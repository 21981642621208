import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import addIcon from "../../../../../assets/svgs/add.svg";
import { useNavigate, useParams } from "react-router-dom";
import { ScrapsCategoryInfoDTO } from "../../../scrap-category/models/scrap-category-DTO";
import { useAuth } from "../../../../../contexts/auth-context";
import ImportExportCsv from "../../../../../components/ImportExportCsv/ImportExportCsv";
import FilterComponent from "../../../../../components/FIlter/filter";
import CardListScrapCategories from "../../../scrap-category/cardlist-scrap-categories/cardlist-scrap-categories";
import { getScrapCategoriesApproved, getScrapCategoriesApprovedFilteredByGroup } from "../../../scraps-service";

const ScrapGroupMaterials: React.FC<{ enabledUsers: string[] }> = ({
  enabledUsers,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userDoc } = useAuth();
  const profile = userDoc?.profile;
  const { id } = useParams<{ id: string }>();
  const [scrapCategories, setScrapCategories] = useState<
    ScrapsCategoryInfoDTO[]
  >([]);
  const [filteredData, setFilteredData] = useState<ScrapsCategoryInfoDTO[]>([]);

  const sortByRegion = (data: ScrapsCategoryInfoDTO[]) => {
    return [...data].sort((a, b) => {
      const nameA = a.name?.toString().toLowerCase() || "";
      const nameB = b.name?.toString().toLowerCase() || "";
      return nameA.localeCompare(nameB);
    });
  };

  const sortOptions = [
    { label: t("label-material"), sortFunction: sortByRegion },
  ];

  const handleFilterClick = (filteredData: ScrapsCategoryInfoDTO[]) => {
    setFilteredData(filteredData);
  };

  const handleSortClick = (
    sortFunction: (data: ScrapsCategoryInfoDTO[]) => ScrapsCategoryInfoDTO[]
  ) => {
    const sortedData = sortFunction([...filteredData]);
    setFilteredData(sortedData);
  };

  const fetchScrapCategories = async () => {
    try {
      if (id) {
        const data = await getScrapCategoriesApprovedFilteredByGroup(id);
        setScrapCategories(data);
        setFilteredData(data);
      }
    } catch (error) {
      console.error("Error fetching scrap categories: ", error);
    }
  };

  useEffect(() => {
    fetchScrapCategories();
  }, []);

  const handleCloseModal = () => {
    fetchScrapCategories();
  };

  if (enabledUsers.includes(profile || "public")) {
    return (
      <div className="page">
        <div className="flex-row">
          <button
            className="blue-with-icon button"
            onClick={() => {
              navigate("/scrap-categories-form");
            }}
          >
            <img src={addIcon} alt="add icon" />
            <p>{t("label-add-new")}</p>
          </button>
          <ImportExportCsv
            exportApi="exportscrapbygeneratorscsv"
            importApi=""
            onClose={handleCloseModal}
          />
          <FilterComponent
            data={scrapCategories}
            onFilterClick={handleFilterClick}
            sortOptions={sortOptions}
          />
        </div>
        <CardListScrapCategories scrapCategories={filteredData} />
      </div>
    );
  } else {
    navigate("/page-not-found");
    return <></>;
  }
};

export default ScrapGroupMaterials;
