import { Outlet } from "react-router-dom";
import Header from "./components/Header/Header";
import "./App.css";
import { useTranslation } from "react-i18next";
import "./assets/i18n/i18n";
import ModalCookies from "./components/ModalCookies/ModalCookies";
import { VerifyAccessFunction } from "./services/verify-access";
import { useEffect, useState } from "react";
import { useAuth } from "./contexts/auth-context";
import Footer from "./pages/landing-page/components/footer/footer";


function App() {
  const { t } = useTranslation();
  const [showCookiesModal, setShowCookiesModal] = useState<boolean>(true);
  const { loading } = useAuth()

  const saveLocalStorage = () => {
    localStorage.setItem("cookiesAccepted", "true");
    setShowCookiesModal(false);
  };

  useEffect(() => {
    const cookiesAccepted = localStorage.getItem("cookiesAccepted");
    if (cookiesAccepted === "true") {
      setShowCookiesModal(false);
    }
  }, []);

  VerifyAccessFunction();

  if (loading) return null;

  return (
    <div>
      <div className="content">
        <Header />
        <Outlet />
      <Footer />
      </div>
      {showCookiesModal && (
        <ModalCookies
          description={t("cookies.description")}
          textLink={t("cookies.policy")}
          accept={t("cookies.accept")}
          reject={t("cookies.reject")}
          accepted={saveLocalStorage}
        />
      )}
    </div>
  );
}

export default App;
