import { SparkIcon } from "@bosch-web-dds/spark-ui-react";
import Select from "react-select";
import React, { useState, useEffect } from "react";
import "./FilterGraphic.css";
import { FinancialDTO } from "../../../../pages/financial/financial-DTO";

interface Props {
  data: FinancialDTO[] | null;
  setIdTofilter: (arg: string) => void;
}

export const FilterGraphic: React.FC<Props> = ({ setIdTofilter, data }) => {
  const [showFilter, setShowFilter] = useState<Boolean>(true);
  const [isClearable, setIsClearable] = useState(true);
  const [selectedOptionScrap, setSelectedOptionScrap] = useState(null);
  const [filteredData, setFilteredData] = useState<any[]>([]);

  const handleSelectChangeScrap = (selectedOptionScrap: any) => {
    setSelectedOptionScrap(selectedOptionScrap);
    setIdTofilter(selectedOptionScrap);
  };

  useEffect(() => {
    if (data) {
      const uniqueLabels = new Set<string>();
      const uniqueData = data.reduce<{ label: string }[]>((acc, item) => {
        if (!uniqueLabels.has(item.scrapMaterialName)) {
          uniqueLabels.add(item.scrapMaterialName);
          acc.push({ label: item.scrapMaterialName });
        }
        return acc;
      }, []);

      setFilteredData(uniqueData);
    }
  }, [data]);

  return (
    <div>
      {showFilter == true ? (
        <div className="margin">
          <SparkIcon
            icName="filter"
            noPadding
            onClick={() => setShowFilter(false)}
          />
        </div>
      ) : (
        <div className="">
          <div className="margin">
            <SparkIcon
              icName="filter"
              noPadding
              onClick={() => setShowFilter(true)}
            />
          </div>

          <div className="container">
            <div className="filters">
              <div className="detals-filter">
                <Select
                  className="basic-single"
                  placeholder="Material"
                  options={filteredData}
                  isClearable={isClearable}
                  value={selectedOptionScrap}
                  onChange={handleSelectChangeScrap}
                  onBlur={() => {
                    setTimeout(() => setShowFilter(true), 0);
                  }}
                  onFocus={() => setShowFilter(false)}
                  menuPlacement="bottom"
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};