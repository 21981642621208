import {
  getFirestore,
  collection,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { COLLECTIONS } from "../../assets/enums/firebase-colections";
import { UserInfoDTO } from "../../pages/settings/components/users/models/users-DTO";

const fetchUsers = async ({ id }: { id: string }): Promise<UserInfoDTO[]> => {
  const db = getFirestore();
  const userCollectionRef = collection(db, COLLECTIONS.USERS);
  const userQuery = query(userCollectionRef, where('originId', '==', id));
  const userSnapshot = await getDocs(userQuery);

  const userList: UserInfoDTO[] = [];
  userSnapshot.forEach((doc) => {
    const userData = doc.data();
    const user: UserInfoDTO = {
      id: doc.id,
      originId: userData.originId,
      email: userData.email,
      name: userData.name,
      profile: userData.profile,
      lastAccess: userData.lastAccess || ""
    };
    userList.push(user);
  });

  return userList;
};

export default fetchUsers;
