export function getResizedImage(
  imageUrl: string,
  size: string = "900x900"
): string {
  const lastDotIndex = imageUrl.lastIndexOf(".");
  const originalImage = imageUrl.substring(0, lastDotIndex);
  const extension = imageUrl.substring(lastDotIndex + 1);

  return `${originalImage}_${size}.${extension}`;
}
