import { useTranslation } from "react-i18next";
import addIcon from "../../assets/svgs/add.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import OpForm from "./optimization-form/optimization-form";
import { getOptimizationById } from "./optimization-service";
import { OptimizationInfoDTO } from "./models/optimization-DTO";
import ImportExportCsv from "../../components/ImportExportCsv/ImportExportCsv";
import { useAuth } from "../../contexts/auth-context";
import FilterComponent from "../../components/FIlter/filter";
import CardOp from "./card-optimization/card-optimization";
import NoData from "../../components/NoData/NoData";

// Funções de ordenação
const sortByOpDate = (data: OptimizationInfoDTO[]) => {
    return [...data].sort((a, b) => {
      const nameA = a.date?.toString().toLowerCase() || '';
      const nameB = b.date?.toString().toLowerCase() || '';
      return nameA.localeCompare(nameB);
    });
  };

const sortByOpOp = (data: OptimizationInfoDTO[]) => {
    return [...data].sort((a, b) => {
      const nameA = a.optimizations?.toString().toLowerCase() || '';
      const nameB = b.optimizations?.toString().toLowerCase() || '';
      return nameA.localeCompare(nameB);
    });
  };

const Opti: React.FC<{ enabledUsers: string[] }> = ({ enabledUsers }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userDoc, loading } = useAuth();
  const profile = userDoc?.profile;
  const { id } = useParams<{ id: string }>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [op, setOp] = useState<OptimizationInfoDTO[]>([]);
  const [filteredData, setFilteredData] = useState<OptimizationInfoDTO[]>([]);

  
  const data = { originId: id };

  const sortByOptions = [
    { label: t("label-date"), sortFunction: sortByOpDate },
    { label: t("label-optimizations"), sortFunction: sortByOpOp },
  ];


  const handleFilterClick = (filteredData: OptimizationInfoDTO[]) => {
    setFilteredData(filteredData);
  };

  const handleSortClick = (sortFunction: (data: OptimizationInfoDTO[]) => OptimizationInfoDTO[]) => {
    const sortedData = sortFunction([...filteredData]); // Ordenar os dados filtrados
    setFilteredData(sortedData);
  };

  const fetchOpti = async () => {
    if (id) {
      try {
        const data = await getOptimizationById(id);
        setOp(data);
        setFilteredData(data);
      } catch (error) {
        console.error("Error fetching optimizations: ", error);
      }
    }
  };

  useEffect(() => {
    fetchOpti();
  }, [id]);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    fetchOpti(); 
  };

  if(loading) return null


  if (enabledUsers.includes(profile || "public")) {
    return (
      <div>
        {isModalOpen && (
          <div className="modal-background">
            <OpForm  onClose={handleCloseModal} />
          </div>
        )}
        <div className="page">
          <div className="flex-row">
            <button className="blue-with-icon button" onClick={handleOpenModal}>
              <img src={addIcon} alt="add icon" />
              <p>{t("label-add-new")}</p>
            </button>
            <ImportExportCsv
              exportApi="exportcontactscsv"
              importApi="importcontactscsv"
              data={data}
              onClose={handleCloseModal}
            />
            <FilterComponent
              data={op}
              onFilterClick={handleFilterClick}
              sortOptions={sortByOptions}
            />
          </div>
          <div className="card-list">
            <br></br>
            {filteredData.map((item, index) => (
              <CardOp onClose={handleCloseModal} key={index} item={{optimizationInfo:item}} />
            ))}
            {op.length === 0 && <NoData />}
          </div>
        </div>
      </div>
    );
  } else {
    navigate("/page-not-found");
    return <></>;
  }
};

export default Opti;
