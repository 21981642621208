import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import OpForm from "../optimization-form/optimization-form";
import { OptimizationDTO } from "../models/optimization-DTO";

interface Props {
  item: OptimizationDTO;
  onClose: () => void;
}

const CardOp: React.FC<Props> = ({ onClose, item }) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
    onClose()
  };

  return (
    <div>
      {isModalOpen && (
      <div className="modal-background">
        <OpForm item={item} onClose={handleCloseModal}/>
      </div>
    )}
    <div
      className="flex-row card-dealer"
      onClick={handleOpenModal}
    >
        <div className="flex-column infos">
        <h4>{item.optimizationInfo.optimizations || "-"}</h4>
        <div className="custom-line-hr" />
          <p>
            {t("label-date")}: <strong>{item.optimizationInfo.date}</strong>
          </p>
         
        </div>

    </div>
    </div>
  );
};

export default CardOp;
