import { useEffect, useState } from "react";
import InputMask from "react-input-mask";
import {
  getAdrressByEntityId,
  getCountryConfigById,
} from "../../services/address-service";
import { useParams } from "react-router-dom";
import { AddressInfoDTO } from "../../pages/scraps/scrap-admin-generator-view/components/scrap-form/models/scrap-DTO";
import { t } from "i18next";
import { CountryConfigDTO } from "../../pages/settings/components/Country-config/country-config-DTO";

interface AddressSectionFieldsProps {
  initialValues?: AddressInfoDTO;
  onSave?: (values: AddressInfoDTO) => void;
}

function AddressSectionFields({
  initialValues,
  onSave,
}: AddressSectionFieldsProps) {
  const { id } = useParams<{ id: string }>();
  const [postalCodeFormat, setPostalCodeFormat] =
    useState<string>("99.999-999");
  const [postalCodeLabel, setPostalCodeLabel] =
    useState<string>("label-postal-code");
  const [countryConfigEntity, setCountryConfigEntity] =
    useState<CountryConfigDTO | null>(null);
  const [values, setValues] = useState<AddressInfoDTO>({
    postalCode: initialValues?.postalCode || "",
    address: initialValues?.address || "",
    city: initialValues?.city || "",
    state: initialValues?.state || "",
    country: initialValues?.country || "",
    countryName: initialValues?.countryName || "",
    postalCodeLabel: initialValues?.postalCodeLabel || "",
  });
  const [isEditable, setIsEditable] = useState(false);
  const [attemptedSave, setAttemptedSave] = useState(false);
  const [fieldErrors, setFieldErrors] = useState<Record<string, boolean>>({
    postalCode: false,
    address: false,
    city: false,
    state: false,
  });

  useEffect(() => {
    const fetchCountryConfig = async () => {
      if (id) {
        const addressData = await getAdrressByEntityId(id);
        if (addressData) {
          const countryConfigData = await getCountryConfigById(
            addressData.country
          );
          if (countryConfigData) {
            setCountryConfigEntity(countryConfigData);
            setPostalCodeFormat(countryConfigData.postalCodeFormatting);
            setPostalCodeLabel(countryConfigData.postalCode);
          }
        }
      }
    };

    fetchCountryConfig();
  }, [id]);

  useEffect(() => {
    if (initialValues) {
      setValues({
        postalCode: initialValues.postalCode,
        address: initialValues.address,
        city: initialValues.city,
        state: initialValues.state,
        country: initialValues?.country,
        countryName: initialValues?.countryName,
        postalCodeLabel: initialValues?.postalCodeLabel,
      });
    }
  }, [initialValues]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setValues((prevValues) => ({
      ...prevValues,
      [id]: value,
    }));
  };

  const handleEditClick = () => {
    setIsEditable(true);
  };

  const handleSaveClick = () => {
    setAttemptedSave(true);

    const errors: Record<string, boolean> = {};
    if (!values.postalCode) errors.postalCode = true;
    if (!values.address) errors.address = true;
    if (!values.city) errors.city = true;
    if (!values.state) errors.state = true;

    setFieldErrors(errors);

    if (Object.keys(errors).length === 0 && onSave) {
      setIsEditable(false);
      onSave(values);
    }
  };

  return (
    <div className="info-section" style={{ margin: "1rem 0rem" }}>
      <h4 style={{ margin: "0" }}>{t("label-location")}</h4>
      <div className="flex-column margin-topbottom-1">
        <label htmlFor="postalCode">{t(`${postalCodeLabel}`)}</label>
        <InputMask
          mask={postalCodeFormat}
          placeholder={postalCodeFormat}
          type="text"
          id="postalCode"
          value={values.postalCode}
          onChange={handleInputChange}
          disabled={!isEditable}
        />
        {attemptedSave && !values.postalCode && (
          <span>{t("error-input-required")}</span>
        )}
      </div>
      <div className="double-fields margin-topbottom-1">
        <div className="flex-column">
          <label htmlFor="address">{t("label-address")}</label>
          <input
            placeholder={t("placeholder.address")}
            type="text"
            id="address"
            value={values.address}
            onChange={handleInputChange}
            disabled={!isEditable}
          />
          {attemptedSave && !values.address && (
            <span>{t("error-input-required")}</span>
          )}
        </div>
      </div>

      <div className="double-fields">
        <div className="flex-column">
          <label htmlFor="city">{t("label-city")}</label>
          <input
            placeholder={t("placeholder.city")}
            type="text"
            id="city"
            value={values.city}
            onChange={handleInputChange}
            disabled={!isEditable}
          />
          {attemptedSave && !values.city && (
            <span>{t("error-input-required")}</span>
          )}
        </div>

        <div className="flex-column">
          <label htmlFor="state">{t("label-state")}</label>
          <input
            placeholder={t("placeholder.state")}
            type="text"
            id="state"
            value={values.state}
            onChange={handleInputChange}
            disabled={!isEditable}
          />
          {attemptedSave && !values.state && (
            <span>{t("error-input-required")}</span>
          )}
        </div>
      </div>

      {isEditable ? (
        <button
          style={{ margin: "1rem 0" }}
          type="button"
          className="primary"
          onClick={handleSaveClick}
        >
          {t("label-save")}
        </button>
      ) : (
        <button
          style={{ margin: "1rem 0" }}
          type="button"
          onClick={handleEditClick}
        >
          {t("label-edit")}
        </button>
      )}
    </div>
  );
}

export default AddressSectionFields;
