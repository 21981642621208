import { t } from "i18next";
import React from "react";
import { useNavigate } from "react-router-dom";

interface ApproveGenericCardProps {
  children?: React.ReactNode;
  itemName: string;
  item: any;
  itemTag?: string;
  onApprove: (item: any) => void;
  onReprove: (item: any) => void;
  onClick: (item: any) => void;
}

const ApproveGenericCard: React.FC<ApproveGenericCardProps> = ({
  children,
  itemName,
  item,
  itemTag,
  onApprove,
  onReprove,
  onClick,
}) => {
  const navigate = useNavigate();
  return (
    <div
      className="flex-row justify-between generic-approve-card"
      onClick={() => {
        onClick(item);
      }}
    >
      <div
        className="flex-column generic-approve-card-children"
        style={{ width: "80%" }}
      >
        <div className="flex-column" style={{ marginBottom: 5, gap: 10 }}>
          <div className="flex-row">
            <div className="pill-tag">{itemTag}</div>
          </div>
          <h3 style={{ margin: 0, marginLeft: "0.5rem" }}>{itemName}</h3>
        </div>
        <div style={{ marginLeft: "0.5rem" }}>
          {children}
        </div>
      </div>
      <div
        className="flex-row align-center generic-approve-card-buttons"
        style={{ gap: "10px" }}
      >
        <button
          className="green"
          onClick={(e) => {
            e.stopPropagation();
            onApprove(item);
          }}
        >
          {t("label-approve")}
        </button>
        <button
          className="red"
          onClick={(e) => {
            e.stopPropagation();
            onReprove(item);
          }}
        >
          {t("label-reprove")}
        </button>
      </div>
    </div>
  );
};

export default ApproveGenericCard;
