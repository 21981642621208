import { FinancialDTO } from "../../../../pages/financial/financial-DTO";
import { useEffect, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import money from "../../../../assets/img/money.png";
import {
  SparkTable,
  SparkTableCell,
  SparkTableHead,
  SparkTableRow,
} from "@bosch-web-dds/spark-ui-react";

interface Props {
  data: FinancialDTO[] | null;
  idTofilter: any;
}

const TableDetails: React.FC<Props> = ({ data, idTofilter }) => {
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    let tranlation = {
      d1: {
        value: 0,
        display: "one",
        color: "collect",
        label: idTofilter.label || t("all"),
        status: t("financial-dashboard.title-one")
      },
      d2: {
        value: 0,
        display: "two",
        color: "Incoming",
        label: idTofilter.label || t("all"),
        status: t("financial-dashboard.title-two"),
      },
      d3: {
        value: 0,
        display: "three",
        color: "Received",
        label: idTofilter.label || t("all"),
        status: t("financial-dashboard.title-three"),
      },
    };

    if (data) {
      if (idTofilter.label) {
        data = data.filter((obj) =>
          [idTofilter.label].includes(obj.scrapMaterialName)
        );
      }

      data.reduce((acc, obj) => {
        if (obj.status === "TO_COLLECT") {
          acc.d1.value += obj.totalValue || 0;
        } else if (obj.status === "TO_RECEIVE") {
          acc.d2.value += obj.totalValue || 0;
        } else if (obj.status === "RECEIVED") {
          acc.d3.value += obj.totalValue || 0;
        }
        return acc;
      }, tranlation);

      setFilteredData([tranlation.d1, tranlation.d2, tranlation.d3]);
    }
  }, [idTofilter]);

  return (
    <SparkTable>
      <SparkTableHead>
        <SparkTableRow>
          <SparkTableCell className="icon-column"> - </SparkTableCell>
          <SparkTableCell>{t("financial-dashboard.name")}</SparkTableCell>
          <SparkTableCell>{t("financial-dashboard.value")}</SparkTableCell>
          <SparkTableCell>{t("financial-dashboard.status")}</SparkTableCell>
        </SparkTableRow>
      </SparkTableHead>

      {filteredData.map((obj) => (
        <SparkTableRow>
          <SparkTableCell className="icon-column">
            <img width={20} src={money} alt="icone de dinheiro" />
          </SparkTableCell>
          <SparkTableCell>{obj.label}</SparkTableCell>
          <SparkTableCell>{obj.value}</SparkTableCell>
          <SparkTableCell>{obj.status}</SparkTableCell>
        </SparkTableRow>
      ))}
    </SparkTable>
  );
};

export default TableDetails;
