import { useTranslation } from "react-i18next";
import addIcon from "../../assets/svgs/add.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import ImportExportCsv from "../../components/ImportExportCsv/ImportExportCsv";
import { useAuth } from "../../contexts/auth-context";
import FilterComponent from "../../components/FIlter/filter";

import NoData from "../../components/NoData/NoData";
import { DocumentsInfoDTO } from "./documents-DTO";
import DocumentForm from "./form-document/form-document";
import { getDocumentsById } from "./documents-service";
import { SparkTable, SparkTableBody, SparkTableCell, SparkTableHead, SparkTableRow } from "@bosch-web-dds/spark-ui-react";
import { transformDateFormat } from "../../services/date-format";

const sortByDocumentName = (data: DocumentsInfoDTO[]) => {
  return [...data].sort((a, b) => {
    const nameA = a.name?.toString().toLowerCase() || '';
    const nameB = b.name?.toString().toLowerCase() || '';
    return nameA.localeCompare(nameB);
  });
};


const Documents: React.FC<{ enabledUsers: string[] }> = ({ enabledUsers }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userDoc } = useAuth();
  const profile = userDoc?.profile;
  const { id } = useParams<{ id: string }>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [documents, setDocuments] = useState<DocumentsInfoDTO[]>([]);
  const [filteredData, setFilteredData] = useState<DocumentsInfoDTO[]>([]);

  const data = { originId: id };

  const sortOptions = [
    { label: t("label-name"), sortFunction: sortByDocumentName },
  ];

  const handleFilterClick = (filteredData: DocumentsInfoDTO[]) => {
    setFilteredData(filteredData);
  };

  const handleSortClick = (sortFunction: (data: DocumentsInfoDTO[]) => DocumentsInfoDTO[]) => {
    const sortedData = sortFunction([...filteredData]);
    setFilteredData(sortedData);
  };

  const fetchDocuments = async () => {
    if (id) {
      try {
        const data = await getDocumentsById(id);
        setDocuments(data);
        setFilteredData(data);
      } catch (error) {
        console.error("Error fetching documents: ", error);
      }
    }
  };

  const [documentId, setDocumentId] = useState<string | null>(null);

  useEffect(() => {
    fetchDocuments();
  }, [id]);

  const handleOpenModal = (driverId: string | undefined) => {
    setDocumentId(driverId ?? "")
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    fetchDocuments();
  };


  const colors = {
    "VALIDATED": "#00884a",
    "NOT_COMPLIANT": "#ed0007",
    "TO_EXPIRE": "#ffcf00",
  }
  function getDocumentStatus(date: string) {
    let documentDate = new Date(date);
    let currentDate = new Date();
    let futureDate = new Date(currentDate);
    futureDate.setMonth(currentDate.getMonth() + 1);

    if (documentDate > futureDate) {
      return '#00884a'
    } else if (documentDate >= currentDate && documentDate <= futureDate) {
      return '#ffcf00'
    } else {
      return '#ed0007'
    }
  }

  if (enabledUsers.includes(profile || "public")) {
    return (
      <div>
        {isModalOpen && (
          <div className="modal-background">
            <DocumentForm documentId={documentId ?? ""} onClose={handleCloseModal} />
          </div>
        )}
        <div className="page">
          <div className="flex-row">
            <button className="blue-with-icon button" onClick={() =>
              handleOpenModal("")}>
              <img src={addIcon} alt="add icon" />
              <p>{t("label-add-new")}</p>
            </button>
            {/* <ImportExportCsv
              exportApi="exportdriverscsv"
              importApi="importdriverscsv"
              data={data}
              onClose={handleCloseModal}
            /> */}
            <FilterComponent
              data={documents}
              onFilterClick={handleFilterClick}
              sortOptions={sortOptions}
            />
          </div>
          <br></br>
          <SparkTable>
            <SparkTableHead>
              <SparkTableRow>
                <SparkTableCell style={{ width: "2rem" }}></SparkTableCell>
                <SparkTableCell><strong>{t("label-name")}</strong></SparkTableCell>
                <SparkTableCell><strong>{t("label-description")}</strong></SparkTableCell>
                <SparkTableCell><strong>{t("label-expirationDate")}</strong></SparkTableCell>
                

              </SparkTableRow>
            </SparkTableHead>
            <SparkTableBody>
              {filteredData.map((obj: any, rowIndex: any) => (
                <SparkTableRow onClick={() => { handleOpenModal(obj.id); }} key={rowIndex}>
                  <SparkTableCell style={{ width: "2rem" }}>
                    <div style={{ width: "24px", height: "24px", backgroundColor: getDocumentStatus(obj?.expirationDocument?.expirationDate) }}></div>
                  </SparkTableCell>
                  <SparkTableCell>{obj.name}</SparkTableCell>
                  <SparkTableCell>{obj.description}</SparkTableCell>
                  <SparkTableCell>{transformDateFormat(obj?.expirationDocument?.expirationDate)}</SparkTableCell>

                </SparkTableRow>
              ))}
            </SparkTableBody>
          </SparkTable>

        </div>
      </div>
    );
  } else {
    navigate("/page-not-found");
    return <></>;
  }
};

export default Documents;
