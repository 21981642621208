import "./LanguagePicker.css"
import React, { useState, useEffect } from "react"
import { useTranslation } from 'react-i18next';
import { SparkIcon } from "@bosch-web-dds/spark-ui-react";

const LanguagePicker: React.FC = () => {
    const { i18n, t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [hovered, setHovered] = useState(false)

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            const target = event.target as HTMLElement;
            if (!target.closest(".language-selector")) {
                setIsOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng);
        setIsOpen(false);
    };

    return (
        <div className="language-selector"
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >
            <div onClick={() => setIsOpen(!isOpen)} style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <SparkIcon icName="globe"
                  pallete={hovered ? "secondary" : undefined}
                />
            </div>
            {isOpen && (
                <div className="language-dropdown">
                    <button className="language-button" onClick={() => changeLanguage(navigator.language.split('-')[0])}>{t("LanguagePicker.default")}</button>
                    <button className="language-button" onClick={() => changeLanguage('pt')}>Português</button>
                    <button className="language-button" onClick={() => changeLanguage('en')}>English</button>
                    <div style={{ height: 12 }}></div>
                </div>
            )}
        </div>
    );
};

export default LanguagePicker;
