import { useTranslation } from "react-i18next";
import CardListScrapGenerator from "../../../cardlist-scrap-generator/cardlist-scrap-generator";
import { useNavigate, useParams } from "react-router-dom";
import addIcon from "../../../../../../assets/svgs/add.svg";
import { getBranchsScrapGeneratorsById } from "../../../../scrap-generator-service";
import { useEffect, useState } from "react";
import { ScrapGeneratorInfoDTO } from "../../../../scrap-generators-DTO";
import ImportExportCsv from "../../../../../../components/ImportExportCsv/ImportExportCsv";
import { useAuth } from "../../../../../../contexts/auth-context";
import FilterComponent from "../../../../../../components/FIlter/filter";
import { t } from "i18next";

// Funções de ordenação
const sortByScrapGeneratorName = (data: ScrapGeneratorInfoDTO[]) => {
  return [...data].sort((a, b) => {
    const nameA = a.legalName?.toString().toLowerCase() || '';
    const nameB = b.legalName?.toString().toLowerCase() || '';
    return nameA.localeCompare(nameB);
  });
};

const sortByScrapGeneratorregion = (data: ScrapGeneratorInfoDTO[]) => {
  return [...data].sort((a, b) => {
    const regionA = a.region?.toString().toLowerCase() || '';
    const regionB = b.region?.toString().toLowerCase() || '';
    return regionA.localeCompare(regionB);
  });
};
const sortByScrapGeneratortradingName = (data: ScrapGeneratorInfoDTO[]) => {
  return [...data].sort((a, b) => {
    const tradingA = a.tradingName?.toString().toLowerCase() || '';
    const tradingB = b.tradingName?.toString().toLowerCase() || '';
    return tradingA.localeCompare(tradingB);
  });
};

const sortOptions = [
  { label:  t("label-name"), sortFunction: sortByScrapGeneratorName },
  { label: t("label-region"), sortFunction: sortByScrapGeneratorregion },
  { label:t("label-trading-name"), sortFunction: sortByScrapGeneratortradingName },
];

const ScrapGeneratorDetailsBranchs: React.FC<{ enabledUsers: string[] }> = ({ enabledUsers }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userDoc } = useAuth();
  const profile = userDoc?.profile;
  const { id } = useParams<{ id: string }>();
  const headquarterId = { headquarterId: id };
  const [scrapGeneratorBranchs, setScrapGeneratorBranchs] = useState<ScrapGeneratorInfoDTO[]>([]);
  const [filteredData, setFilteredData] = useState<ScrapGeneratorInfoDTO[]>([]);

  const handleFilterClick = (filteredData: ScrapGeneratorInfoDTO[]) => {
    setFilteredData(filteredData);
  };

  const handleSortClick = (sortFunction: (data: ScrapGeneratorInfoDTO[]) => ScrapGeneratorInfoDTO[]) => {
    const sortedData = sortFunction([...filteredData]);
    setFilteredData(sortedData);
  };

  const fetchScrapGenerators = async () => {
    try {
      const data = await getBranchsScrapGeneratorsById(id as string);
      setScrapGeneratorBranchs(data);
      setFilteredData(data);
    } catch (error) {
      console.error("Error fetching scrap Generators: ", error);
    }
  };

  useEffect(() => {
    fetchScrapGenerators();
  }, [id]);

  const handleCloseModal = () => {
    fetchScrapGenerators();
  };

  if (enabledUsers.includes(profile || "public")) {
    return (
      <div>
        <div className="flex-row">
          <button
            className="blue-with-icon button"
            onClick={() => {
              navigate(`/scrap-generators-new/${id}`);
            }}
          >
            <img src={addIcon} alt="add icon" />
            <p>{t("label-add-new")}</p>
          </button>
          <ImportExportCsv
            exportApi="exportscrapgeneratorscsv"
            importApi="importscrapgeneratorscsv"
            data={headquarterId}
            onClose={handleCloseModal}
          />
          <FilterComponent
            data={scrapGeneratorBranchs}
            onFilterClick={handleFilterClick}
            sortOptions={sortOptions}
         
          />
        </div>
        <br />
        <CardListScrapGenerator scrapGenerators={filteredData} />
      </div>
    );
  } else {
    navigate("/page-not-found");
    return <></>;
  }
};

export default ScrapGeneratorDetailsBranchs;
