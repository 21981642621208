import { useTranslation } from "react-i18next";
import addIcon from "../../../../assets/svgs/add.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import { ContainerTypesInfoDTO } from "./container-types-DTO";
import { getContainerTypesByOriginId } from "./container-types-service";
import {
  SparkTable,
  SparkTableBody,
  SparkTableCell,
  SparkTableHead,
  SparkTableRow,
} from "@bosch-web-dds/spark-ui-react";
import ContainerTypesForm from "./container-types-form/container-types-form";
import { useAuth } from "../../../../contexts/auth-context";

const ContainerTypes: React.FC<{ enabledUsers: string[] }> = ({ enabledUsers }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userDoc } = useAuth();
  const profile = userDoc?.profile;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [containerTypes, setContainerTypes] = useState<ContainerTypesInfoDTO[]>(
    []
  );
  const { id } = useParams<{ id: string }>();
  const [tableData, setTableData] = useState<any>([]);
  const tableHeader = [
    `${t("material-container-type")}`,
  ];

  const fetchContainerTypes = async () => {
    if (id) {
      try {
        const data = await getContainerTypesByOriginId(id);
        setContainerTypes(data);

        setTableData(data);
      } catch (error) {
        console.error("Error fetching scrap categories: ", error);
      }
    }
  };
  const [requirementId, setRequirementID] = useState(null || "");

  useEffect(() => {
    fetchContainerTypes();
  }, [id, setContainerTypes]);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    fetchContainerTypes();
  };

  if (enabledUsers.includes(profile || "public")) {
    return (
      <div>
        {isModalOpen && (
          <div className="modal-background">
            <ContainerTypesForm
              requirementId={requirementId ?? ""}
              onClose={handleCloseModal}
            />
          </div>
        )}
        <div className="page">
          <div className="flex-row">
            <button className="blue-with-icon button" onClick={handleOpenModal}>
              <img src={addIcon} alt="add icon" />
              <p>{t("label-add-new")}</p>
            </button>
          </div>
          <br />
          <div className="info-section">
        <SparkTable>
            <SparkTableHead className="flex-row" style={{ alignItens: "center" }}>
              <SparkTableRow>
                {tableHeader.map((header, index) => (
                  <SparkTableCell style={{ alignItens: "center" }} key={index}>
                    <strong>{header.toUpperCase()}</strong>
                  </SparkTableCell>
                ))}
              </SparkTableRow>
            </SparkTableHead>

            <SparkTableBody>
              {tableData.map((rowItem: any, rowIndex: any) => (
                <SparkTableRow
                  onClick={() => {
                    setRequirementID(rowItem.id);
                    handleOpenModal();
                  }}
                  key={rowIndex}
                >
                  <SparkTableCell>{rowItem.name}</SparkTableCell>
                </SparkTableRow>
              ))}
            </SparkTableBody>
          </SparkTable>
        </div>
      </div>
    </div>);
  } else {
    navigate("/page-not-found")
    return <></>
  }
};

export default ContainerTypes;
