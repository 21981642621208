import { useTranslation, Trans, Translation } from "react-i18next";
import money from "../../../../assets/img/money.png";
import { useEffect, useState } from "react";
import { FinancialDTO } from "../../../../pages/financial/financial-DTO";
import { t } from "i18next";
import { CurrencyInput } from "react-currency-mask";
import { formatCurrencyByLocale } from "../../../../utils/formatting";

interface Props {
  data: FinancialDTO[] | null;
  idTofilter: any;
}

export const CardDetails: React.FC<Props> = ({ data, idTofilter }) => {
  const [filteredData, setFilteredData] = useState<any[]>([]);

  useEffect(() => {
    let tranlation = {
      d1: { value: 0, display: "one", color: "collect" },
      d2: { value: 0, display: "two", color: "Incoming" },
      d3: { value: 0, display: "three", color: "Received" },
    };

    if (data) {
      if (idTofilter.label) {
        data = data.filter((obj) =>
          [idTofilter.label].includes(obj.scrapMaterialName)
        );
      }

      data.reduce((acc, obj) => {
        if (obj.status === "TO_COLLECT") {
          acc.d1.value += obj.totalValue;
        } else if (obj.status === "TO_RECEIVE") {
          acc.d2.value += obj.totalValue;
        } else if (obj.status === "RECEIVED") {
          acc.d3.value += obj.totalValue;
        }
        return acc;
      }, tranlation);

      // const allValuesZero = tranlation.d1.value === 0 &&
      //   tranlation.d2.value === 0 &&
      //   tranlation.d3.value === 0;

      // const anyValueNaN = Number.isNaN(Number(tranlation.d1.value)) ||
      //   Number.isNaN(Number(tranlation.d2.value)) ||
      //   Number.isNaN(Number(tranlation.d3.value));

      // if (allValuesZero || anyValueNaN) {
      //   setFilteredData([]);
      // } else {
      //   setFilteredData([tranlation.d1, tranlation.d2, tranlation.d3]);
      // }

      setFilteredData([tranlation.d1, tranlation.d2, tranlation.d3]);
    }
  }, [idTofilter]);

  return (
    <div>
      {filteredData.map((obj) => (
        <div className="detals-card">
          <div className={`icone ${obj.color}`}>.</div>
          <img src={money} alt="icon" className="img-icon" />

          <div className="second-card">
            <span>{t(`financial-dashboard.title-${obj.display}`)}</span>
            <span className="top">
              {formatCurrencyByLocale(obj.value, "pt-BR", "BRL")}
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};
