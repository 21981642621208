import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../contexts/auth-context";
import { useForm } from "react-hook-form";
import sigorLogo from "../../../../assets/img/SIGOR-logo.png";
import { getScrapGeneratorById } from "../../../scrap-generators/scrap-generator-service";
import { useEffect, useState } from "react";
import { getSigorLoginByOriginId, upsertSigorLogin } from "./sigor-service";
import { SigorLoginDTO } from "../../generator-settings-DTO";
import { ScrapGeneratorDTO } from "../../../scrap-generators/scrap-generators-DTO";
import { useAlert } from "../../../../contexts/alert-context";

const SigorLogin: React.FC<{ enabledUsers: string[] }> = ({ enabledUsers }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userDoc } = useAuth();
  const profile = userDoc?.profile;
  const { setAlert } = useAlert();
  const { register, handleSubmit, setValue, watch } = useForm<SigorLoginDTO>();
  const [sigorLogin, setSigorLogin] = useState<SigorLoginDTO>();
  const [generatorData, setGeneratorData] = useState<ScrapGeneratorDTO>();
  const [passwordModified, setPasswordModified] = useState(false);

  const onSubmit = async (data: SigorLoginDTO) => {
    if (passwordModified && data.password) {
      data.password = btoa(data.password);
    } else if (sigorLogin) {
      data.password = sigorLogin.password;
    }

    if (sigorLogin) {
      data.id = sigorLogin.id;
    }

    const savedSigor = await upsertSigorLogin(data);
    if (savedSigor) {
      fetchSigorData();
      setAlert({
        text: t("alert-successfully-updated"),
        type: "success",
      });
    }
  };

  function fetchSigorData() {
    if (userDoc) {
      getScrapGeneratorById(userDoc?.originId).then((generatorData) => {
        if (generatorData) {
          setGeneratorData(generatorData);
          getSigorLoginByOriginId(generatorData.id).then((sigorData) => {
            setValue("generatorId", generatorData?.id || "");
            setValue(
              "nationalRegister",
              generatorData?.scrapGeneratorInfo.nationalRegister || ""
            );
            setValue(
              "nationalRegisterLabel",
              generatorData?.scrapGeneratorInfo.nationalRegisterLabel || ""
            );
            setValue("unity", generatorData?.scrapGeneratorInfo.mtrCode || "");
            if (sigorData) {
              setSigorLogin(sigorData);
              setValue("password", "");
              setValue("documentId", sigorData.documentId);
            }
          });
        } else {
          console.error("generator data error");
        }
      });
    }
  }

  useEffect(() => {
    fetchSigorData();
  }, []);

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordModified(e.target.value !== "••••••••");
  };

  if (enabledUsers.includes(profile || "public")) {
    return (
      <div className="flex-column align-center justify-center">
        <div
          style={{
            backgroundColor: "var(--bosch-accent-turquoise-opacity10)",
            width: "100%",
          }}
        >
          <img style={{ height: "4rem" }} src={sigorLogo} />
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className=""
          style={{ width: "calc(50vw + 100px)", marginTop: "2rem" }}
        >
          <div className="form-fields flex-column">
            <div className="flex-column">
              <label htmlFor="unit">{t("label-unity")}</label>
              <input
                disabled
                type="text"
                placeholder={t("label-unity")}
                id="unit"
                {...register("unity")}
              />
            </div>

            <div className="flex-column">
              <label htmlFor="national-register">
                {t("label-national-register")}{" "}
                {`(${
                  generatorData?.scrapGeneratorInfo.nationalRegisterLabel || ""
                })`}
              </label>
              <input
                disabled
                type="text"
                placeholder={t("label-national-register")}
                id="national-register"
                {...register("nationalRegister")}
              />
            </div>

            <div className="flex-column">
              <label htmlFor="document-id">
                {t("label-document-individual")}{" "}
              </label>
              <input
                type="text"
                placeholder={t("placeholder-document-id")}
                id="document-id"
                {...register("documentId")}
              />
            </div>

            <div className="flex-column">
              <div className="flex-row align-center justify-between">
                <label htmlFor="password">{t("label-password")}</label>
                {sigorLogin && (
                  <p style={{ color: "var(--bosch-success-green)" }}>
                    {t("alert-registred-login")}
                  </p>
                )}
              </div>
              <input
                type="password"
                placeholder={sigorLogin ? "••••••••" : ""}
                id="password"
                {...register("password")}
                onChange={handlePasswordChange}
              />
            </div>

            <div className="flex-row" style={{ gap: 10, alignSelf: "end" }}>
              <button type="submit" className="blue">
                {t("label-save")}
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  } else {
    navigate("/page-not-found");
    return <></>;
  }
};

export default SigorLogin;
