import { useTranslation } from "react-i18next";
import addIcon from "../../../../assets/svgs/add.svg";
import { useNavigate, useParams } from "react-router-dom";
import CreditAnalysisCardList from "./cardlist-credit-analysis/credit-analysis-carlist";
import { useEffect, useState } from "react";
import ContactForm from "./credit-analysis-form/credit-analysis-form";
import { CreditAnalysisInfoDTO } from "./credit-analysis-DTO";
import { getCreditAnalysisByOriginId } from "./credit-analysis-service";
import { useAuth } from "../../../../contexts/auth-context";

const CreditAnalysis: React.FC<{ enabledUsers: string[] }> = ({ enabledUsers }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userDoc } = useAuth();
  const profile = userDoc?.profile;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [creditAnalysis, setCreditAnalysis] = useState<CreditAnalysisInfoDTO[]>(
    []
  );
  const { id } = useParams<{ id: string }>();

  const fetchCreditAnalysis = async () => {
    if (id) {
      try {
        const data = await getCreditAnalysisByOriginId(id);
        setCreditAnalysis(data);
      } catch (error) {
        console.error("Error fetching scrap categories: ", error);
      }
    }
  };

  useEffect(() => {
    fetchCreditAnalysis();
  }, [id, setCreditAnalysis]);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    fetchCreditAnalysis();
  };

  if (enabledUsers.includes(profile || "public")) {
    return (
      <div>
        {isModalOpen && (
          <div className="modal-background">
            <ContactForm contactId="" onClose={handleCloseModal} />
          </div>
        )}
        <div className="page">
          <div className="flex-row">
            <button className="blue-with-icon button" onClick={handleOpenModal}>
              <img src={addIcon} alt="add icon" />
              <p>{t("label-add-new")}</p>
            </button>
          </div>

          <CreditAnalysisCardList
            onClose={handleCloseModal}
            creditAnalysis={creditAnalysis}
          />
        </div>
      </div>
    );
  } else {
    navigate("/page-not-found")
    return <></>
  }
};

export default CreditAnalysis;
