import React, { useEffect, useState } from "react";
import "../../../scrap.css";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { SparkIcon } from "@bosch-web-dds/spark-ui-react";
import ChartMinimalCardView from "../../../../../components/ChartMinimalCardView/ChartMinimalCardView";
import ChartEmptyImg from "./../../../../../assets/img/chart-pie.svg";
import {
  getScrapByGenerator,
  getScrapCategoryTotalbyId,
} from "../../../scraps-service";
import { CategoryTotalDTO } from "../../models/scrap-category-total-dto";
import MultiCountryAverageChart from "../../../../../components/ChartLineCountryByYear/ChartLineCountryByYear";
import { receiveNumberFormatWithMilhar } from "../../../../../services/number-format";

const ScrapCategoryPanel: React.FC<{ enabledUsers: string[] }> = ({
  enabledUsers,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [totalDetails, setTotalDetails] = useState<CategoryTotalDTO | null>(
    null
  );
  const { id } = useParams<{ id: string }>();
  const [scrapByGeneratorDetails, setScrapByGeneratorDetails] = useState<
    any[] | null
  >(null);
  const [generatorQuantity, setGeneratorQuantity] = useState("0");
  const [dealerQuantity, setDealerQuantity] = useState("0");
  const [showLegend, setShowLegend] = useState(false);

  useEffect(() => {
    const fetchTotalDetails = async () => {
      try {
        if (id) {
          const result = await getScrapCategoryTotalbyId(id);
          setTotalDetails(result);
        }
      } catch (err) {
        console.error("Failed to fetch data");
      }
    };

    const fetchScrapByGenerator = async () => {
      try {
        const result = await getScrapByGenerator(id ?? "");
        setScrapByGeneratorDetails(result);
      } catch (err) {
        console.error("Failed to fetch data");
      }
    };

    fetchTotalDetails();
    fetchScrapByGenerator();
  }, [id]);

  useEffect(() => {
    if (scrapByGeneratorDetails && scrapByGeneratorDetails?.length > 0) {
      const uniqueScrapByGeneratorDetails = scrapByGeneratorDetails.filter(
        (detail, index, self) =>
          index === self.findIndex((t) => t.originId === detail.originId)
      );

      const uniqueScrapDealer = scrapByGeneratorDetails
        .filter((detail) => detail.contractDealer)
        .filter(
          (detail, index, self) =>
            index ===
            self.findIndex((t) => t.contractDealer === detail.contractDealer)
        );

      setGeneratorQuantity(uniqueScrapByGeneratorDetails.length.toString());
      setDealerQuantity(uniqueScrapDealer.length.toString());
    }
  }, [scrapByGeneratorDetails]);

  return (
    <div className="flex-column" style={{ marginBottom: "1rem" }}>
      <div
        className="flex-row expanded-section-card-scrap justify-between"
        style={{ gap: "10px" }}
      >
        <div
          style={{
            backgroundColor: "#FFF",
            borderRadius: "5px",
            padding: "10px",
            width: "98%",
          }}
          className="flex-column justify-center align-center"
        >
          {totalDetails && (
            <div style={{ width: "100%" }} className="flex-row justify-between">
              <p>{t("label-average-annual-price")}</p>
              <a onClick={() => setShowLegend((prev) => !prev)}>
                <SparkIcon icName="list-view" noPadding />
              </a>
            </div>
          )}
          <MultiCountryAverageChart
            data={totalDetails}
            showLegend={showLegend}
          />
        </div>
        <div
          className="flex-row chart-cards"
          style={{ gap: "10px", width: "100%" }}
        >
          <div className="flex-column" style={{ gap: "10px", width: "100%" }}>
            <ChartMinimalCardView
              data={
                receiveNumberFormatWithMilhar(
                  totalDetails?.years[0].quantityDealed
                ) || "0"
              }
              title={t("label-quantity-traded")}
            />
            <ChartMinimalCardView
              data={
                receiveNumberFormatWithMilhar(
                  totalDetails?.years[0].quantityToDeal
                ) || "0"
              }
              title={t("label-quantity-to-negotiate")}
            />
          </div>
          <div className="flex-column" style={{ gap: "10px", width: "100%" }}>
            <ChartMinimalCardView
              data={generatorQuantity}
              title={t("label-scrap-generators")}
            />
            <ChartMinimalCardView
              data={dealerQuantity}
              title={t("label-scrap-dealers")}
            />
          </div>
        </div>
        <div className="chart-mock">
          <img src={ChartEmptyImg} alt="chat-empty" />
        </div>
      </div>
    </div>
  );
};

export default ScrapCategoryPanel;
