import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useRef, useEffect } from "react";
import { useAlert } from "../../../../../contexts/alert-context";
import { SpecialTechnologyDTO } from "../special-technologies-DTO";
import {
  addNew,
  getSpecialTechnologyById,
} from "../special-technologies-service";
import { extractSubstringFile } from "../../../../../services/string-treatment";
import {
  SparkIcon,
  SparkTableBody,
  SparkTableCell,
  SparkTableRow,
} from "@bosch-web-dds/spark-ui-react";

interface ModalProps {
  onClose: () => void;
  contactId: string;
}

const SpecialTechnologyForm: React.FC<ModalProps> = ({
  onClose,
  contactId,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { setAlert } = useAlert();
  const [specialTechnology, setSpecialTechnology] =
    useState<SpecialTechnologyDTO | null>(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<SpecialTechnologyDTO>();

  const [selectedImages, setSelectedImages] = useState<File[]>([]);
  const [previews, setPreviews] = useState<string[]>([]);
  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files || []) as File[];
    setSelectedImages(files);

    const previewsArray = files.map((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      return new Promise<string>((resolve) => {
        reader.onloadend = () => {
          if (typeof reader.result === "string") {
            resolve(reader.result);
          }
        };
      });
    });

    Promise.all(previewsArray).then((previews) => {
      setPreviews(previews);
    });
  };

  useEffect(() => {
    if (contactId) {
      getSpecialTechnologyById(contactId).then((data) => {
        if (data) {
          setSpecialTechnology(data);
          Object.entries(data).forEach(([key, value]) => {
            if (typeof value === "object" && value !== null) {
              Object.entries(value as Record<string, unknown>).forEach(
                ([subKey, subValue]) => {
                  setValue(
                    `${key}.${subKey}` as keyof SpecialTechnologyDTO,
                    subValue as any
                  );
                }
              );
            } else {
              setValue(key as keyof SpecialTechnologyDTO, value as any);
            }
          });
        }
      });
    }
  }, [id, setValue]);

  const onSubmit = async (contact: SpecialTechnologyDTO) => {
    try {
      if (id) {
        await addNew(contact, id, selectedImages);
        setAlert({ text: t("alert-successfully-registered"), type: "success" });
        onClose();
      }
    } catch (e) {
      console.error("Erro ao cadastrar: ", e);
      setAlert({ text: `${t("alert-error-registered")}:`, type: "error" });
    }
  };

  return (
    <div className="modal-content">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-fields flex-column">
          <h2>{t("label-special-technology")}</h2>

          <div className="flex-column">
            <label htmlFor="technology">{`${t("label-special-technology")}*`}</label>
            <input
              placeholder={t("placeholder.generalEmpty")}
              type="text"
              id="technology"
              {...register("specialTechnologyInfo.specialTechnologyTitle", {
                required: true,
              })}
            />
            {errors.specialTechnologyInfo?.specialTechnologyTitle && (
              <span>{t("error-input-required")}</span>
            )}
          </div>

          <div className="flex-column">
            <label htmlFor="description">{`${t("label-description")}*`}</label>
            <textarea
              placeholder={t("placeholder.generalEmpty")}
              id="description"
              {...register("specialTechnologyInfo.specialTechnology", {
                required: true,
              })}
            />
            {errors.specialTechnologyInfo?.specialTechnology && (
              <span>{t("error-input-required")}</span>
            )}
          </div>

          <div className="form-fields flex-column">
            <div className="flex-column">
              <label htmlFor="observations">
                {t("label-general-observations")}
              </label>
              <textarea
                placeholder={t("placeholder.observations")}
                id="observations"
                {...register("observations.observations")}
              />
            </div>
          </div>

          <br />

          <div className="flex-column">
            <div className="input-and-preview">
              <input
                type="file"
                id="imageUpload"
                accept="*"
                multiple
                onChange={handleImageChange}
              />
            </div>
          </div>

          {specialTechnology?.specialTechnologyInfo?.files?.length &&
            specialTechnology?.specialTechnologyInfo?.files?.length > 0 && (
              <div className="flex-column">
                <div className="custom-line-hr" />
                <h4>
                  <strong>{t("label-documents")}</strong>
                </h4>
                <SparkTableBody>
                  {specialTechnology?.specialTechnologyInfo?.files?.map(
                    (rowItem: any, rowIndex: any) => (
                      <SparkTableRow
                        key={rowIndex}
                        onClick={() => window.open(rowItem, "_blank")}
                      >
                        <SparkTableCell
                          style={{ color: "var(--bosch-accent-blue)" }}
                        >
                          {extractSubstringFile(rowItem)}
                        </SparkTableCell>
                        <SparkTableCell className="icon-column">
                          <SparkIcon
                            pallete="tertiary"
                            className="icon-flag"
                            no-padding={true}
                            icName={"upload"}
                          />
                        </SparkTableCell>
                      </SparkTableRow>
                    )
                  )}
                </SparkTableBody>
              </div>
            )}

          <hr></hr>

          <div className="flex-row buttons-section">
            <button className="neutral-white" onClick={onClose} type="button">
              {t("label-back")}
            </button>
            <button className="blue" type="submit">
              {t("label-save")}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SpecialTechnologyForm;
