import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { ApprovalPendingItemDTO } from "../../../../components/ApproveGenericCard/models/ApprovalPendingDTO";
import { useTranslation } from "react-i18next";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { storage } from "../../../../utils/firebase-config";
import { ScrapsCategoryDTO } from "../../../scraps/scrap-category/models/scrap-category-DTO";
import { physicalStateENUM } from "../../../../assets/enums/physical-state-enum";
import { unityENUM } from "../../../../assets/enums/unit-enum";
import MTRCategoryClassInputAutocomplete from "../../../../components/MTRCategoryClassInputAutocomplete/MTRCategoryClassInputAutocomplete";
import MTRCategoryInputAutocomplete from "../../../../components/MTRCategoryInputAutocomplete/MTRCategoryInputAutocomplete";
import PhisicalStateEnumInputAutocomplete from "../../../../components/PhisicalStateEnumInputAutocomplete/PhisicalStateEnumInputAutocomplete";
import UnitEnumInputAutocomplete from "../../../../components/UnitEnumInputAutocomplete/UnitEnumInputAutocomplete";

interface ScrapCategoryFormProps {
  item: ApprovalPendingItemDTO;
  onApprove: (data: ApprovalPendingItemDTO) => void;
  onReprove: (data: ApprovalPendingItemDTO) => void;
  onClose: () => void;
}

const ScrapCategoryMinimalViewForm: React.FC<ScrapCategoryFormProps> = ({
  item,
  onApprove,
  onReprove,
  onClose,
}) => {
  const { t } = useTranslation();
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [preview, setPreview] = useState(item.data?.scrapsCategoryInfo?.image);
  const [selectedUnity, setSelectedUnity] = useState("");
  const [physicalState, setPhysicalState] = useState("");

  const handleImageChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        if (typeof reader.result === "string") {
          setPreview(reader.result);
        }
      };
      reader.readAsDataURL(file);
    }
    setPreview("");
  };

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<ScrapsCategoryDTO>({
    defaultValues: item.data,
  });

  const [isHazardousMaterial, setIsHazardousMaterial] = useState(
    item.data.scrapsCategoryInfo.isHazardousMaterial
  );

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsHazardousMaterial(event.target.value === "true");
  };

  const handleReprove = handleSubmit((data) => {
    onReprove({ ...item, data });
  });

  const handleUnityChange = (event: any) => {
    const { value } = event.target;
    setSelectedUnity(t(`unityENUM.${value}`));
    setValue("scrapsCategoryInfo.unity", value);
  };
  const handlePhysicalStateChange = (event: any) => {
    const { value } = event.target;
    setPhysicalState(t(`physicalStateENUM.${value}`));
    setValue("scrapsCategoryInfo.phisycalState", value);
  };

  const handleAprove = handleSubmit(async (data) => {
    if (selectedImage) {
      const storageRef = ref(storage, `scrap-category/${selectedImage.name}`);
      await uploadBytes(storageRef, selectedImage);
      const imageURL = await getDownloadURL(storageRef);
      data.scrapsCategoryInfo.image = imageURL;
    }
    if (isHazardousMaterial === "true") {
      data.scrapsCategoryInfo.isHazardousMaterial = true;
    } else {
      data.scrapsCategoryInfo.isHazardousMaterial = false;
    }
    onApprove({ ...item, data });
  });

  return (
    <form className="modal-content" onSubmit={handleAprove}>
      <div className="form-fields flex-column">
        <div className="flex-column">
          <label htmlFor="name">{`${t("label-name")}*`}</label>
          <input
            id="name"
            {...register("scrapsCategoryInfo.name", { required: true })}
          />
          {errors.scrapsCategoryInfo?.name && (
            <span>{t("error-required-field")}</span>
          )}
        </div>

        <div className="flex-column">
          <label htmlFor="legalName">{t("label-description")}</label>
          <MTRCategoryInputAutocomplete
            initialValue={getValues("scrapsCategoryInfo.description")}
            label=""
            onChange={(data) => {
              setValue("scrapsCategoryInfo.description", data)
              setValue("scrapsCategoryInfo.ibamaCode", data.code)
            }}
            placeholder=""
          ></MTRCategoryInputAutocomplete>
        </div>

        <div className="flex-column">
          <label htmlFor="legalName">{t("label-material-class")}</label>
          <MTRCategoryClassInputAutocomplete
            initialValue={getValues("scrapsCategoryInfo.materialClass")}
            label=""
            onChange={(data) => setValue("scrapsCategoryInfo.materialClass", data)}
            placeholder=""
          ></MTRCategoryClassInputAutocomplete>
        </div>

        <div className="flex-column">
          <label htmlFor="legalName">{t("label-ibama-code")}</label>
          <input
            disabled
            placeholder={t("placeholder.generalEmpty")}
            type="text"
            id="legalName"
            {...register("scrapsCategoryInfo.ibamaCode", {
              required: false,
            })}
          />
        </div>

        {/* <div className="flex-column">
          <label htmlFor="legalName">{t("label-physical-state")}</label>
          <select
            id="physical-state"
            {...register("scrapsCategoryInfo.phisycalState", { required: true })}
            onChange={handlePhysicalStateChange}
          >
            <option value=""></option>
            {Object.entries(physicalStateENUM).map(([key, value]) => (
              <option key={key} value={key}>
                {t(`physicalStateENUM.${value}`)}
              </option>
            ))}
          </select>
          {errors.scrapsCategoryInfo?.unity && (
            <span>{t("error-input-required")}</span>
          )}
        </div>

        <div className="flex-column">
          <label htmlFor="legalName">{t("material-quantity-unity")}</label>
          <select
            id="material-quantity-unity"
            {...register("scrapsCategoryInfo.unity", { required: true })}
            onChange={handleUnityChange}
          >
            <option value=""></option>
            {Object.entries(unityENUM).map(([key, value]) => (
              <option key={key} value={key}>
                {t(`unityENUM.${value}`)}
              </option>
            ))}
          </select>
          {errors.scrapsCategoryInfo?.unity && (
            <span>{t("error-input-required")}</span>
          )}
        </div> */}

        <div className="flex-column">
          <label htmlFor="legalName">{t("label-physical-state")}</label>
          <PhisicalStateEnumInputAutocomplete
            initialValue={getValues("scrapsCategoryInfo.phisycalState")}
            label=""
            onChange={(data) => {
              setValue("scrapsCategoryInfo.phisycalState", data)
            }}
            placeholder=""
          ></PhisicalStateEnumInputAutocomplete>
        </div>
        <div className="flex-column">
          <label htmlFor="legalName">{t("material-quantity-unity")}</label>
          <UnitEnumInputAutocomplete
            label=""
            initialValue={getValues("scrapsCategoryInfo.unity")}
            onChange={(data) => setValue("scrapsCategoryInfo.unity", data)}
            placeholder=""
          ></UnitEnumInputAutocomplete>
        </div>

        <div className="flex-column">
          <label htmlFor="imageUpload" className="custom-file-upload title">
            {t("label-material-pic")}
          </label>
          <div className="input-and-preview-column">
            <input
              type="file"
              id="imageUpload"
              accept="image/*"
              onChange={handleImageChange}
            />
            {preview && (
              <div className="image-selected">
                <img className="preview-img" src={preview} alt="Preview" />
              </div>
            )}
          </div>
        </div>

        <div className="flex-column">
          <label>{`${t("label-type")}*`}</label>
          <div className="flex-row radio-types">
            <label htmlFor="isNonHazardous">
              <input
                type="radio"
                id="isNonHazardous"
                value="false"
                {...register("scrapsCategoryInfo.isHazardousMaterial", {
                  required: true,
                })}
                onChange={handleRadioChange}
                checked={isHazardousMaterial === false}
              />
              {t("label-non-hazardous-material")}
            </label>

            <label htmlFor="isHazardous">
              <input
                type="radio"
                id="isHazardous"
                value="true"
                {...register("scrapsCategoryInfo.isHazardousMaterial", {
                  required: true,
                })}
                onChange={handleRadioChange}
                checked={isHazardousMaterial === true}
              />
              {t("label-hazardous-material")}
            </label>
          </div>
          {errors.scrapsCategoryInfo?.isHazardousMaterial && (
            <span>{t("error-required-field")}</span>
          )}
        </div>
      </div>

      <div className="flex-column" style={{ gap: "5px" }}>
        <label htmlFor="observations">{t("label-general-observations")}</label>
        <textarea
          id="observations"
          {...register("observations.observations")}
        />
      </div>

      <div className="flex-row buttons-section">
        <button type="button" onClick={onClose}>
          {t("label-cancel")}
        </button>
        <button type="button" className="red" onClick={handleReprove}>
          {t("label-reprove")}
        </button>
        <button type="submit" className="green">
          {t("label-approve")}
        </button>
      </div>
    </form>
  );
};

export default ScrapCategoryMinimalViewForm;
