import React, { useEffect, useState } from "react";
import Select from "react-select";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import { useTranslation } from "react-i18next";
import { MTRTreatmentDTO } from "./models/mtrtreatment-DTO";

const MTRTreatmentInputAutocomplete: React.FC<{
  label: string;
  placeholder: string;
  control: any;
  name: string;
  errors: any;
  onChange: (treatmentMTRCode: MTRTreatmentDTO | null) => void; // O tratamento é opcional
  initialValue?: MTRTreatmentDTO | null; // Valor inicial opcional
  isDisabled?: boolean;
}> = ({
  label,
  placeholder,
  control,
  name,
  errors,
  onChange,
  initialValue,
  isDisabled,
}) => {
  const [treatmentOptions, setTreatmentOptions] = useState<any[]>([]);
  const [selectedTreatment, setSelectedTreatment] = useState<MTRTreatmentDTO | null>(initialValue || null);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      const db = getFirestore();
      const treatmentCollectionRef = collection(db, "MTRTreatment");
      const treatmentSnapshot = await getDocs(treatmentCollectionRef);
      const options = treatmentSnapshot.docs.map((doc) => {
        const treatmentData = doc.data() as MTRTreatmentDTO;
        return {
          value: treatmentData.code,
          label: treatmentData.name,
          data: treatmentData,
        };
      });
      setTreatmentOptions(options);
    };
    fetchData();
  }, []);

  // Atualiza o valor inicial quando fornecido
  useEffect(() => {
    if (initialValue) {
      setSelectedTreatment(initialValue);
    }
  }, [initialValue]);

  const handleChange = (selectedOption: any) => {
    if (selectedOption && selectedOption.data) {
      setSelectedTreatment(selectedOption.data);
      onChange(selectedOption.data); // Atualiza o valor no formulário
    } else {
      setSelectedTreatment(null);
      onChange(null);
    }
  };

  return (
    <div className="flex-column select-field">
      <label htmlFor="mtr-treatment">{label}</label>
      <Select
        id="mtr-treatment"
        placeholder={placeholder}
        value={
          selectedTreatment
            ? { value: selectedTreatment.code, label: selectedTreatment.name }
            : null
        }
        onChange={handleChange}
        options={treatmentOptions}
        isDisabled={isDisabled}
      />

    </div>
  );
};

export default MTRTreatmentInputAutocomplete;
