import React, { useState, useEffect, useRef } from "react";
import { Outlet, useNavigate, useParams, useLocation } from "react-router-dom";
import { SparkTabNavigation } from "@bosch-web-dds/spark-ui-react";
import { useTranslation } from "react-i18next";
import { getScrapCategoryById } from "../../scraps-service";
import { useAuth } from "../../../../contexts/auth-context";
import { ScrapsCategoryDTO } from "../models/scrap-category-DTO";

const DropdownMenu = ({ show, onClose, options, position }: any) => {
  const menuRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        onClose(null);
      }
    };

    if (show) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [show, onClose]);

  if (!show) {
    return null;
  }

  return (
    <div
      ref={menuRef}
      className="dropdown-menu"
      style={{ top: position.top, left: position.left }}
    >
      <ul>
        {options.map((option: any, index: any) => (
          <li key={index} onClick={() => onClose(option)}>
            {option.label}
          </li>
        ))}
      </ul>
    </div>
  );
};

const ScrapCategoryDetails: React.FC<{ enabledUsers: string[] }> = ({ enabledUsers }) => {
  const { id } = useParams();
  const location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userDoc, loading } = useAuth();
  const profile = userDoc?.profile;
  const [scrapsCategory, setScrapsCategory] = useState<ScrapsCategoryDTO | null>(null);
  const [tabs] = useState([
    { value: "pannel", label: t("label-panel") },
    { value: "register", label: t("label-register") },
    { value: "generators", label: t("label-scrap-generators") },
    { value: "dealers", label: t("label-scrap-dealers") },
    { value: "to-deal", label: t("label-toDeal") },
    { value: "in-bid", label: t("label-in-bid") },
    { value: "in-approval", label: t("label-in-approval") },
    { value: "contracted", label: t("statusEnum.CONTRACTED") },
  ]);
  const [tempTabs, setTempTabs] = useState<any[]>(tabs)

  const [selectedTab, setSelectedTab] = useState("pannel");

  useEffect(() => {
    const fetchScrapCategory = async () => {
      if (!id) return;
      try {
        const data = await getScrapCategoryById(id);
        if (data) {
          setScrapsCategory(data);
        } else {
          console.error("Data is undefined");
        }
      } catch (error) {
        console.error("Error fetching scrap dealer:", error);
      }
    };
    fetchScrapCategory();
  }, [id]);

  useEffect(() => {
    const currentPath = location.pathname.split("/").pop();
    const allOptions = [...tempTabs];
    const matchedOption = allOptions.find(option => option.value === currentPath);
    if (matchedOption) {
      setSelectedTab(matchedOption.value);
    }
  }, [location]);

  const handleTabChange = (event: Event, data: { value: string }) => {
    const newTab = data.value;
      setTempTabs([])
      setTempTabs(tabs);
      setSelectedTab(newTab);
      navigate(newTab);
  };

  if (loading) return null;

  if (enabledUsers.includes(profile || "public")) {
    return (
      <div className="page page-centered">
        <h2>{scrapsCategory?.scrapsCategoryInfo.name}</h2>
        <SparkTabNavigation
          items={tempTabs}
          selectedTab={selectedTab}
          whenChange={handleTabChange}
        />
        <Outlet />

      </div>
    );
  } else {
    navigate("/page-not-found")
    return <></>
  }
};

export default ScrapCategoryDetails;
