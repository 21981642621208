import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { COLLECTIONS } from "../../assets/enums/firebase-colections";
import {
  callFunction,
  QueryOptions,
  saveFiles,
  saveNewAsync,
  saveNewWithId,
  update,
} from "../../services/persistence";
import { CollectItemDTO } from "./models/collect-item-DTO";
import { firestore } from "../../utils/firebase-config";
import {
  CollectStatus,
  FinancialStatus,
} from "../../assets/enums/collects-enum";
import { FinancialDTO } from "../financial/financial-DTO";
import { CDFDTO } from "./components/cdf-section/modal-cdf/cdf-dto";
import { ContactInfoDTO } from "../contacts/contacts-DTO";
import i18n, { defaultLanguage } from "../../assets/i18n/i18n";
import { generateEmailTemplate } from "../../assets/templates/email-template";
import {
  sendEmailGeneratorOrDealer,
  sendEmailSDM,
} from "../../services/send-email-SDM";
import { t } from "i18next";
import { useAlert } from "../../contexts/alert-context";
import { getSuccessFee } from "../scrap-generators/scrap-generator-service";

export async function addNewCollect(
  data: CollectItemDTO,
  NFFiles: File[],
  MTRFiles: File[],
  TicketFiles: File[]
) {
  let financialItem: FinancialDTO = {
    id: "",
    status: "",
    scrapMaterial: undefined,
    generatorId: "",
    contractedDealerId: "",
    totalValue: undefined,
    valueFee: undefined,
    scrapGeneratorName: "",
    scrapDealerName: "",
    scrapMaterialName: "",
    currency: "",
    locale: "",
    quantity: 0,
  };
  const fee = await getSuccessFee(data.generatorId);


  financialItem.contractedDealerId = data.contractedDealerId;
  financialItem.status = await returnFinancialStatus(data);
  financialItem.dateToCollect = new Date();
  financialItem.scrapMaterial = data.scrapMaterial;
  financialItem.generatorId = data.generatorId;
  financialItem.totalValue = data.totalValue;
  financialItem.valueFee = (fee.value / 100 ) * data.totalValue;
  financialItem.scrapGeneratorName = data.scrapGeneratorName;
  financialItem.scrapDealerName = data.scrapDealerName;
  financialItem.scrapMaterialName = data.scrapMaterialName;
  financialItem.currency = data.currency;
  financialItem.locale = data.locale;
  financialItem.quantity = data.quantity;
  data.status = defineCollectStatus(data, MTRFiles);
  if (data.id === undefined) {
    const newDocRef = await saveNewAsync(COLLECTIONS.COLLECTS, data);
    saveFilesCollect(NFFiles, MTRFiles, TicketFiles, newDocRef.id);
    if (financialItem?.status?.length > 0) {
      financialItem.dateToCollect = new Date();
      saveNewWithId(COLLECTIONS.FINANCIAL, financialItem, newDocRef.id);
    }
  } else {
    await update(COLLECTIONS.COLLECTS, data, data.id);
    saveFilesCollect(NFFiles, MTRFiles, TicketFiles, data.id);
    if (financialItem?.status?.length > 0) {
      update(COLLECTIONS.FINANCIAL, financialItem, data.id);
    }
  }
  try {
    if (data.automaticMTR && !data.attachmentMTR) {
      await callFunction("generatemtr", {
        collectId: data.id,
      })
    }
    return { success: true };
  } catch (error) {
    return { success: false, error: `Erro MTR: ${error}` };
  }
}

function defineCollectStatus(data: CollectItemDTO, MTRFiles: File[]) {
  let status = CollectStatus.SCHEDULED;
  if (
    data.status == CollectStatus.CANCELLED ||
    data.status == CollectStatus.FINISHED
  ) {
    return data.status;
  }
  if (data.receivedQtd && data.receivedQtd > 0) {
    return CollectStatus.FINISHED;
  }

  if (MTRFiles.length > 0) {
    return CollectStatus.MTR_GENERATED;
  }

  if (data.quantity && data.quantity > 0) {
    return CollectStatus.WAITING_MTR;
  }
  return status;
}

export async function addNewScheduled(data: CollectItemDTO, language: string) {
  await saveNewAsync(COLLECTIONS.COLLECTS, data);
  sendNewCollectRequestEmail(data, language);
}

function saveFilesCollect(
  NFFiles: File[],
  MTRFiles: File[],
  TicketFiles: File[],
  id: string
) {
  if (NFFiles && NFFiles.length > 0) {
    saveFiles(
      COLLECTIONS.COLLECTS,
      id,
      "Collects_Invoice",
      NFFiles,
      "invoiceAttachment"
    );
  }

  if (MTRFiles && MTRFiles.length > 0) {
    saveFiles(
      COLLECTIONS.COLLECTS,
      id,
      "Collects_MTR",
      MTRFiles,
      "attachmentMTR"
    );
  }
  if (TicketFiles && TicketFiles.length > 0) {
    saveFiles(
      COLLECTIONS.COLLECTS,
      id,
      "Collects_Tickets",
      TicketFiles,
      "attachamentticket"
    );
  }
}

export async function getAllCollects(
  options?: QueryOptions
): Promise<CollectItemDTO[]> {
  const collectsCollection = collection(firestore, COLLECTIONS.COLLECTS);

  let collectsQuery;
  if (options) {
    const { fieldName, operator, value } = options;
    collectsQuery = query(
      collectsCollection,
      where(fieldName, operator, value)
    );
  } else {
    collectsQuery = collectsCollection;
  }

  const collectsSnapshot = await getDocs(collectsQuery);
  const collectsList = collectsSnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
    searchData: doc
      .data()
      ["scrapMaterialName"].concat(
        " ",
        doc.data()["scrapGeneratorName"],
        " ",
        doc.data()["scrapDealerName"]
      ),
  })) as unknown as CollectItemDTO[];

  return collectsList;
}

async function returnFinancialStatus(data: CollectItemDTO) {
  if (
    data.status == CollectStatus.WAITING_MTR ||
    data.status == CollectStatus.SCHEDULED ||
    data.status == CollectStatus.MTR_GENERATED
  ) {
    return FinancialStatus.TO_COLLECT;
  } else if (
    data.status == CollectStatus.MTR_GENERATED ||
    data.status == CollectStatus.FINISHED
  ) {
    return FinancialStatus.TO_RECEIVE;
  } else {
    return "";
  }
}

export async function addNewCDF(
  data: CDFDTO,
  mtrId: string,
  attachments: File[]
) {
  calculeForSendSdmEmail(data);
  await update(
    COLLECTIONS.COLLECTS,
    {
      hasCDF: true,
      receivedQtd: Number(data.receivedQtd),
      status: CollectStatus.FINISHED,
    },
    mtrId
  );
  if (data.id) {
    update(COLLECTIONS.CDF, data, data.id);
    if (attachments && attachments.length > 0) {
      saveFiles(
        COLLECTIONS.CDF,
        data.id,
        "CDR_Invoice",
        attachments,
        "attachments"
      );
    }
  } else {
    data.date = new Date();
    await saveNewWithId(COLLECTIONS.CDF, data, mtrId);
    if (attachments && attachments.length > 0) {
      saveFiles(
        COLLECTIONS.CDF,
        mtrId,
        "CDR_Invoice",
        attachments,
        "attachments"
      );
    }
  }
}

function emailValueDivergencyContent(data: CDFDTO): string {
  return `<div>
    <h2>${i18n.t("label-cdf-divergency-error-title", { lng: "pt" })}</h2>
    <p>${i18n.t("label-cdf-divergency-error-info", { lng: "pt" })}:</p>
    <ul>
      <li><strong>${i18n.t("label-material", { lng: "pt" })}:</strong>
        ${data.material.name}
      </li>
      <li><strong>${i18n.t("label-scrap-dealer", { lng: "pt" })}:</strong>
        ${data.dealerName}
      </li>
      <li><strong>${i18n.t("label-scrap-generator", { lng: "pt" })}:</strong>
        ${data.generatorName}
      </li>
      <li><strong>${i18n.t("label-quantity", { lng: "pt" })}:</strong>
        ${data.material.quantity} (${i18n.t(
    `unityENUM.${data.material.unity}`,
    { lng: "pt" }
  )})
      </li>
      <li><strong>${i18n.t("label-received-quantity", { lng: "pt" })}:</strong>
        ${data.receivedQtd} (${i18n.t(`unityENUM.${data.material.unity}`, {
    lng: "pt",
  })})
      </li>
    </ul>
  </div>`;
}

function calculeForSendSdmEmail(data: CDFDTO) {
  if (
    data.material.quantity / data.receivedQtd < 0.95 ||
    data.material.quantity / data.receivedQtd > 1.05
  ) {
    sendEmailSDM(
      `${t("label-cdf-divergency-error-title")}`,
      `${emailValueDivergencyContent(data)}`
    );
    sendEmailGeneratorOrDealer(
      `${t("label-cdf-divergency-error-title")}`,
      generateEmailTemplate(`${emailValueDivergencyContent(data)}`),
      data.dealerId
    );
    sendEmailGeneratorOrDealer(
      `${t("label-cdf-divergency-error-title")}`,
      generateEmailTemplate(`${emailValueDivergencyContent(data)}`),
      data.generatorId
    );
  }
}

export async function getAllCDFs(
  profile: string,
  generatorId?: string,
  dealerId?: string
): Promise<CDFDTO[]> {
  let queryS;
  if (profile == "admin-bosch" && !generatorId && !dealerId) {
    queryS = collection(firestore, COLLECTIONS.CDF);
  } else if (generatorId) {
    queryS = query(
      collection(firestore, COLLECTIONS.CDF),
      where("generatorId", "==", generatorId)
    );
  } else {
    queryS = query(
      collection(firestore, COLLECTIONS.CDF),
      where("dealerId", "==", dealerId)
    );
  }

  const querySnapshot = await getDocs(queryS);
  const cdfs: CDFDTO[] = [];
  querySnapshot.forEach((doc) => {
    cdfs.push({ ...doc.data(), id: doc.id } as CDFDTO);
  });
  return cdfs;
}

export async function getCDFById(id: string): Promise<CDFDTO | null> {
  try {
    const docRef = doc(firestore, COLLECTIONS.CDF, id);
    const docSnap = await getDoc(docRef);

    return { ...docSnap.data(), id: docSnap.id } as CDFDTO;
  } catch (error) {
    console.error("get cdf error: ", error);
    return null;
  }
}

export async function sendNewCollectRequestEmail(
  scrapAdmin: CollectItemDTO,
  language: string = "en"
) {
  const docRefContact = doc(
    firestore,
    COLLECTIONS.CONTACTS,
    scrapAdmin.contractedDealerId + "contact"
  );
  const docSnap = await getDoc(docRefContact);
  const contact = docSnap.data() as ContactInfoDTO;

  await i18n.changeLanguage(language);

  if (contact.email != null && contact.email.length > 0 && scrapAdmin) {
    const emailContent = `
      <div>
        <h2>${contact.name}, ${i18n.t("label-email-scheduled-new")}!</h2>

        <br/>
        <p><strong>${i18n.t("label-details")}:</strong></p>
        <ul>
         <li><strong>${i18n.t("label-scrap-generator")}:</strong>
            ${scrapAdmin.scrapGeneratorName}
          </li>
          <li><strong>${i18n.t("label-material")}:</strong>
            ${scrapAdmin.scrapMaterialName}
          </li>
          <li><strong>${i18n.t("label-date", language)}:</strong> ${
      scrapAdmin.date
    }</li>
           <li><strong>${i18n.t("label-period", language)}:</strong> ${
      scrapAdmin.period
    }
           </li>
        </ul>
        <br/>
        <p>${i18n.t("label-email-solicitation")}</p>
      </div>
    `;

    const content = generateEmailTemplate(emailContent);

    callFunction("sendemail", {
      to: contact.email,
      subject: i18n.t("email-template-scheduled"),
      content: content,
    });

    await i18n.changeLanguage(defaultLanguage());
  }
}
