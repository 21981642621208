import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CollectItemDTO } from "../../models/collect-item-DTO";
import { getScrapMaterialContractedById } from "../../../scraps/scraps-service";
import {
  ScrapContractDTO,
  ScrapInfoDTO,
} from "../../../scraps/scrap-admin-generator-view/components/scrap-form/models/scrap-DTO";
import { getScrapAdminContract } from "../../../scraps/scrap-admin-service";
import { SparkIcon } from "@bosch-web-dds/spark-ui-react";
import { formatDateToString, formatTimestampDate, transformDateFormat } from "../../../../services/date-format";
import Tooltip from "../../../../components/tooltip/tooltip";
interface CardScheduledItemProps {
  scheduled: CollectItemDTO;
  originEntity: string;
}

const CardScheduledItem: React.FC<CardScheduledItemProps> = ({
  scheduled,
  originEntity,
}) => {
  const { t } = useTranslation();
  const [materialDetails, setMaterialDetails] = useState<ScrapInfoDTO>();
  const [materialContract, setMaterialContract] = useState<ScrapContractDTO>();

  const fetchData = async () => {
    const responseMaterialDetails = await getScrapMaterialContractedById(
      scheduled.scrapMaterial
    );
    setMaterialDetails(responseMaterialDetails);

    const responseMaterialContract = await getScrapAdminContract(
      scheduled.scrapMaterial
    );
    setMaterialContract(responseMaterialContract);
  };

  useEffect(() => {
    fetchData();
  }, [scheduled]);

  return (
    <div className="collect-card flex-row align-center" style={{ gap: 20 }}>
      <div className="flex-row align-center" style={{ gap: 20, flex: 2 }}>
        <button>
          <SparkIcon icName="paperclip" />
        </button>
        <div className="flex-column">
          <span className="item-label">{t("label-material")}</span>
          <Tooltip
            text={
              materialDetails?.scrapCategory.isHazardousMaterial
                ? t("label-hazardous-material")
                : t("label-non-hazardous-material")
            }
          >
            <h4>{materialDetails?.scrapCategory?.name}</h4>
          </Tooltip>
        </div>
      </div>
      {originEntity != "GENERATOR" && (
        <div className="flex-column" style={{ flex: 1.5 }}>
          <span className="item-label">{t("label-scrap-generator")}</span>
          <p>{materialDetails?.originName}</p>
        </div>
      )}
      {originEntity != "DEALER" && (
        <div className="flex-column" style={{ flex: 1.5 }}>
          <span className="item-label">{t("label-scrap-dealer")}</span>
          <p>{materialDetails?.contractDealerName}</p>
        </div>
      )}

      <div className="flex-column" style={{ flex: 1 }}>
        <span className="item-label">{t("label-period")}</span>
        <p style={{ color: "var(--bosch-accent-blue)" }}>
          {t(`collect-periodENUM.${scheduled.period}`)}
        </p>
      </div>

      <div className="flex-column" style={{ flex: 1 }}>
        <span className="item-label">{t("label-date")}</span>
        {scheduled.date && (
          <p style={{ color: "var(--bosch-accent-blue)" }}>
            {scheduled.date? transformDateFormat(scheduled.date.toString()): "-"}
          </p>
        )}
      </div>
    </div>
  );
};

export default CardScheduledItem;
