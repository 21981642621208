import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { COLLECTIONS } from "../assets/enums/firebase-colections";
import { saveNewAsync, updateAsync } from "./persistence";
import { firestore } from "../utils/firebase-config";

export async function savePendingApproval(
  data: any,
  userId: string,
  origin: string
) {
  const pendingData = {
    status: "PENDING",
    requesterId: userId,
    adminId: "",
    id: "",
    origin: origin,
    data: data,
  };

  const docRef = await saveNewAsync(COLLECTIONS.PENDING_APPROVAL, pendingData);
  const pendingDataWithId = {
    ...pendingData,
    id: docRef.id,
  };

  await updateAsync(COLLECTIONS.PENDING_APPROVAL, pendingDataWithId, docRef.id);

  return docRef;
}

export async function saveReprovedItem(id: string, data: any, adminId: string) {
  data.adminId = adminId;
  data.status = "REPROVED";
  return await updateAsync(COLLECTIONS.PENDING_APPROVAL, data, id);
}

export async function saveApprovedItem(id: string, data: any, adminId: string) {
  data.adminId = adminId;
  data.status = "APPROVED";
  return await updateAsync(COLLECTIONS.PENDING_APPROVAL, data, id);
}

export async function getAllPendingItems() {
  const contactsCollectionRef = collection(
    firestore,
    COLLECTIONS.PENDING_APPROVAL
  );

  const q = query(contactsCollectionRef, where("status", "==", "PENDING"));

  const querySnapshot = await getDocs(q);

  const pendingItems = <any>[];
  querySnapshot.forEach((doc: any) => {
    pendingItems.push({ id: doc.id, ...doc.data() });
  });

  return pendingItems;
}

export async function getAllPendingItemsByRequester(requesterId: string) {
  const contactsCollectionRef = collection(
    firestore,
    COLLECTIONS.PENDING_APPROVAL
  );

  const q = query(
    contactsCollectionRef,
    where("status", "==", "PENDING"),
    where("requesterId", "==", requesterId)
  );

  const querySnapshot = await getDocs(q);

  const pendingItems: any[] = [];
  querySnapshot.forEach((doc: any) => {
    pendingItems.push({ id: doc.id, ...doc.data() });
  });

  return pendingItems;
}

export async function getItemById(itemId: string) {
  const itemDocRef = doc(firestore, COLLECTIONS.PENDING_APPROVAL, itemId);
  const itemDoc = await getDoc(itemDocRef);
  if (itemDoc.exists()) {
    return { id: itemDoc.id, ...itemDoc.data() };
  } else {
    throw new Error("Item not found");
  }
}
