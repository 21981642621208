import React, { useEffect, useState } from "react";
import Select, { components, SingleValue, ActionMeta } from "react-select";
import fetchUsers from "./UsersInputAutocompleteService";
import { useTranslation } from "react-i18next";

const UserInputAutocomplete: React.FC<{
  placeholder: string;
  dontShow: any[];
  onChange: (user: any) => void;
  isDisabled?: boolean;
  id: string;
  defaultValue?: any;
}> = ({ placeholder, onChange, isDisabled, defaultValue, id, dontShow }) => {
  const [selectedUser, setSelectedUser] = useState<any | null>(null);
  const [userOptions, setUserOptions] = useState<any[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const users = await fetchUsers({ id });
        const options = users.reduce<any>((acc, user) => {
          if (dontShow.filter((obj) => obj.user.id == user.id).length === 0) {
            acc.push({
              value: user.id,
              label: user.name,
              data: user,
            });
          }
          return acc;
        }, []);

        setUserOptions(options);

        if (defaultValue) {
          const defaultOption = {
            value: defaultValue.id,
            label: defaultValue.name,
            data: defaultValue,
          };
          setSelectedUser(defaultOption);
        }
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };
    fetchData();
  }, [defaultValue]);

  const handleChange = (selectedOption: SingleValue<{ value: any; label: any; data: any }>) => {
    setSelectedUser(selectedOption);
    onChange(selectedOption ? selectedOption.data : null);
  };

  const customOption = (props: any) => (
    <components.Option {...props}>
      <div className="flex-row" style={{ alignItems: "center" }}>
        <label>{props.label}</label>
      </div>
    </components.Option>
  );

  return (
    <div className="flex-column select-field" style={{ position: "relative", zIndex: 1000 }}>
      <Select
        placeholder={placeholder}
        value={selectedUser}
        onChange={handleChange}
        options={userOptions}
        isDisabled={isDisabled}
        closeMenuOnSelect={true}
        hideSelectedOptions={false}
        components={{ Option: customOption }}
      />
    </div>
  );
};

export default UserInputAutocomplete;
