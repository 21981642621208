import {
  getAuth,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from "firebase/auth";
import { firestore } from "../utils/firebase-config";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { COLLECTIONS } from "../assets/enums/firebase-colections";
import { t } from "i18next";


export async function signIn(
  email: string,
  password: string
): Promise<any> {
  try {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );
    const user = userCredential.user;
    const date = new Date().toLocaleString();

    const userRef = doc(firestore, COLLECTIONS.USERS, user.uid);
    const userDoc = await getDoc(userRef);

    if (userDoc.exists()) {
      await updateDoc(userRef, {
        lastAccess: date,
      });
    } else {
      console.error("No such document!");
    }
    const userProfile = userDoc.data();
    return userProfile;
  } catch (error) {
    console.error(error);
    return false;
  }
}

export function resetPasswordByEmail(email: string) {
  const auth = getAuth();
  sendPasswordResetEmail(auth, email)
}

const auth = getAuth();
if (isSignInWithEmailLink(auth, window.location.href)) {
  let email = window.localStorage.getItem('emailForSignIn');
  if (!email) {
    email = window.prompt(`${t("label-login-email-information")}`);
  }
  if (typeof email === "string"){
    signInWithEmailLink(auth, email, window.location.href)
      .then((result) => {
        window.localStorage.removeItem('emailForSignIn');
        window.location.href = '/scrap-dealers'
      })
      .catch((error) => {
        console.error(error);
      });
  }
}

export function signInLinkToEmail(email: string) {
  const auth = getAuth();
  sendSignInLinkToEmail(auth, email, { url: "https://dev-scrapforbetter.web.app/scrap-dealers" , handleCodeInApp: true })
  .then(() => {
    window.localStorage.setItem('emailForSignIn', email);
  })
  .catch((error) => {
    console.error(error);
  });
}

export function signOutFirebase() {
  signOut(auth)
}
