import React, { useState } from "react";
import ModalForgetPassword from "../modal-forget-password/modal-forget-password";
import { useTranslation } from "react-i18next";

const ButtonForgetPassword = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const { t } = useTranslation();

  return (
    <div>
      <a className="forget-password" type="button" onClick={handleOpenModal}>
        <p>{t("label-forget-password")}</p>
      </a>
      {isModalOpen && <ModalForgetPassword onClose={handleCloseModal} />}
    </div>
  );
};

export default ButtonForgetPassword;
