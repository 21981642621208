import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { useAlert } from "../../../contexts/alert-context";
import { OptimizationDTO } from "../models/optimization-DTO";
import { addNewOp } from "../optimization-service";

interface ModalProps {
  onClose: () => void;
  item?: OptimizationDTO;
}

const OptiForm: React.FC<ModalProps> = ({ onClose, item }) => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { setAlert } = useAlert();
  const [op, setOp] = useState<OptimizationDTO>(item || {
    optimizationInfo: {
      date: "",
      optimizations: "",
      originId: "",
    }
  });

  const handlerData = (key: keyof OptimizationDTO['optimizationInfo'], value: string) => {
    setOp((prevState) => ({
      ...prevState,
      optimizationInfo: {
        ...prevState.optimizationInfo,
        [key]: value,
      },
    }));
  };
  const onSubmit = async () => {
    try {
      if (id) {
        await addNewOp(op, id);
        setAlert({ text: t("alert-successfully-registered"), type: "success" });
        onClose();
      }
    } catch (e) {
      console.error("Erro ao cadastrar: ", e);
      setAlert({ text: `${t("alert-error-registered")}:`, type: "error" });
    }
  };

  return (
    <div className="modal-content">
      <form onSubmit={onSubmit}>
        <div className="form-fields flex-column">

          <div className="form-fields flex-column">
            <div className="flex-column">
              <label htmlFor="optimizations">
                {t("label-optimizations")}
              </label>
              <textarea
                value={op.optimizationInfo.optimizations}
                placeholder={t("placeholder.opti")}
                id="optimizations"
                onChange={(e) => {
                  handlerData("optimizations", e.target.value)
                }}
              />
            </div>
          </div>
        </div>
        <br />

        <div className="flex-row buttons-section">
          <button className="neutral-white" onClick={onClose} type="button">
            {t("label-back")}
          </button>
          <button className="blue" type="submit">
            {t("label-save")}
          </button>
        </div>
      </form>
    </div>
  );
};

export default OptiForm;
