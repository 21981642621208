import {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { ObservationsDTO, UserDTO, UserInfoDTO } from "./models/users-DTO";
import { COLLECTIONS } from "../../../../assets/enums/firebase-colections";
import { firestore } from "../../../../utils/firebase-config";
import { getContactObservation } from "../../../contacts/contacts-service";

export async function getUsersById(id: string): Promise<UserInfoDTO[]> {
  const collectionRef = collection(firestore, COLLECTIONS.USERS);

  const q = query(
    collectionRef,
    where("originId", "==", id),
    orderBy("name")
  );

  const querySnapshot = await getDocs(q);

  const items = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
    searchData: doc
    .data()
    ["name"].concat(
      " ",
      doc.data()["email"],
    ),

  })) as unknown as UserInfoDTO[];
  return items;
}

export const getUserById = async (id: string): Promise<UserDTO | null> => {
  const userInfo = await getUser(id);
  const observations = await getContactObservation(id);

  return {
    id,
    userInfo,
    observations,
  };
};

export async function getUser(id: string): Promise<UserInfoDTO> {
  const docRef = doc(firestore, COLLECTIONS.USERS, id);
  const docSnap = await getDoc(docRef);

  return docSnap.data() as UserInfoDTO;
}

export async function getObservation(
  id: string
): Promise<ObservationsDTO> {
  const docRef = doc(firestore, COLLECTIONS.OBSERVATIONS, id);
  const docSnap = await getDoc(docRef);

  return docSnap.data() as ObservationsDTO;
}
