import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import {
  SparkIcon,
  SparkTable,
  SparkTableBody,
  SparkTableCell,
  SparkTableHead,
  SparkTableRow,
} from "@bosch-web-dds/spark-ui-react";
import PartnerRequirementForm from "./partner-requirement-check-form/partner-requirement-check-form";
import { PartnerRequirementInfoDTO } from "../../../settings/components/partners-requirements/partner-requirement-DTO";
import { getPartnerRequirementByOriginId } from "../../../settings/components/partners-requirements/partner-requirement-service";
import { PartnerRequirementDealerDTO } from "./partner-requirement-check-DTO";
import { getPartnerRequirementDealer } from "./partner-requirement-check-service";
import { getAdrressByEntityId } from "../../../../services/address-service"
import { useAuth } from "../../../../contexts/auth-context";
import FilterComponent from "../../../../components/FIlter/filter";
import { transformDateFormat } from "../../../../services/date-format";

const PartnerRequirementCheck: React.FC<{ enabledUsers: string[] }> = ({ enabledUsers }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userDoc } = useAuth();
  const profile = userDoc?.profile;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [partnerRequirement, setPartnerRequirement] = useState<PartnerRequirementInfoDTO[]>([]);
  const [partnerRequirementDealer, setPartnerRequirementDealer] = useState<PartnerRequirementDealerDTO[]>([]);
  const { id } = useParams<{ id: string }>();

  const [tableData, setTableData] = useState<any[]>([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [sortField, setSortField] = useState<string | null>(null);

  const tableHeader = [
    `${t("label-requirement")}`,
    `${t("label-documents")}`,
    `${t("label-sdm-approval")}`,
    `${t("label-expiration")}`,
  ];

  const fetchPartnerRequirement = async () => {
    if (id) {
      try {
        const address = await getAdrressByEntityId(id);
        const partnerRequirements = await getPartnerRequirementByOriginId("bosch", address?.countryName);
        setPartnerRequirement(partnerRequirements);

        const dealerRequirements = await getPartnerRequirementDealer(id);
        setPartnerRequirementDealer(dealerRequirements);

        const combinedArray = partnerRequirements.map((partnerReq) => {
          const matchingDealerReq = dealerRequirements.find(
            (dealerReq) => dealerReq.requirementId === partnerReq.id
          );
          return { ...partnerReq, dealerRequirement: matchingDealerReq };
        });

        setTableData(combinedArray);
        setFilteredData(combinedArray);
      } catch (error) {
        console.error("Error fetching partner requirements: ", error);
      }
    }
  };

  const [requirementId, setRequirementID] = useState<string | null>(null);

  useEffect(() => {
    fetchPartnerRequirement();
  }, [id]);

  useEffect(() => {
    if (sortField) {
      handleSortClick(sortField);
    }
  }, [sortField]);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    fetchPartnerRequirement();
  };

  // Funções de ordenação
  const sortByRequirement = (data: any[]) => {
    return [...data].sort((a, b) => {
      const requirementA = a.requirement?.toString().toLowerCase() || '';
      const requirementB = b.requirement?.toString().toLowerCase() || '';
      return requirementA.localeCompare(requirementB);
    });
  };

  const sortByDocuments = (data: any[]) => {
    return [...data].sort((a, b) => {
      const documentsA = a.dealerRequirement?.files?.length || 0;
      const documentsB = b.dealerRequirement?.files?.length || 0;
      return documentsA - documentsB;
    });
  };

  

  const sortOptions = [
    { label: t("label-requirement"), sortFunction: sortByRequirement },
    { label: t("label-documents"), sortFunction: sortByDocuments },

  ];

  const handleFilterClick = (filteredData: any[]) => {
    setFilteredData(filteredData);
  };

  const handleSortClick = (sortField: string) => {
    const sortOption = sortOptions.find(option => option.label === sortField);
    if (sortOption) {
      const sortedData = sortOption.sortFunction(filteredData);
      setFilteredData(sortedData);
      setSortField(sortField);
    }
  };

  

  if (enabledUsers.includes(profile || "public")) {
    return (
      <div>
        {isModalOpen && (
          <div className="modal-background">
            <PartnerRequirementForm
              requirementId={requirementId ?? ""}
              onClose={handleCloseModal}
            />
          </div>
        )}
        <div className="page">
          <div className="flex-row" style={{ justifyContent: "space-between" }}>
            <FilterComponent
              data={tableData}
              onFilterClick={handleFilterClick}
             
              sortOptions={sortOptions}
            />
          </div>
          <br />
          <div className="info-section">
            <SparkTable>
              <SparkTableHead className="flex-row" style={{ alignItems: "center" }}>
                <SparkTableRow>
                  {tableHeader.map((header, index) => (
                    <SparkTableCell
                      style={{
                        alignItems: "center",
                        justifyContent: index !== 0 ? "center" : "start",
                        display: "flex",
                        flexDirection: "row",
                      }}
                      key={index}
                    >
                      <strong>{header.toUpperCase()}</strong>
                    </SparkTableCell>
                  ))}
                </SparkTableRow>
              </SparkTableHead>

              <SparkTableBody>
                {filteredData.map((rowItem: any, rowIndex: any) => (
                  <SparkTableRow
                    onClick={() => {
                      setRequirementID(rowItem.id);
                      handleOpenModal();
                    }}
                    key={rowIndex}
                  >
                    <SparkTableCell>{rowItem.requirement}</SparkTableCell>
                    <SparkTableCell
                      className="flex-row"
                      style={{ justifyContent: "center" }}
                    >
                      <SparkIcon
                        className="icon-flag"
                        pallete="tertiary"
                        no-padding={true}
                        icName={
                          rowItem.dealerRequirement?.files?.length > 0
                            ? "upload"
                            : "less-minimize"
                        }
                      />
                    </SparkTableCell>

                    <SparkTableCell
                      className="flex-row"
                      style={{ justifyContent: "center" }}
                    >
                      {rowItem.dealerRequirement?.sdmApprovalStatus
                        ? t(`approvalStatusENUM.${rowItem.dealerRequirement?.sdmApprovalStatus}`)
                        : " - "}
                    </SparkTableCell>
                    
                    <SparkTableCell
                      className="flex-row"
                      style={{ justifyContent: "center" }}
                      onClick={()=>console.log(rowItem)}
                    >
                      {t(`${transformDateFormat(rowItem.dealerRequirement?.expirationDocument?.expirationDate) ?? " - "}`)}


                    </SparkTableCell>
                  </SparkTableRow>
                  
                ))}
              </SparkTableBody>
              
            </SparkTable>
            
          </div>
          
        </div>
      </div>
    );
  } else {
    navigate("/page-not-found");
    return <></>;
  }
};

export default PartnerRequirementCheck;
