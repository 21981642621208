import React, { useState, useRef, useEffect } from "react";
import { RiSearch2Line, RiArrowUpDownLine } from "react-icons/ri";
import "./filter.css";

interface FilterComponentProps {
  data: any[];
  onFilterClick: (filteredData: any[]) => void;
  sortOptions?: { label: string; sortFunction: (data: any[]) => any[] }[];
}

const DropdownMenu: React.FC<{
  show: boolean;
  options: { label: string; sortFunction: (data: any[]) => any[] }[];
  buttonRef: React.RefObject<HTMLButtonElement>;
  onClose: (option?: {
    label: string;
    sortFunction: (data: any[]) => any[];
  }) => void;
}> = ({ show, options, buttonRef, onClose }) => {
  const menuRef = useRef<HTMLDivElement | null>(null);
  const [position, setPosition] = useState<{ top: number; left: number }>({
    top: 0,
    left: 0,
  });

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    if (show) {
      document.addEventListener("mousedown", handleClickOutside);
      if (buttonRef.current) {
        const rect = buttonRef.current.getBoundingClientRect();
        setPosition({ top: rect.bottom, left: rect.left });
      }
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [show, onClose, buttonRef]);

  if (!show) return null;

  return (
    <div ref={menuRef} className={`dropdown-menuo ${show ? "visible" : ""}`}>
      <ul>
        {options.map((option, index) => (
          <li key={index} onClick={() => onClose(option)}>
            {option.label}
          </li>
        ))}
      </ul>
    </div>
  );
};

const FilterComponent: React.FC<FilterComponentProps> = ({
  data,
  onFilterClick,
  sortOptions,
}) => {
  const [filterValue, setFilterValue] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.toLowerCase();
    setFilterValue(value);

    const filteredData = data.filter((item) =>
      item.searchData.toLowerCase().includes(value)
    );

    onFilterClick(filteredData);
  };

  const handleToggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const closeDropdown = (option?: {
    label: string;
    sortFunction: (data: any[]) => any[];
  }) => {
    if (option) {
      const sortedData = option.sortFunction(data);
      onFilterClick(sortedData);
    }

    setShowDropdown(false);
  };

  return (
    <div className="filter-component">
      <div className="filter-container">
        <div className="search-container">
          <input
            type="text"
            value={filterValue}
            onChange={handleFilterChange}
            placeholder="Buscar"
            className="filter-input"
          />
          <RiSearch2Line className="search-icon" />
        </div>
        {sortOptions && (
          <button
            ref={buttonRef}
            onClick={handleToggleDropdown}
            className="filter-button"
          >
            <RiArrowUpDownLine className="filter-icon" />
          </button>
        )}
        {showDropdown && (
          <DropdownMenu
            show={showDropdown}
            options={sortOptions || []}
            buttonRef={buttonRef}
            onClose={closeDropdown}
          />
        )}
      </div>
    </div>
  );
};

export default FilterComponent;
