import { SparkIcon } from "@bosch-web-dds/spark-ui-react";
import "./ChartMinimalCardView.css";

interface ModalProps {
  data: string;
  title: string;
  iconName?: any;
}

const ChartMinimalCardView: React.FC<ModalProps> = ({
  data,
  title,
  iconName,
}) => {
  return (
    <div className="minimal-chart">
      <div className="flex-row">
        {iconName && <SparkIcon noPadding icName={iconName} />}
        <h1>{data}</h1>
      </div>
      <h4>{title}</h4>
    </div>
  );
};

export default ChartMinimalCardView;
