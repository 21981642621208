import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { SpecialTechnologyDTO, SpecialTechnologyInfoDTO } from "./special-technologies-DTO";
import { saveFiles, saveNewAsync, saveNewWithId, update } from "../../../../services/persistence";
import { COLLECTIONS } from "../../../../assets/enums/firebase-colections";
import { firestore } from "../../../../utils/firebase-config";
import { ObservationsDTO } from "../../scrap-dealer-DTO";


// add new
export async function addNew(data: SpecialTechnologyDTO, id: string, files: File[]) {
  if (data.id == undefined) {
    data.specialTechnologyInfo.originId = id;
    saveNewAsync(COLLECTIONS.SPECIAL_TECHNOLOGY, data.specialTechnologyInfo).then((docRef) => {
      saveNewWithId(COLLECTIONS.OBSERVATIONS, data.observations, docRef.id);
      if (files.length > 0) {
        saveFiles(
          COLLECTIONS.SPECIAL_TECHNOLOGY,
          docRef.id,
          COLLECTIONS.SPECIAL_TECHNOLOGY,
          files,
          "files"
        );
      }
    });
  } else {
    update(COLLECTIONS.SPECIAL_TECHNOLOGY, data.specialTechnologyInfo, data.id);
    update(COLLECTIONS.OBSERVATIONS, data.observations, data.id);
    if (files.length > 0) {
      saveFiles(
        COLLECTIONS.SPECIAL_TECHNOLOGY,
        data.id,
        COLLECTIONS.SPECIAL_TECHNOLOGY,
        files,
        "files"
      );
    }
  }
}

export async function getSpecialTechnologyByOriginId(id: string): Promise<SpecialTechnologyInfoDTO[]> {
  const specialTechnologysCollectionRef = collection(firestore, COLLECTIONS.SPECIAL_TECHNOLOGY);

  const q = query(specialTechnologysCollectionRef, where("originId", "==", id));

  const querySnapshot = await getDocs(q);

  const items = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
    searchData: doc
    .data()
    ["specialTechnologyTitle"].concat(
      " ",
      doc.data()["specialTechnology"]
    ),
  })) as unknown as SpecialTechnologyInfoDTO[];
  return items;
}

export const getSpecialTechnologyById = async (
  id: string
): Promise<SpecialTechnologyDTO | null> => {
  const specialTechnologyInfo = await getSpecialTechnology(id);
  const observations = await getSpecialTechnologyObservation(id);

  return {
    id,
    specialTechnologyInfo,
    observations,
  };
};

export async function getSpecialTechnology(id: string): Promise<SpecialTechnologyInfoDTO> {
  const docRef = doc(firestore, COLLECTIONS.SPECIAL_TECHNOLOGY, id);
  const docSnap = await getDoc(docRef);

  return docSnap.data() as SpecialTechnologyInfoDTO;
}

export async function getSpecialTechnologyObservation(
  id: string
): Promise<ObservationsDTO> {
  const docRef = doc(firestore, COLLECTIONS.OBSERVATIONS, id);
  const docSnap = await getDoc(docRef);

  return docSnap.data() as ObservationsDTO;
}
