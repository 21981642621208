import { doc, getDoc } from "firebase/firestore";
import { firestore } from "../utils/firebase-config";
import { COLLECTIONS } from "../assets/enums/firebase-colections";
import { CountryConfigDTO } from "../pages/settings/components/Country-config/country-config-DTO";


export interface AddressEntityDTO {
  address: string;
  addressNumber: string;
  city: string;
  country: string;
  countryName: string;
  postalCode: string;
  postalCodeLabel: string;
  state: string;
}


export async function getAdrressByEntityId(id: string) {
  const docRef = doc(firestore, COLLECTIONS.ADDRESS, id);
  const docSnap = await getDoc(docRef);

  return docSnap.data() as AddressEntityDTO;
}

export async function getCountryConfigById(id: string) {
    const docRef = doc(firestore, COLLECTIONS.COUNTRY_CONFIG, id);
    const docSnap = await getDoc(docRef);
  
    return docSnap.data() as CountryConfigDTO;
  }
