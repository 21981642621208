import { useTranslation } from "react-i18next";
import addIcon from "../../../../assets/svgs/add.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import ImportExportCsv from "../../../../components/ImportExportCsv/ImportExportCsv";
import FilterComponent from "../../../../components/FIlter/filter";
import NoData from "../../../../components/NoData/NoData";
import { SocialActivityDTO, SocialActivityInfoDTO } from "./social-activities-DTO";
import { useAuth } from "../../../../contexts/auth-context";
import SocialActivityForm from "./social-activities-form/social-activities-form";
import CardSocialActivities from "./card-social-activities/card-social-activities";
import { getSocialActivityByOriginId } from "./social-activities-service";


// Funções de ordenação
const sortByContactName = (data: SocialActivityDTO[]) => {
  return [...data].sort((a, b) => {
    const nameA = a.socialActivityInfo?.socialActivity?.toString().toLowerCase() || "";
    const nameB = b.socialActivityInfo?.socialActivity?.toString().toLowerCase() || "";
    return nameA.localeCompare(nameB);
  });
};



const SocialActivities: React.FC<{ enabledUsers: string[] }> = ({ enabledUsers }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userDoc } = useAuth();
  const profile = userDoc?.profile;
  const { id } = useParams<{ id: string }>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [contacts, setContacts] = useState<SocialActivityInfoDTO[]>([]);
  const [filteredData, setFilteredData] = useState<SocialActivityInfoDTO[]>([]);

  const data = { originId: id };

  const sortOptions = [
    { label: t("label-name"), sortFunction: sortByContactName },
  ];

  const handleFilterClick = (filteredData: SocialActivityInfoDTO[]) => {
    setFilteredData(filteredData);
  };

  const fetchContacts = async () => {
    if (id) {
      try {
        const data = await getSocialActivityByOriginId(id);
        setContacts(data);
        setFilteredData(data);
      } catch (error) {
        console.error("Error fetching contacts: ", error);
      }
    }
  };

  useEffect(() => {
    fetchContacts();
  }, [id]);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    fetchContacts(); // Atualizar a lista de contatos ao fechar o modal
  };

  if (enabledUsers.includes(profile || "public")) {
    return (
      <div>
        {isModalOpen && (
          <div className="modal-background">
            <SocialActivityForm contactId="" onClose={handleCloseModal} />
          </div>
        )}
        <div className="page">
          <div className="flex-row">
            <button className="blue-with-icon button" onClick={handleOpenModal}>
              <img src={addIcon} alt="add icon" />
              <p>{t("label-add-new")}</p>
            </button>
            {profile === "admin-bosch" && (
            <ImportExportCsv
              exportApi="exportcontactscsv"
              importApi="importcontactscsv"
              data={data}
              onClose={handleCloseModal}
            />
            )}
            <FilterComponent
              data={contacts}
              onFilterClick={handleFilterClick}
              sortOptions={sortOptions}
            />
          </div>
          <div className="card-list">
            <br></br>
            {filteredData.map((item, index) => (
              <CardSocialActivities onClose={handleCloseModal} key={index} item={item} />
            ))}
            {contacts.length === 0 && <NoData />}
          </div>
        </div>
      </div>
    );
  } else {
    navigate("/page-not-found");
    return <></>;
  }
};

export default SocialActivities;
