import React, { useEffect, useState } from "react";
import Select, { StylesConfig } from "react-select";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { getScrapMaterials } from "../../pages/scraps/scraps-service";
import { useAuth } from "../../contexts/auth-context";

const ScrapMaterialInputAutocomplete: React.FC<{
  label: string;
  placeholder: string;
  onChange: (material: any) => void;
  initialValue?: string;
  isDisabled?: boolean;
  originEntity?: string;
}> = ({
  label,
  placeholder,
  onChange,
  initialValue,
  isDisabled,
  originEntity,
}) => {
    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>();
    const [materialOptions, setMaterialOptions] = useState<any[]>([]);
    const [selectedMaterial, setSelectedMaterial] = useState<any>(null);
    const [inputValue, setInputValue] = useState("");
    const { userDoc } = useAuth();
    const profile = userDoc?.profile;
    const originId = userDoc?.originId;

    const handleChangeRef = React.useRef(onChange);
    handleChangeRef.current = onChange;

    const fetchData = async () => {
      try {
        let filteredCategories: any[] = [];
        if (initialValue) {
          filteredCategories = await getScrapMaterials();
        } else if (profile === "admin-bosch") {
          if (id) {
            let fieldName: string;
            if (originEntity == "GENERATOR") {
              fieldName = "originId";
            } else {
              fieldName = "contractDealer";
            }
            filteredCategories = await getScrapMaterials(
              {
                fieldName: fieldName,
                operator: "==",
                value: id,
              },
              true
            );
          } else {
            filteredCategories = await getScrapMaterials(undefined, true);
          }

        } else if (originId) {
          filteredCategories = await getScrapMaterials(
            {
              fieldName: "originId",
              operator: "==",
              value: originId,
            },
            true
          );

        }

        const options = filteredCategories?.map((material: any) => ({
          value: material.id,
          label: `${material.scrapCategory.name} - ${material.contractDealerName}`,
          data: material,
        }));

        setMaterialOptions(options);

        if (initialValue) {
          const initialMaterial = options.find(
            (option) => option.value === initialValue
          );

          if (initialMaterial) {
            setSelectedMaterial(initialMaterial.data);
            handleChangeRef.current(initialMaterial.data);
            onChange(initialMaterial.data);
          }
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    useEffect(() => {
      fetchData();
    }, [initialValue, id,]);


    const handleChange = (selectedOption: any) => {
      if (selectedOption && selectedOption.data) {
        setSelectedMaterial(selectedOption.data);
        handleChangeRef.current(selectedOption.data);
      } else {
        setSelectedMaterial(null);
        handleChangeRef.current(null);
      }
    };

    const handleInputChange = (newValue: string) => {
      setInputValue(newValue);
    };

    const customFilterOptions = (candidate: any, input: string) => {
      return candidate?.label?.toLowerCase().includes(input?.toLowerCase());
    };

    const customStyles: StylesConfig<any, false> = {
      option: (provided) => ({
        ...provided,
      }),
    };

    return (
      <div className="flex-column select-field">
        <div className="flex-row justify-between">
          <label htmlFor="material">{label}</label>
        </div>
        <div style={{ margin: "0.5rem 0rem" }}>
          <Select
            id="material"
            placeholder={placeholder}
            value={
              selectedMaterial
                ? {
                  value: selectedMaterial.id,
                  label: `${selectedMaterial.scrapCategory.name} - ${selectedMaterial.contractDealerName} - ${selectedMaterial.scrapCategory.description.name}`,
                }
                : null
            }
            onChange={handleChange}
            onInputChange={(newValue, { action }) => {
              if (action === "input-change") {
                handleInputChange(newValue);
              }
            }}
            options={materialOptions}
            filterOption={customFilterOptions}
            isDisabled={isDisabled}
            styles={customStyles}
          />
        </div>
      </div>
    );
  };

export default ScrapMaterialInputAutocomplete;
