import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../contexts/auth-context";
import { SparkIcon } from "@bosch-web-dds/spark-ui-react";
import { useState, useEffect, useCallback } from "react";
import NewCollectModal from "./components/new-collect-modal-form/new-collect-modal-form";
import NewCollectModalScheduled from "./components/scheduled-section/new-scheduled-modal-form";
import { getAllCollects } from "./collects-service";
import { CollectItemDTO } from "./models/collect-item-DTO";
import CardCollectItem from "./components/card-collect-item/card-collect-item";
import CardScheduledItem from "./components/scheduled-section/card-collect-item-scheduled";
import FilterComponent from "../../components/FIlter/filter";
import NoData from "../../components/NoData/NoData";
import Slider from "../../components/Slider/Slider";
import { CADRItemDTO } from "./models/cadri-item-DTO";
import MTRSection from "./components/mtr-section/mtr-section";
import CADRISection from "./components/cadri-section/cadri-section";
import CDFSection from "./components/cdf-section/cdf-section";
import { CDFDTO } from "./components/cdf-section/modal-cdf/cdf-dto";
import ModalCDF from "./components/cdf-section/modal-cdf/ModalCDF";
import NewCollectModalCadri from "./components/cadri-section/new-collect-modal-form-cadri/new-collect-modal-form-cadri";
import NewFSDRModal from "./components/fsdr-section/modal-fsdr";
import { FSDRItemDTO } from "./components/fsdr-section/fsdr-item-DTO";

const Collects: React.FC<{ enabledUsers: string[] }> = ({ enabledUsers }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userDoc } = useAuth();
  const profile = userDoc?.profile;
  const originId = userDoc?.originId;
  const [selectedCadri, setSelectedCadri] = useState<CADRItemDTO | null>(null);
  const [isFSDRModalOpen, setIsFSDRModalOpen] = useState(false);
  const [selectedfsdr, setselectedfsdr] = useState<CADRItemDTO | null>(null);
  const [isModalNewCollectOpen, setIsModalNewCollectOpen] = useState(false);
  const [collects, setCollects] = useState<CollectItemDTO[]>([]);
  const [scheduled, setScheduled] = useState<CollectItemDTO[] | null>([]);
  const [isModalNewScheduledOpen, setIsModalNewScheduledOpen] = useState(false);
  const [isModalNewCadriOpen, setIsModalNewCadriOpen] = useState(false);
  const [selectedCollect, setSelectedCollect] = useState<CollectItemDTO>();
  const [filteredData, setFilteredData] = useState<CollectItemDTO[]>([]);
  const [viewType, setViewType] = useState<string>("COLLECT");
  const [hovered, setHovered] = useState<string>("");
  const [cdfModal, setCdfModal] = useState(false);
  const [mtrID, setMtrID] = useState("");
  const [fsdrid, setFSDRID] = useState("");
  const [material, setMaterial] = useState<any>();
  const [selectedCDF, setSelectedCDF] = useState<CDFDTO>();
  const [cdfClosed, setCdfClosed] = useState(false);
  const [transationData, setTransitionData] = useState<any>({});
  const [isFSDRSaved, setIsFSDRSaved] = useState<{ [key: string]: boolean }>(
    {}
  );
  const [fsdrmodal, setFSDRModal] = useState(false);

  const location = useLocation();
  const { id } = useParams<{ id: string }>();

  const fetchData = useCallback(async () => {
    try {
      let collectsData: CollectItemDTO[] = [];

      if (profile === "scrap-generator") {
        collectsData = await getAllCollects({
          fieldName: "generatorId",
          operator: "==",
          value: originId,
        });
      } else if (
        profile === "admin-bosch" &&
        location.pathname.includes("collections-generator")
      ) {
        collectsData = await getAllCollects({
          fieldName: "generatorId",
          operator: "==",
          value: id,
        });
      } else if (
        profile === "admin-bosch" &&
        location.pathname.includes("collections-dealer")
      ) {
        collectsData = await getAllCollects({
          fieldName: "contractedDealerId",
          operator: "==",
          value: id,
        });
      } else if (profile === "admin-bosch") {
        collectsData = await getAllCollects();
      }

      setCollects(collectsData);
      setFilteredData(collectsData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [profile, originId]);

  useEffect(() => {
    fetchData();
  }, [cdfModal, fetchData]);

  const handleFilterClick = (filteredData: any[], type: string) => {
    if (type === "COLLECT") {
      setFilteredData(filteredData as CollectItemDTO[]);
    }
  };

  const seeCollectDetailsModalScheduled = (collect: CollectItemDTO) => {
    setScheduled(scheduled);
    setIsModalNewCollectOpen(true);
  };

  const seeCollectDetailsModal = (collect: CollectItemDTO) => {
    setSelectedCollect(collect);
    setIsModalNewCollectOpen(true);
  };

  const addCollectDetailsModal = () => {
    setSelectedCollect(undefined);
    setIsModalNewCollectOpen(true);
  };

  const addCadriModal = () => {
    setSelectedCadri(null);
    setIsModalNewCadriOpen(true);
  };

  const addScheduledModal = () => {
    setScheduled(null);
    setIsModalNewScheduledOpen(true);
  };

  const seeCadriDetailsModal = (cadri: CADRItemDTO) => {
    setSelectedCadri(cadri);
    setIsModalNewCadriOpen(true);
  };

  const seefsdrDetailsModal = (fsdr: CADRItemDTO) => {
    setselectedfsdr(fsdr);
    setIsFSDRModalOpen(true);
  };

  if (enabledUsers.includes(profile || "public")) {
    return (
      <div className="page">
        {isModalNewScheduledOpen && (
          <div className="modal-background">
            <NewCollectModalScheduled
              originEntity={
                location.pathname.includes("collections-generator")
                  ? "GENERATOR"
                  : "GLOBAL"
              }
              onClose={() => {
                setIsModalNewScheduledOpen(false);
                const delay = 1500;
                setTimeout(fetchData, delay);
                setSelectedCollect(undefined);
              }}
              onSave={() => {
                fetchData();
                const delay = 2000;
                setTimeout(fetchData, delay);
              }}
              profile={profile as string}
              item={selectedCollect}
            />
          </div>
        )}
        {isModalNewCollectOpen && (
          <div className="modal-background">
            <NewCollectModal
              originEntity={
                location.pathname.includes("collections-generator")
                  ? "GENERATOR"
                  : "GLOBAL"
              }
              onClose={() => {
                setIsModalNewCollectOpen(false);
                const delay = 1500;
                setTimeout(fetchData, delay);
                setSelectedCollect(undefined);
              }}
              onSave={() => {
                fetchData();
                const delay = 2000;
                setTimeout(fetchData, delay);
              }}
              profile={profile as string}
              item={selectedCollect || undefined}
            />
          </div>
        )}
        {isModalNewCadriOpen && (
          <div className="modal-background">
            <NewCollectModalCadri
              originEntity="GENERATOR"
              profile={profile as string}
              onClose={() => {
                setIsModalNewCadriOpen(false);
                const delay = 1500;
                setTimeout(fetchData, delay);
              }}
              onSave={(newCadri) => {
                const delay = 1500;
                setTimeout(fetchData, delay);
              }}
              item={selectedCadri || undefined}
            />
          </div>
        )}

        {isFSDRModalOpen && (
          <div className="modal-background">
            <NewFSDRModal
              originEntity="someOriginEntity"
              onSave={(data) => {
                const delay = 1500;
                setTimeout(fetchData, delay);
                console.log("Dados salvos:", data);

                setIsFSDRModalOpen(false);
              }}
              onClose={() => {
                setIsFSDRModalOpen(false);
                setselectedfsdr(null);
                const delay = 1500;
                setTimeout(fetchData, delay);
              }}
              profile="user-profile"
              item={selectedfsdr || undefined}
            />
          </div>
        )}

        {cdfModal && (
          <div className="modal-background">
            <ModalCDF
              transationData={transationData}
              mtrID={mtrID}
              defaultValue={selectedCDF}
              material={material}
              onClose={() => {
                setCdfModal(false);
                setSelectedCDF(undefined);
                setMaterial(undefined);
                setCdfClosed(true);
              }}
            ></ModalCDF>
          </div>
        )}
        <div
          className="collects-container flex-column"
          style={{ gap: 10, marginTop: 15 }}
        >
          <div className="flex-row justify-between">
            {(profile === "scrap-generator" ||
              location.pathname.includes("collections-generator")) &&
              viewType != "CDF" &&
              viewType != "MTR" && (
                <button
                  className="blue-with-icon button"
                  style={{ gap: 10 }}
                  onClick={() => {
                    viewType === "CADRI"
                      ? addCadriModal()
                      : viewType == "COLLECT"
                      ? addScheduledModal()
                      : addCollectDetailsModal();
                  }}
                >
                  <SparkIcon icName="add" pallete="primary" noPadding />
                  <p>{t("label-add-new")}</p>
                </button>
              )}
            <Slider
              options={[
                {
                  display: t("label-collects"),
                  value: "COLLECT",
                  viewType: "text",
                },
                { display: "MTR", value: "MTR", viewType: "text" },
                { display: "CADRI", value: "CADRI", viewType: "text" },
                { display: "CDF", value: "CDF", viewType: "text" },
              ]}
              setState={setViewType}
            />
            <FilterComponent
              data={collects}
              onFilterClick={(filteredData) =>
                handleFilterClick(filteredData, viewType)
              }
            />
          </div>
          {viewType === "SCHEDULED" && filteredData.length > 0 ? (
            <div className="flex-column" style={{ gap: 6 }}>
              {filteredData.map((scheduled) => (
                <div
                  key={scheduled.id}
                  onClick={() => seeCollectDetailsModal(scheduled)}
                >
                  <CardScheduledItem
                    scheduled={scheduled}
                    originEntity="GLOBAL"
                  />
                </div>
              ))}
            </div>
          ) : viewType === "COLLECT" && filteredData.length > 0 ? (
            <div className="flex-column" style={{ gap: 5 }}>
              {filteredData.map((collect) => (
                <div
                  key={collect.id}
                  onClick={() => seeCollectDetailsModal(collect)}
                >
                  <CardCollectItem collect={collect} originEntity="GLOBAL" />
                </div>
              ))}
            </div>
          ) : viewType === "MTR" && filteredData.length > 0 ? (
            <MTRSection
              setTransitionData={setTransitionData}
              filteredData={filteredData}
              setHovered={setHovered}
              hovered={hovered}
              seeCollectDetailsModal={seeCollectDetailsModal}
              setMtrID={setMtrID}
              setMaterial={setMaterial}
              setCdfModal={setCdfModal}
            />
          ) : viewType === "CADRI" && originId && profile ? (
            <CADRISection
              originId={originId}
              profile={profile}
              seeCadriDetailsModal={seeCadriDetailsModal}
              seefsdrDetailsModal={seefsdrDetailsModal}
              observer={isModalNewCadriOpen}
              setFSDRID={setFSDRID}
              setFSDRModal={setFSDRModal}
            />
          ) : viewType === "CDF" ? (
            <CDFSection
              setHovered={setHovered}
              hovered={hovered}
              setMtrID={setMtrID}
              setSelectedCDF={setSelectedCDF}
              setCdfModal={setCdfModal}
              cdfClosed={cdfClosed}
              setCdfClosed={setCdfClosed}
            />
          ) : (
            <NoData />
          )}
        </div>
      </div>
    );
  } else {
    navigate("/page-not-found");
    return null;
  }
};

export default Collects;
