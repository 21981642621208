import React from "react";
import industryIcon from "../../../../../assets/svgs/industry.svg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ScrapGeneratorInfoDTO } from "../../../scrap-generators-DTO";

interface Props {
  item: ScrapGeneratorInfoDTO;
}

const CardScrapGenerator: React.FC<Props> = ({ item }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div
      className="flex-row card-generator"
      onClick={() => {
        navigate(`/scrap-generators-details/${item.id}/register`);
      }}
    >
      <div className="flex-row" style={{ gap: 20 }}>
        <img src={industryIcon} alt="industry-icon" />
        <div className="flex-column" style={{ justifyContent: "center" }}>
          <h3>{item.legalName || "-"}</h3>
          <p>
            {t("label-region")}: <strong>{item.region || "-"}</strong>
          </p>
        </div>
      </div>
      <div className="flex-column">
        <p className="type a-link-style">{t("label-waste")}</p>
      </div>
    </div>
  );
};

export default CardScrapGenerator;
