import { t } from "i18next";

const HeaderEmailImg =
  "https://firebasestorage.googleapis.com/v0/b/rbprj-100132.appspot.com/o/templates_config%2Fheader-email.svg?alt=media&token=dc87e73b-7359-403e-bbed-63148c75d404";

export function generateEmailTemplate(contentDiv: string): string {
  return `
      <div style="font-family: Arial, sans-serif; color: #333;">
        <img style="width:100%" src="${HeaderEmailImg}" alt="" />
        <div style="padding:0rem 1rem">
            ${contentDiv}
           
            <p>${t(
              "email-template.go-to-plataform"
            )}: <a href="https://bosch-ssm.web.app/">https://bosch-ssm.web.app/</a></p>
            <p>${t("email-template.sincerely")},</p>
            <p><em>Bosch Scrap Management Solution</em></p>
        </div>
      </div>
    `;
}
