import React from "react";
import { useState, useEffect } from "react";
import { t } from "i18next";
import { StatusEnum } from "../../../../../assets/enums/status-enum";

import { CurrencyInput } from "react-currency-mask";
import { ScrapBidDTO } from "../scrap-form/models/scrap-DTO";
import { MaterialBidTypeEnum } from "../../../../../assets/enums/bid-type-enum";
import { getAdrressByEntityId } from "../../../../../services/address-service";
import { getCountryConfigById } from "../../../../../services/address-service";
import ScrapDealerInputAutocomplete from "../../../../../components/ScrapDealersIInputAutocomplete/ScrapDealersIInputAutocomplete";
import {
  addNewBID,
  fetchFulfilledRequirements,
  fetchPartnerRequirementsTotalByCountry,
} from "../../../scrap-admin-service";
import { update } from "../../../../../services/persistence";
import { useAlert } from "../../../../../contexts/alert-context";
import {
  SparkIcon,
  SparkTable,
  SparkTableBody,
  SparkTableCell,
  SparkTableHead,
  SparkTableRow,
} from "@bosch-web-dds/spark-ui-react";
import ImportExportCsv from "../../../../../components/ImportExportCsv/ImportExportCsv";
import { DealersInvitedTableDTO } from "../../../scrap-generator-scraps-view/components/scrap-form/models/dealers-invited-DTO";
import ScrapDealersSendMailModal from "../scrap-form/components/scraps-dealers-send-mail-modal";
import { COLLECTIONS } from "../../../../../assets/enums/firebase-colections";
import { CountryConfigDTO } from "../../../../settings/components/Country-config/country-config-DTO";

interface ModalProps {
  onClose: () => void;
  id: string;
  scrapsID: any[];
  children?: React.ReactNode;
}

const ModalMultBid: React.FC<ModalProps> = ({
  onClose,
  scrapsID,
  id,
  children,
}) => {
  const [today, setToday] = useState(new Date().toISOString().split("T")[0]);
  const [countryConfigEntity, setCountryConfigEntity] =
    useState<CountryConfigDTO>();
  const { setAlert } = useAlert();
  const [idsToSend, setIdsToSend] = useState<any[]>([]);
  const [sendMailModal, setSendMailModal] = useState(false);
  const [selectedScrapDealer, setSelectedScrapDealer] = useState<any[]>([]);
  const [editTableModalOpen, setEditTableModalOpen] = useState(false);
  const [editTableItem, setEditTableItem] =
    useState<DealersInvitedTableDTO | null>();
  const [mailedIDS, setMailedIDS] = useState<string[]>([]);
  const [invitedDealer, setDealersInvited] = useState<DealersInvitedTableDTO[]>(
    []
  );
  const tableHeader = [
    ``,
    `${t("label-invited")}`,
    `${t("label-region")}`,
    `${t("label-invitation")}`,
    `${t("label-proposed-value")}`,
    `${t("label-attachment")}`,
    `${t("label-status")}`,
  ];

  const getAttachmentText = (attachments: string[]) => {
    let attachmentString: string = "";

    if (attachments.length === 1 && attachments[0].trim() === "") {
      attachments = [];
    }

    if (attachments.length > 0) {
      const attachmentCount = attachments.length;
      attachmentString = `${t("label-attachment")} (${attachmentCount})`;
    } else {
      attachmentString = "-";
    }

    return attachmentString;
  };

  const [data, setData] = useState<ScrapBidDTO>({
    dealersInvited: [],
    bidDate: "",
    bidValue: "",
    bidType: "",
    requirimentsApprovalBid: "",
  });

  const getIconName = (rowItem: any) => {
    if (mailedIDS.includes(rowItem.id)) return "mail-forward";
    if (rowItem.inviteAccepted) return "checkmark-frame";
    if (rowItem.inviteSended) return "mail-forward";
    return "mail";
  };

  function handlerMails(e: any, newId: any) {
    e.stopPropagation();
    setIdsToSend((prev) =>
      prev.filter((obj) => obj.id == newId.id).length > 0
        ? prev.filter((obj) => obj.id != newId.id)
        : [...prev, newId]
    );
  }

  const handleOpenTableModal = (rowItem: DealersInvitedTableDTO) => {
    setEditTableItem(rowItem);
    setEditTableModalOpen(true);
  };

  const fetchEntityCountry = async () => {
    try {
      const data = await getAdrressByEntityId(id as string);
      const countryConfig = await getCountryConfigById(data.country);
      setCountryConfigEntity(countryConfig);
    } catch {}
  };

  useEffect(() => {
    fetchEntityCountry();
  }, []);

  useEffect(() => {
    setData((prevData) => ({
      ...prevData,
      dealersInvited: prevData.dealersInvited.map((obj: any) => ({
        ...obj,
        [obj.inviteSended]: mailedIDS.includes(obj.id),
      })),
    }));
  }, [mailedIDS]);

  function handleData(key: keyof ScrapBidDTO, value: any) {
    setData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  }

  const fetchAllPartnerRequirementsTotal = async () => {
    let total: string = "";
    if (countryConfigEntity?.id) {
      total = (
        await fetchPartnerRequirementsTotalByCountry(
          countryConfigEntity?.country[0]
        )
      ).length.toString();
    }
    return total;
  };

  const fetchAllFulfilledRequirementsTotal = async (id: string) => {
    let total: string = "";

    await fetchFulfilledRequirements(id).then((data) => {
      total = data?.total.toString();
    });
    return total;
  };

  const fetchData = async () => {
    if (selectedScrapDealer) {
      const newDealers = await Promise.all(
        selectedScrapDealer?.map(async (dealer) => ({
          id: dealer.id,
          dealerName: dealer.legalName,
          inviteAccepted: false,
          fulfiledRequirements: `${
            (await fetchAllFulfilledRequirementsTotal(dealer.id)) || "0"
          }/${(await fetchAllPartnerRequirementsTotal()) || "0"}`,
          proposedValue: " ",
          attachment: " ",
          status: " ",
          region: dealer.region,
          inviteSended: false,
        }))
      );

      setDealersInvited((prevData) => {
        const updatedData = prevData.filter((oldDealer) =>
          newDealers.some((newDealer: any) => newDealer.id === oldDealer.id)
        );

        const newItems = newDealers.filter(
          (newDealer: any) =>
            !updatedData.some((oldDealer) => oldDealer.id === newDealer.id)
        );
        return [...updatedData, ...newItems];
      });
    } else {
      setDealersInvited([]);
    }
  };

  useEffect(() => {
    const promises = scrapsID.map((item) => {
      return update(
        COLLECTIONS.SCRAP_BY_GENERATOR_INVITED_TABLE,
        { dealersInvitedList: invitedDealer },
        item.id
      );
    });
    Promise.all(promises);
  }, [invitedDealer]);

  useEffect(() => {
    fetchData();
  }, [selectedScrapDealer]);

  return (
    <div
      className="modal-content"
      style={{ padding: "2rem", width: "70vw", maxWidth: "70vw" }}
    >
      {sendMailModal && (
        <div className="modal-background">
          <ScrapDealersSendMailModal
            onClose={() => {
              setSendMailModal(false);
              setIdsToSend([]);
            }}
            multIDS={idsToSend}
            id={scrapsID || ""}
            materialID={id}
            sendedIdsHandler={setMailedIDS}
            formData={data}
          />
        </div>
      )}
      <h2 style={{ marginBottom: 15 }}>{t("material-about-bid")}</h2>
      <div className="flex-row double-fields margin-topbottom-1">
        <div className="flex-column" style={{ gap: "6px" }}>
          <label htmlFor="material-bid-type">{t("material-bid-type")}</label>
          <select
            id="material-bid-type"
            onChange={(e) => handleData("bidType", e.target.value)}
          >
            <option value=""></option>
            {Object.values(MaterialBidTypeEnum).map((type) => (
              <option key={type} value={type}>
                {t(`bidTypeEnum.${type}`)}
              </option>
            ))}
          </select>
        </div>

        <div className="flex-column" style={{ gap: "6px" }}>
          <label htmlFor="material-bid-date">{t("material-bid-date")}</label>
          <input
            placeholder={t("material-bid-date")}
            type="date"
            min={today}
            id="material-bid-date"
            onChange={(e) => handleData("bidDate", e.target.value)}
          />
        </div>

        <div className="flex-column" style={{ gap: "6px" }}>
          <label htmlFor="material-bid-value">
            {t("material-bid-value")}
            <span style={{ marginLeft: 5, color: "#9e9e9e" }}>
              {`(${countryConfigEntity?.currencyName || "Reais"}/${t(
                `unityENUM.KILO`
              )})`}
            </span>
          </label>
          <CurrencyInput
            currency={countryConfigEntity?.currency}
            locale={countryConfigEntity?.locale}
            onChangeValue={(event, originalValue, maskedValue) => {
              handleData("bidValue", maskedValue.toString());
            }}
            InputElement={
              <input
                placeholder={t("material-bid-value")}
                type="text"
                id="material-bid-value"
              />
            }
          />
        </div>
      </div>

      <div className="flex-column" style={{ gap: "6px" }}>
        <label htmlFor="requiriments-approval-bid">
          {t("label-requiriments-approval-bid")}
        </label>
        <textarea
          placeholder={t("placeholder.requirimentsApprovalBid")}
          id="requiriments-approval-bid"
          onChange={(e) => {
            handleData("requirimentsApprovalBid", e.target.value);
          }}
        />
      </div>

      <div className="flex-column margin-topbottom-1" style={{ gap: "6px" }}>
        <label htmlFor="material-dealers-invited">
          {t("material-dealers-invited")}
        </label>
        <ScrapDealerInputAutocomplete
          onChange={(data) => {
            handleData("dealersInvited", data);
            setSelectedScrapDealer(data);
          }}
          placeholder={t("material-dealers-invited")}
        ></ScrapDealerInputAutocomplete>
      </div>

      {data.dealersInvited?.length > 0 && (
        <div className="flex-column info-section" style={{ width: "auto" }}>
          <div className="flex-row" style={{ justifyContent: "space-between" }}>
            <ImportExportCsv
              exportApi="exportscrapbygeneratorinvitedtablecsv"
              importApi="importscrapbygeneratorinvitedtablecsv"
              data={data}
              onClose={() => {}}
            />
            <SparkIcon icName="cloud-upload" noPadding></SparkIcon>
          </div>
          <SparkTable>
            <SparkTableHead
              className="flex-row"
              style={{ alignItens: "center" }}
            >
              <SparkTableRow>
                {tableHeader?.map((header, index) => (
                  <SparkTableCell
                    style={{
                      alignItens: "center",
                      justifyContent: index != 0 ? "center" : "start",
                      display: "flex",
                      flexDirection: "row",
                      margin: "1rem 0",
                    }}
                    key={index}
                  >
                    <strong>{header.toUpperCase()}</strong>
                  </SparkTableCell>
                ))}
              </SparkTableRow>
            </SparkTableHead>

            <SparkTableBody>
              {invitedDealer.map((rowItem: any, rowIndex: any) => (
                <SparkTableRow
                  key={rowIndex}
                  onClick={(e) => handleOpenTableModal(rowItem)}
                >
                  <SparkTableCell>
                    <input
                      type="checkbox"
                      checked={idsToSend.some((item) => item.id === rowItem.id)}
                      onChange={(e) => {}}
                      onClick={(e: any) =>
                        handlerMails(e, {
                          id: rowItem.id,
                          name: rowItem.dealerName,
                        })
                      }
                    />
                  </SparkTableCell>

                  <SparkTableCell>{rowItem.dealerName}</SparkTableCell>
                  <SparkTableCell
                    className="flex-row"
                    style={{ justifyContent: "center" }}
                  >
                    {rowItem.region}
                  </SparkTableCell>

                  <SparkTableCell
                    className="flex-row"
                    style={{ justifyContent: "center" }}
                  >
                    <SparkIcon
                      className="icon-flag"
                      pallete="tertiary"
                      no-padding={true}
                      icName={getIconName(rowItem)}
                    />
                    <p style={{ color: "white" }}>{"-----"}</p>
                  </SparkTableCell>

                  <SparkTableCell
                    className="flex-row"
                    style={{ justifyContent: "center" }}
                  >
                    {rowItem.proposedValue}
                  </SparkTableCell>
                  <SparkTableCell
                    className="flex-row"
                    style={{ justifyContent: "center" }}
                  >
                    {getAttachmentText(rowItem.attachment)}
                  </SparkTableCell>
                  <SparkTableCell
                    className="flex-row"
                    style={{ justifyContent: "center" }}
                  >
                    <p>
                      {rowItem.status != " "
                        ? t(`dealer-invited-statusENUM.${rowItem.status}`)
                        : "-"}
                    </p>
                  </SparkTableCell>
                </SparkTableRow>
              ))}
            </SparkTableBody>
            <br />
          </SparkTable>
          {idsToSend.length > 0 && (
            <button
              className="blue"
              onClick={(e) => {
                e.preventDefault();
                setSendMailModal(true);
              }}
            >
              {t("send-mails")}
            </button>
          )}{" "}
        </div>
      )}

      <div className="flex-row buttons-section">
        <button
          className="neutral-white"
          onClick={() => onClose()}
          type="button"
          style={{ margin: "1rem" }}
        >
          {t("label-back")}
        </button>
        <button
          style={{ margin: "1rem 0rem" }}
          className="blue"
          type="submit"
          onClick={() => {
            if (
              data.bidDate != "" &&
              data.bidType != "" &&
              data.bidValue != "" &&
              data.dealersInvited.length > 0
            ) {
              try {
                scrapsID.map((obj) =>
                  addNewBID(obj.id, data).then(() => {
                    update(
                      COLLECTIONS.SCRAP_BY_GENERATOR,
                      { status: StatusEnum.AWAITING_BID },
                      obj.id
                    );
                    setAlert({
                      text: t("alert-successfully-registered"),
                      type: "success",
                    });
                  })
                );
              } catch {
                setAlert({
                  text: `${t("alert-error-registered")}:`,
                  type: "error",
                });
              }
              setTimeout(() => {
                onClose();
              }, 2000);
            } else {
              setAlert({ text: `${t("fill-all-fields")}`, type: "error" });
            }
          }}
        >
          {t("label-save")}
        </button>
      </div>
    </div>
  );
};

export default ModalMultBid;
