import React from "react";
import { t } from "i18next";
import { useAlert } from "../../../../../../contexts/alert-context";
import {
  getAdrressByEntityId,
  getCountryConfigById,
} from "../../../../../../services/address-service";
import {
  sendDealerEmail,
  updateIdsDealersInvited,
} from "../../../../scrap-admin-service";

interface ModalProps {
  onClose: () => void;
  multIDS: any[];
  id: string | any[];
  materialID?: string;
  sendedIdsHandler?: (arg: string[]) => void;
  formData?: any;
}

const ScrapDealersSendMailModal: React.FC<ModalProps> = ({
  onClose,
  multIDS,
  id,
  materialID,
  sendedIdsHandler,
  formData,
}) => {
  const { setAlert } = useAlert();

  const handlerSend = async () => {
    try {
      const addresses = await Promise.all(
        multIDS.map((item) => getAdrressByEntityId(item.id))
      );

      const countryConfigs = await Promise.all(
        addresses.map((data) => getCountryConfigById(data.country))
      );
      onClose();
      setAlert({
        text: t("label-invite-sended"),
        type: "success",
      });
      updateIdsDealersInvited(multIDS, id as string);

      for (let index = 0; index < multIDS.length; index++) {
        const item = multIDS[index];
        const countryConfig = countryConfigs[index];
        const language = countryConfig.locale.startsWith("pt") ? "pt" : "en";

        await sendDealerEmail(id as string, item.id, language);
      }

      onClose();
    } catch (error) {
      setAlert({
        text: t("label-invite-error"),
        type: "error",
      });

      console.error("Error sending invites:", error);
    }
  };

  return (
    <div className="modal-content" style={{ padding: "2rem" }}>
      <div className="flex-column margin-topbottom-1">
        <label>{t("label-send-mail-modal")}</label>
        {multIDS.map((obj) => (
          <input
            key={obj.id}
            style={{ marginTop: "1rem" }}
            type="text"
            disabled
            name="dealerName"
            value={obj.name}
          />
        ))}
      </div>
      <div className="flex-row buttons-section">
        <button
          className="neutral-white"
          onClick={onClose}
          type="button"
          style={{ margin: "1rem" }}
        >
          {t("label-back")}
        </button>
        <button
          style={{ margin: "1rem" }}
          className="blue"
          type="submit"
          onClick={handlerSend}
        >
          {t("label-send")}
        </button>
      </div>
    </div>
  );
};

export default ScrapDealersSendMailModal;
