import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { t } from "i18next";
import { CollectPeriod } from "../../../../assets/enums/collects-enum";

import ScrapMaterialInputAutocomplete from "../../../../components/ScrapMaterialInputAutocomplete/ScrapMaterialInputAutocomplete";
import { CollectItemDTO } from "../../models/collect-item-DTO";
import { useAlert } from "../../../../contexts/alert-context";
import {  addNewScheduled,  } from "../../collects-service";
import { getScrapAdminContract } from "../../../scraps/scrap-admin-service";
import { ScrapInfoDTO } from "../../../scraps/scrap-admin-generator-view/components/scrap-form/models/scrap-DTO";
import {
  getAdrressByEntityId,
  getCountryConfigById,
} from "../../../../services/address-service";
import { getScrapDealerById } from "../../../scrap-dealers/scrap-dealers-service";
import { useAuth } from "../../../../contexts/auth-context";
import { useParams } from "react-router-dom";
import genericImagePreview from "./../../../../assets/svgs/material-list.svg";
import { CollectStatus } from "../../../../assets/enums/collects-enum";
import CollapsibleSection from "../../../../components/ColapseSection/CollapsibleSection";
import { transformDateFormat } from "../../../../services/date-format";
import { CountryConfigDTO } from "../../../settings/components/Country-config/country-config-DTO";



interface NewCollectModalScheduledProps {
  originEntity: string;
  item?: CollectItemDTO;
  profile: string;
  onSave: (item: CollectItemDTO) => void;
  onClose: () => void;
}

const NewCollectModalScheduled: React.FC<NewCollectModalScheduledProps> = ({
  item,
  onSave,
  onClose,
  profile,
  originEntity,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<CollectItemDTO>({
    defaultValues: item as CollectItemDTO,
  });
  const { userDoc } = useAuth();
  const originId = userDoc?.originId;
  const { id } = useParams<{ id: string }>();
  const [autocompleteError, setAutocompleteError] = useState(false);
    useState(false);
  const watchedInvoiceAttachment = watch("invoiceAttachment");
  const watchedAttachmentMTR = watch("attachmentMTR");
  const [scrapMaterialPreview, setScrapMaterialPreview] = useState<any>();
  const [scrapMaterialContract, setScrapMaterialContract] = useState<any>();
  const [scrapDealerDetails, setScrapDealerDetails] = useState<any>();
  const [submitType, setSubmitType] = useState<string | null>(null);


  const [countryConfigEntity, setCountryConfigEntity] =
    useState<CountryConfigDTO>();

  const language: string =
    countryConfigEntity?.locale.split("-")[0] == "pt" ? "pt" : "en";

  const { setAlert } = useAlert();

  const fetchEntityCountry = async () => {
    try {
      const entityId = id || originId;
      if (entityId) {
        const data = await getAdrressByEntityId(entityId);
        if (data?.country) {
          const countryConfig = await getCountryConfigById(data.country);
          setCountryConfigEntity(countryConfig);
        }
      }
    } catch (error) {
      console.error("Failed to fetch country configuration:", error);
    }
  };

  
  useEffect(() => {
    fetchEntityCountry();
  }, []);

  useEffect(() => {
    if (item) {
      setValue("status", item.status);
       
    }
  }, [item]);

  useEffect(() => {
    if (item) {
      setValue("period", item.period);
      
    }
  }, [item]);

  useEffect(() => {
    if (item) {
      setValue("date", item.date);
      
    }   
  }, [item]);

 

 

  const handleSetScrapMaterial = async (scrapMaterial: ScrapInfoDTO) => {
    setValue("scrapMaterial", scrapMaterial);
    setScrapMaterialPreview(scrapMaterial);
    setAutocompleteError(false);

    const contractDetails = await getScrapAdminContract(scrapMaterial.id);
    setScrapMaterialContract(contractDetails);

    const scrapDealerDetails = await getScrapDealerById(
      scrapMaterial.contractDealer
    );
    setScrapDealerDetails(scrapDealerDetails);
  };

  const getImageSrc = (scrapMaterialPreview: any) => {
    if (
      scrapMaterialPreview?.images &&
      scrapMaterialPreview.images.length > 0
    ) {
      return scrapMaterialPreview.images[0];
    } else if (scrapMaterialPreview?.scrapCategory?.image) {
      return scrapMaterialPreview?.scrapCategory?.image;
    } else {
      return genericImagePreview;
    }
  };
    
  const onSubmit = async (data: CollectItemDTO) => {
 
    if (!data.scrapMaterial) {
      setAutocompleteError(true);
    } else {
      data.unity = scrapMaterialPreview.unity;
      data.valueByUnity = scrapMaterialContract?.contractValue;
      data.generatorId = data.scrapMaterial.originId;
      data.contractedDealerId = data.scrapMaterial.contractDealer;
      data.scrapMaterialName = data.scrapMaterial.scrapCategory.name;
      data.scrapGeneratorName = data.scrapMaterial.originName;
      data.isHazardousMaterial = data.scrapMaterial.scrapCategory.isHazardousMaterial
      data.scrapDealerName = data.scrapMaterial.contractDealerName;
      data.scrapMaterial = data.scrapMaterial.id;
      data.status = CollectStatus.SCHEDULED;
      if (countryConfigEntity) {
        data.currency = countryConfigEntity.currency;
        data.locale = countryConfigEntity.locale;
      }
      if(data.observations == undefined){
        data.observations = ""
      }
          
      await addNewScheduled(data, language).then(() => {
        setAlert({
          text: t("alert-successfully-registered"),
          type: "success",
        });

        onClose();
        onSave(data);
      });
      
      
    }
  };

 

  return (
    <div
      className="big-modal-content"
      style={{ gap: "20px", padding: "1rem 1rem 2rem 1rem" }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-fields">
          <CollapsibleSection title={t("label-collect")} titleFontSize="18px">
            <div className="flex-column">
              <ScrapMaterialInputAutocomplete
                originEntity={originEntity}
                initialValue={item?.scrapMaterial}
                isDisabled={item ? true : false}
                label={`${t("label-material")}*`}
                onChange={(scrapMaterial) =>
                  handleSetScrapMaterial(scrapMaterial)
                }
                placeholder={t("label-material")}
              />
              <div className="flex-row justify-between">
                {autocompleteError && <span>{t("error-input-required")}</span>}
                <div></div>
                <p
                  style={{
                    color: "var(--extra-grey-medium)",
                    fontSize: 12,
                  }}
                >
                  {t("label-material-contracted")}
                </p>
              </div>
            </div>

            {scrapMaterialPreview && (
              <div
                className="resume-material-collect flex-row margin-topbottom-1"
                style={{ gap: 10 }}
              >
                <div className="flex-column">
                  <img
                    style={{
                      width: "100px",
                      height: "100px",
                      objectFit: "cover",
                      borderRadius: 5,
                    }}
                    src={getImageSrc(scrapMaterialPreview)}
                  />
                </div>
                <div
                  className="flex-column justify-between"
                  style={{ width: "100%" }}
                >
                  <div className="flex-column" style={{ gap: 5 }}>
                    <div className="justify-between flex-row">
                      <h3 style={{ margin: 0, marginBottom: 8 }}>
                        {scrapMaterialPreview.scrapCategory.name}
                      </h3>
                      <a
                        href={
                          profile === "admin-bosch"
                            ? `/scrap-generators-details/${scrapMaterialPreview.originId}/scraps-form/${scrapMaterialPreview.id}`
                            : `/generator-scraps/${scrapMaterialPreview.originId}/generator-scraps-form/${scrapMaterialPreview.id}`
                        }
                        className="a-link-style"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t("label-details")}
                      </a>
                    </div>
                  </div>
                  <div className="flex-column" style={{ gap: 2 }}>
                    <h5 style={{ margin: 0 }}>
                      {t("material-contract-date")}:
                    </h5>
                    <p>{`${transformDateFormat(
                      scrapMaterialContract?.contractDate
                    )}  ${t("label-to").toLowerCase()}  ${transformDateFormat(
                      scrapMaterialContract?.expirationDocument.expirationDate
                    )}`}</p>
                  </div>
                  <div className="flex-row justify-between align-end">
                    <div className="flex-column" style={{ gap: 2 }}>
                      <h5 style={{ margin: 0 }}>
                        {t("label-responsible-shipping")}:
                      </h5>
                      <p>
                        {t(`label-${scrapMaterialPreview.responsibleShipping}`)}
                      </p>
                    </div>
                    <div className="flex-column align-end">
                      <div className="flex-row" style={{ gap: 5 }}>
                        <h3 style={{ margin: 0 }}>
                          {scrapMaterialContract?.contractValue || "xx"}
                        </h3>
                        <p>{`/${t(
                          `unityENUM.${scrapMaterialPreview.unity}`
                        )}`}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <br/>

            <div className="flex-column">
              <label>{`${t("label-date")}*`}</label>
              <input
                  type="date"
                  id="date"
                  {...register("date", {
                    required: true,
                  })}
                />
            </div>

           <br/>

           
            <div className="flex-column">
              <label>{t("label-period")}</label>
              <select {...register("period")}>
                {Object.values(CollectPeriod).map((period) => (
                  <option key={period} value={period}>
                    {t(`collect-periodENUM.${period}`)}
                  </option>
                ))}
              </select>
            </div>

            <br/>
            <hr></hr>
            <br/>
              <div className="flex-column">
                <label htmlFor="label-observations">
                  {t("label-observations")}
                </label>
                <input
                  disabled={!scrapMaterialPreview}
                  placeholder={t("label-observations")}
                  type="text"
                  id="label-observations"
                  {...register("observations", {
                    required: false,
                  })}
                />
              </div>
          </CollapsibleSection>

          <div
            className="flex-row justify-end"
            style={{ gap: "10px", marginTop: "1.5rem" }}
          >
            <button onClick={onClose} style={{ margin: 0 }}>
              {t("label-cancel")}
            </button>
            <button  className="blue" type="submit" style={{ margin: 0, width:"35%" }}>
              {t("label-save-scheduled")}
              
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default NewCollectModalScheduled;
