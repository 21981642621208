import React from "react";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../../../../contexts/auth-context";
import { t } from "i18next";
import {  getAdrressByEntityId, getCountryConfigById } from "../../../../../../services/address-service";
import { COLLECTIONS } from "../../../../../../assets/enums/firebase-colections";
import { SparkTable, SparkTableHead, SparkTableRow, SparkTableCell, SparkTableBody } from "@bosch-web-dds/spark-ui-react";
import { getAllApprovers, addNewApprover } from "./scrap-generator-approve-roll-service";
import { ApproveRollDTO } from "./scrap-generator-approve-rollDTO";
import AddNewApproverModal from "./components/AddNewApproverModal";
import { CurrencyInput } from "react-currency-mask";
import FilterComponent from "../../../../../../components/FIlter/filter";
import addIcon from "../../../../../../assets/svgs/add.svg"
import { CountryConfigDTO } from "../../../../../settings/components/Country-config/country-config-DTO";

const ScrapGeneratorApproveRoll: React.FC<{ enabledUsers: string[] }> = ({ enabledUsers }) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { userDoc } = useAuth();
    const [countryConfigEntity, setCountryConfigEntity] = useState<CountryConfigDTO>()
    const [formData, setFormData] = useState<ApproveRollDTO>()
    const profile = userDoc?.profile;
    const [enableAddModal, setEnableAddModal] = useState(false)
    const [filteredData, setFilteredData] = useState<
        ApproveRollDTO[]
    >([]);
    const [data, setData] = useState<
        ApproveRollDTO[]
    >([]);
    const [edit, setEdit] = useState<any>()
    const tableHeader = [
        `${t("label-name")}`,
        `${t("material-quantity-unity")}`,
        `${t("label-value")}`,
    ];

    async function getData() {
        const data = await getAllApprovers(id)
        setData(data)
        setFilteredData(data)
    }

    const fetchCountryConfig = async () => {
        if (id) {
            const addressData = await getAdrressByEntityId(id);
            if (addressData) {
                const countryConfigData = await getCountryConfigById(addressData.country);
                if (countryConfigData) {
                    setCountryConfigEntity(countryConfigData);
                }
            }
        }
    };

    useEffect(() => {
        fetchCountryConfig();
        getData()
    }, [id, enableAddModal]);

    if (enabledUsers.includes(profile || "public")) {
        return (
            <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                {
                    enableAddModal && userDoc && countryConfigEntity && id && <div className="modal-background">
                        <AddNewApproverModal
                            edit={edit}
                            
                            clearEdit={() => setEdit(null)}
                            filteredData={filteredData}
                            id={id}
                            countryConfigEntity={countryConfigEntity}
                            onClose={() => setEnableAddModal(false)}
                        ></AddNewApproverModal>
                    </div>
                }

                <div style={{ width: "100%", display: "flex", justifyContent: "flex-between", marginBottom: "1rem" }}>
                    <button
                        className="blue-with-icon button"
                        onClick={() => setEnableAddModal(true)}
                    >
                        <img src={addIcon} alt="add icon" />
                        <p>{t("label-add-new")}</p>
                    </button>
                    <FilterComponent
                        data={data.map((obj) => ({ ...obj, searchData: obj.user.name }))}
                        onFilterClick={(data) => setFilteredData(data)}
                    />
                </div>

                {
                    filteredData && data && <div className="info-section">
                        <SparkTable>
                            <SparkTableHead>
                                <SparkTableRow>
                                    {tableHeader.map((header, index) => (
                                        <SparkTableCell key={index}>
                                            <strong>{header.toUpperCase()}</strong>
                                        </SparkTableCell>
                                    ))}
                                </SparkTableRow>
                            </SparkTableHead>

                            <SparkTableBody>
                                {filteredData.map((rowItem: any, rowIndex: any) => (
                                    <SparkTableRow key={rowIndex} onClick={() => { setEdit(rowItem); setEnableAddModal(true) }}>
                                        <SparkTableCell>
                                            {rowItem.user.name}
                                        </SparkTableCell>
                                        <SparkTableCell>
                                            {rowItem.currency.currency}
                                        </SparkTableCell>

                                        <SparkTableCell>
                                            <div className="reset-currency-input-style" onClick={() => { }}>
                                                <CurrencyInput
                                                    defaultValue={rowItem.approveValue}
                                                    locale={rowItem.locale}
                                                    currency={rowItem.currency.currency}
                                                    onChangeValue={(event, originalValue, maskedValue) => { }}
                                                    value={rowItem.approveValue}
                                                ></CurrencyInput>
                                            </div>
                                        </SparkTableCell>
                                    </SparkTableRow>
                                ))}
                            </SparkTableBody>
                        </SparkTable>
                    </div>
                }
            </div>
        )
    } else {
        navigate("/page-not-found")
        return <></>
    }
}

export default ScrapGeneratorApproveRoll;


