import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";

import {
  CreditAnalysisInfoDTO,
  CreditAnalysisDTO,
  ObservationsDTO,
  CreditDTO,
} from "./credit-analysis-DTO";
import { firestore } from "../../../../utils/firebase-config";
import { COLLECTIONS } from "../../../../assets/enums/firebase-colections";
import {
  getExpirationDocument,
  setExpirationDocument,
} from "../../../../components/Expiration/ExpirationService";
import {
  saveNewAsync,
  saveNewWithId,
  update,
} from "../../../../services/persistence";
import { ScrapDealerInfoDTO } from "../../scrap-dealer-DTO";

// add new
export async function addNew(data: CreditAnalysisDTO, id: string) {
  if (data.creditAnalysisInfoDTO.expirationDocument != null) {
    data.creditAnalysisInfoDTO.expirationDocument.originId = id;
    const originName = await getOriginName(id);
    data.creditAnalysisInfoDTO.expirationDocument.origin = originName;
  }
  if (data.id == undefined) {
    data.creditAnalysisInfoDTO.originId = id;
    saveNewAsync(COLLECTIONS.CREDIT_ANALYSIS, data.creditAnalysisInfoDTO).then(
      (docRef) => {
        saveNewWithId(COLLECTIONS.OBSERVATIONS, data.observations, docRef.id);
        if (data.creditAnalysisInfoDTO.expirationDocument != null) {
          setExpirationDocument(
            data.creditAnalysisInfoDTO.expirationDocument,
            docRef.id
          );
        }
      }
    );
  } else {
    update(COLLECTIONS.CREDIT_ANALYSIS, data.creditAnalysisInfoDTO, data.id);
    update(COLLECTIONS.OBSERVATIONS, data.observations, data.id);
    setExpirationDocument(
      data.creditAnalysisInfoDTO.expirationDocument,
      data.id
    );
  }
}

async function getOriginName(id: string): Promise<string> {
  const docRef = doc(firestore, COLLECTIONS.SCRAP_DEALERS, id);
  const docSnap = await getDoc(docRef);

  const dealer = docSnap.data() as ScrapDealerInfoDTO;
  return dealer.tradingName;
}

export async function getCreditAnalysisByOriginId(
  id: string
): Promise<CreditAnalysisInfoDTO[]> {
  const collectionRef = collection(firestore, COLLECTIONS.CREDIT_ANALYSIS);

  const q = query(collectionRef, where("originId", "==", id));

  const querySnapshot = await getDocs(q);

  const items = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  })) as unknown as CreditAnalysisInfoDTO[];
  return items;
}

export const getById = async (
  id: string
): Promise<CreditAnalysisDTO | null> => {
  const creditAnalysisInfoDTO = await getInfoById(id);
  const observations = await getObservation(id);

  return {
    id,
    creditAnalysisInfoDTO,
    observations,
  };
};

export async function getInfoById(id: string): Promise<CreditAnalysisInfoDTO> {
  const docRef = doc(firestore, COLLECTIONS.CREDIT_ANALYSIS, id);
  const docSnap = await getDoc(docRef);

  return docSnap.data() as CreditAnalysisInfoDTO;
}

export async function getObservation(id: string): Promise<ObservationsDTO> {
  const docRef = doc(firestore, COLLECTIONS.OBSERVATIONS, id);
  const docSnap = await getDoc(docRef);

  return docSnap.data() as ObservationsDTO;
}

export async function getCreditValue(id: string): Promise<CreditDTO> {
  const docRef = doc(firestore, COLLECTIONS.IN_BILLING_CREDIT, id);
  const docSnap = await getDoc(docRef);

  return docSnap.data() as CreditDTO;
}
