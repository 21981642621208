import { collection, getDocs, onSnapshot, query, QuerySnapshot, where } from "firebase/firestore";
import { COLLECTIONS } from "../../assets/enums/firebase-colections";
import {
  QueryOptions,
  saveFiles,
  saveNewAsync,
  update,
} from "../../services/persistence";

import { firestore } from "../../utils/firebase-config";
import { BankDTO } from "./bankDTO";


export async function getBanks(country: string): Promise<BankDTO[]> {
  
    const bankCollectionRef = collection(firestore, COLLECTIONS.BANKS);
   
    const q = query(
  
      bankCollectionRef,
  
      where("country", "array-contains", "Brasil")
  
    );
    
    const querySnapshot = await getDocs(q);

    
  const items = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  })) as unknown as BankDTO[];
  return items;
}