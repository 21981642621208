export const unityENUM = {
  KILO: "KILO",
  CUBIC_METER: "CUBIC_METER",
  LITER: "LITER",
  TON: "TON",
  UNIT: "UNIT",
  Quilograma: "KILO",
  M3: "CUBIC_METER",
  Litro: "LITER",
  Tonelada: "TON",
  Unidade: "UNIT"
};
