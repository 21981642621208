import React, { useEffect, useState } from "react";
import Select, { components, MultiValue, ActionMeta } from "react-select";
import { useTranslation } from "react-i18next";
import { getScrapDealersValidated } from "../../pages/scrap-dealers/scrap-dealers-service";
import { useAuth } from "../../contexts/auth-context";

const ScrapDealerInputAutocomplete: React.FC<{
  placeholder: string;
  onChange: (scrapDealers: any[]) => void;
  isDisabled?: boolean;
  defaultValue?: any[];
  isSingleSelect?: boolean;
}> = ({ placeholder, onChange, isDisabled, defaultValue, isSingleSelect }) => {
  const [selectedScrapDealers, setSelectedScrapDealers] = useState<any[]>([]);
  const [scrapDealerOptions, setScrapDealerOptions] = useState<any[]>([]);
  const { userDoc } = useAuth();
  const profile = userDoc?.profile;
  const originId = userDoc?.originId;

  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const scrapDealers = await getScrapDealersValidated();
        const options = scrapDealers.map((scrapDealer) => ({
          value: scrapDealer.id,
          label: scrapDealer.legalName,
          data: scrapDealer,
        }));
        setScrapDealerOptions(options);

        if (defaultValue) {
          const defaultOptions = defaultValue?.map((dealer) => ({
            value: dealer.id,
            label: dealer.legalName,
            data: dealer,
          }));
          setSelectedScrapDealers(defaultOptions);
        }
      } catch (error) {
        console.error("Error fetching scrap dealers:", error);
      }
    };
    fetchData();
  }, [defaultValue]);

  const handleChange = (
    selectedOptions: MultiValue<{ value: any; label: any; data: any }> | { value: any; label: any; data: any }
  ) => {
    const mutableSelectedOptions = Array.isArray(selectedOptions)
      ? [...selectedOptions]
      : selectedOptions
      ? [selectedOptions]
      : [];

    const selectedData = mutableSelectedOptions.map((option) => option.data);

    setSelectedScrapDealers(mutableSelectedOptions);
    onChange(selectedData);
  };

  const customOption = (props: any) => (
    <components.Option {...props}>
      <div className="flex-row" style={{ alignItems: "center" }}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </div>
    </components.Option>
  );

  return (
    <div className="flex-column select-field">
      <Select
        id="scrapDealer"
        placeholder={placeholder}
        value={selectedScrapDealers}
        onChange={handleChange}
        options={scrapDealerOptions}
        isDisabled={isDisabled}
        isMulti={!isSingleSelect}
        closeMenuOnSelect={isSingleSelect ? true : false}
        hideSelectedOptions={false}
        components={isSingleSelect ? undefined : { Option: customOption }}
      />
    </div>
  );
};

export default ScrapDealerInputAutocomplete;
