import { useTranslation } from "react-i18next";
import CardListScrapDealer from "../cardlist-scrap-dealer/cardlist-scrap-dealer";
import { useNavigate, useParams } from "react-router-dom";
import addIcon from "../../../../assets/svgs/add.svg";
import { useEffect, useState } from "react";
import { ScrapDealerInfoDTO } from "../../scrap-dealer-DTO";
import { getBranchsScrapDealersById } from "../../scrap-dealers-service";
import ImportExportCsv from "../../../../components/ImportExportCsv/ImportExportCsv";
import { useAuth } from "../../../../contexts/auth-context";

const ScrapDealerDetailsBranchs: React.FC<{ enabledUsers: string[] }> = ({ enabledUsers }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userDoc } = useAuth();
  const profile = userDoc?.profile;
  const { id } = useParams<{ id: string }>();
  const data = { headquarterId: id };
  const [scrapDealerBranchs, setScrapDealerBranchs] = useState<ScrapDealerInfoDTO[]>([]);

  const fetchScrapDealerBranchs = async () => {
    try {
      let data: any;
      data = await getBranchsScrapDealersById(id as string);
      setScrapDealerBranchs(data);
    } catch (error) {
      console.error("Error fetching scrap dealers: ", error);
    }
  };

  useEffect(() => {
    fetchScrapDealerBranchs();
  }, []);

  const handleCloseModal = () => {
    fetchScrapDealerBranchs();
  };

  if (enabledUsers.includes(profile || "public")) {
    return (
      <div>
        <div className="flex-row">
          <button
            className="blue-with-icon button"
            onClick={() => {
              navigate(`/scrap-dealers-new/${id}`);
            }}
          >
            <img src={addIcon} alt="add icon" />
            <p>{t("label-add-new")}</p>
          </button>
          <ImportExportCsv
            exportApi="exportscrapdealerscsv"
            importApi="importscrapdealerscsv"
            data={data}
            onClose={handleCloseModal}
          />
        </div>
        <br />
        <CardListScrapDealer scrapDealers={scrapDealerBranchs} />
      </div>
    );
  } else {
    navigate("/page-not-found")
    return <></>
  }
};

export default ScrapDealerDetailsBranchs;
