import CardScrapCategory from "./card-scrap-groups/card-scrap-group";
import "../../scrap.css";
import NoData from "../../../../components/NoData/NoData";

import CardScrapGroup from "./card-scrap-groups/card-scrap-group";
import { ScrapsCategoryGroupDTO } from "../../scrap-category/models/scrap-category-DTO";

interface cardlistProps {
  scrapCategories: ScrapsCategoryGroupDTO[]
}

const CardListScrapGroups = ({scrapCategories}:cardlistProps) => {

  return (
    <div className="card-list">
      <br></br>
      {scrapCategories.map((item, index) => (
        <CardScrapGroup key={index} item={item} />
      ))}
      {scrapCategories.length === 0 && <NoData />}
    </div>
  );
};

export default CardListScrapGroups;
