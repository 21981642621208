import { useTranslation, Trans } from "react-i18next";
import iconCash from "../../../../assets/img/cash.svg";
import iconProjects from "../../../../assets/img/projects.svg";
import industry from "../../../../assets/img/industry.svg";

const CardDetals: any = ({}) => {
  const { t } = useTranslation();

  return (
    <div className="flex">
      <div className="container-highlights">
        <div className="container-industry">
          <div className="cash">
            <img src={industry} alt="icon-cash" width={60} height={60} />
            <span className="text-tons color-white">
              {t("landing-page.value-industry")}
            </span>
          </div>

          <div className="detals-one">
            <span className="text-percentage-hundred-detals color-white">
              {t("landing-page.plant-industry")}
            </span>
            <span className="text-percentage-hundred-detals color-white">
              {t("landing-page.plant-industrys")}
            </span>
          </div>
        </div>
        <div className="container-tons">
          <span className="text-tons color-black">
            {t("landing-page.percentage-one")}
          </span>
          <span className="text-percentage-hundred-detals color-black">
            {t("landing-page.percentage-one-description")}
          </span>
          <span className="text-tons-description">
            {t("landing-page.percentage-one-descriptions")}
          </span>
        </div>
        <div className="container-cash">
          <div className="cash">
            <img src={iconCash} alt="icon-cash" width={60} height={60} />
            <span className="text-tons color-white">
              {t("landing-page.value-ten")}
            </span>
          </div>
          <span className="text-percentage-hundred-detals color-white">
            {t("landing-page.invoicing")}
          </span>
        </div>
        <div className="container-industry">
          <span className="text-tons color-white">
            {t("landing-page.percentage-hundred")}
          </span>
          <span className="text-percentage-hundred-detals color-white">
            {t("landing-page.compliace")}
          </span>
        </div>

        <div className="container-tons">
          <span className="text-tons color-black">
            {t("landing-page.percentage-two")}
          </span>
          <span className="text-percentage-hundred-detals color-black">
            {t("landing-page.percentage-two-description")}
          </span>
          <span className="text-tons-description">
            {t("landing-page.percentage-two-descriptions")}
          </span>
        </div>

        <div className="container-cash">
          <div className="cash">
            <img src={iconProjects} alt="icon-cash" width={60} height={60} />
            <span className="text-tons color-white">
              {t("landing-page.value-projects")}
            </span>
          </div>
          <span className="text-percentage-hundred-detals color-white">
            {t("landing-page.projects")}
          </span>
          <span className="text-tons-description color-white">
            {t("landing-page.projects-description")}
          </span>
        </div>
      </div>
      <span>{t("landing-page.calculation-percentage")}</span>
    </div>
  );
};

export default CardDetals;
