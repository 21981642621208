import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAlert } from "../../../contexts/alert-context";
import downloadIconWhite from "../../../assets/svgs/download_white.svg";
import { callFunction, uploadCsvFile } from "../../../services/persistence";

interface ModalProps {
  onClose: () => void;
  apiData: string;
  templateUrl: string;
  data?: any;
  passFileToParent?: boolean;
  onFileSelect?: (file: File) => void;
}

const UploadCsvModal: React.FC<ModalProps> = ({
  onClose,
  apiData,
  templateUrl,
  data,
  passFileToParent = false,
  onFileSelect,
}) => {
  type FileState = File | null;

  const { t } = useTranslation();
  const { setAlert } = useAlert();
  const [file, setFile] = useState<FileState>(null);

  const handleSubmit = async () => {
    if (!file) {
      setAlert({
        text: t("label-no-file"),
        type: "error",
      });
      return;
    }

    if (passFileToParent && onFileSelect) {
      onFileSelect(file);
      onClose();
      return;
    }

    try {
      uploadCsvFile(file, apiData, data, callFunction).then(() => {
        setAlert({
          text: t("upload-success"),
          type: "success",
        });
        onClose();
      });
    } catch (error: any) {
      setAlert({
        text: t("upload-error"),
        type: "error",
      });
      console.error(error.message);
    }
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <p className="forget-password-title">{t("label-upload-csv")}</p>
        <p className="info-text">{t("label-upload-csv-information")}</p>

        <input
          type="file"
          id="fileInput"
          accept=".csv"
          title={t("label-upload-csv")}
          onChange={(e) => e.target.files && setFile(e.target.files[0])}
        />

        <button
          className="primary"
          type="button"
          onClick={handleSubmit}
          title={t("label-send")}
        >
          {t("label-send")}
        </button>
        <a className="blue-with-icon button" href={templateUrl}>
          <button
            type="button"
            className="blue-with-icon button"
            title={t("label-download-csv-example")}
          >
            <img src={downloadIconWhite} alt="add icon" />
            <p>{t("label-download-csv-example")}</p>
          </button>
        </a>
      </div>
    </div>
  );
};

export default UploadCsvModal;
