import { collection, getDocs, query, where } from "firebase/firestore";
import { COLLECTIONS } from "../../../../assets/enums/firebase-colections";
import {
  QueryOptions,
  saveFiles,

  update,
} from "../../../../services/persistence";

import { firestore } from "../../../../utils/firebase-config";
import { CADRItemDTO } from "../../models/cadri-item-DTO";

export async function addNewFsdr(
  data: CADRItemDTO,
  NFFiles: File[],
  
) {

  if (data.id !== undefined) {
    update(COLLECTIONS.CADRI, data, data.id);
    saveFilesfsdr(NFFiles, data.id);
  } 
  
}

function saveFilesfsdr(NFFiles: File[], id: string) {
  if (NFFiles && NFFiles.length > 0) {
    saveFiles(
      COLLECTIONS.CADRI,
      id,
      "fsdr",
      NFFiles,
      "attachmentFSDR"
    );
  }
}

export async function getAllfsdr(
  options?: QueryOptions
): Promise<CADRItemDTO[]> {
  const fsdrCollection = collection(firestore, COLLECTIONS.CADRI);

  let fsdrQuery;
  if (options) {
    const { fieldName, operator, value } = options;
    fsdrQuery = query(
        fsdrCollection,
      where(fieldName, operator, value)
    );
  } else {
    fsdrQuery = fsdrCollection;
  }

  const fsdrSnapshot = await getDocs(fsdrQuery);
  const fsdrList = fsdrSnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
    searchData: doc
      .data()
      ["scrapMaterialName"].concat(
        " ",
        doc.data()["scrapGeneratorName"],
        " ",
        doc.data()["scrapDealerName"]
      ),
  })) as unknown as CADRItemDTO[];

  return fsdrList;
}

