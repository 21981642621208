import React, { useEffect, useState } from "react";
import Select, { StylesConfig } from "react-select";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../utils/firebase-config";
import {
  getMTRCategoriesApproved,
  getScrapGroups,
} from "../../pages/scraps/scraps-service";
import {
  MTRCategoryDTO,
  ScrapsCategoryGroupDTO,
} from "../../pages/scraps/scrap-category/models/scrap-category-DTO";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import ScrapGroupForm from "../../pages/scraps/scrap-group/scrap-group-form/scrap-group-form";

const ScrapGroupAutocomplete: React.FC<{
  label: string;
  placeholder: string;
  onChange: (category: any) => void;
  initialValue?: any;
  callHandlerFirstValue?: boolean;
  isDisabled?: boolean;
}> = ({
  label,
  placeholder,
  onChange,
  callHandlerFirstValue,
  initialValue,
  isDisabled,
}) => {
  const [categoryOptions, setCategoryOptions] = useState<any[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<any>(
    initialValue || null
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState("");
  const [userId, setUserId] = useState<string>();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(user.uid);
      } else {
        setUserId("");
      }
    });
    return () => unsubscribe();
  }, []);

  const handleChangeRef = React.useRef(onChange);
  handleChangeRef.current = onChange;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const mtrCategories = await getScrapGroups();

        const options = mtrCategories.map(
          (scrapGroup: ScrapsCategoryGroupDTO) => ({
            value: scrapGroup.id,
            label: scrapGroup.scrapGroup,
            data: scrapGroup,
          })
        );

        setCategoryOptions(options);

        if (initialValue) {
          const initialCategory = options.find(
            (option) => option.label === initialValue.scrapGroup
          );
          if (initialCategory) {
            if (callHandlerFirstValue) {
              onChange(initialCategory.data);
            }
            setSelectedCategory(initialCategory.data);
            handleChangeRef.current(initialCategory.data);
          }
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchData();
  }, [initialValue, userId]);

  const handleChange = (selectedOption: any) => {
    if (selectedOption && selectedOption.value === "add-new") {
      setIsModalOpen(true);
    } else if (selectedOption && selectedOption.data) {
      setSelectedCategory(selectedOption.data);
      handleChangeRef.current(selectedOption.data);
    } else {
      setSelectedCategory(null);
      handleChangeRef.current(null);
    }
  };

  const handleInputChange = (newValue: string) => {
    setInputValue(newValue);
  };

  const customFilterOptions = (candidate: any, input: string) => {
    return candidate?.label?.toLowerCase().includes(input?.toLowerCase());
  };

  const customStyles: StylesConfig<any, false> = {
    option: (provided, state) => ({
      ...provided,
      ...(state.data.value === "add-new" && {
        textAlign: "initial",
        color: "#007bc0",
        fontWeight: "regular",
        fontSize:"14px"
      }),
    }),
  };

  return (
    <div className="">
      {isModalOpen && (
        <div className="modal-background">
          <ScrapGroupForm contactId="" onClose={()=> setIsModalOpen(false)} />
        </div>
      )}
      <div className="">
        <label htmlFor="category">{label}</label>
      </div>
      <Select
        id="category"
        placeholder={placeholder}
        value={
          selectedCategory
            ? { value: selectedCategory.id, label: selectedCategory.scrapGroup }
            : null
        }
        onChange={handleChange}
        onInputChange={(newValue, { action }) => {
          if (action === "input-change") {
            handleInputChange(newValue);
          }
        }}
        options={[
          ...categoryOptions,
          { value: "add-new", label: t("label-didnt-find") },
        ]}
        filterOption={customFilterOptions}
        isDisabled={isDisabled}
        styles={customStyles}
      />
    </div>
  );
};

export default ScrapGroupAutocomplete;
