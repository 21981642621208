
export const CollectStatus = {
  SCHEDULED: "SCHEDULED",
  WAITING_MTR: "WAITING_MTR",
  MTR_GENERATED: "MTR_GENERATED",
  FINISHED: "FINISHED",
  CANCELLED: "CANCELLED",
};

export const FinancialStatus = {
  TO_COLLECT: "TO_COLLECT",
  TO_RECEIVE: "TO_RECEIVE",
  RECEIVED: "RECEIVED",
};

export const CollectPeriod = {
  MORNING: "MORNING",
  AFTERNOON: "AFTERNOON",
  NIGHT: "NIGHT",
};