import { collection, getDocs, query, where } from "firebase/firestore";
import { firestore } from "../../utils/firebase-config";
import { COLLECTIONS } from "../../assets/enums/firebase-colections";


export async function getFinancial(profile: string, originId: string
): Promise<any> {
  let q ;
    if (profile == "admin-bosch"){
        q = query(
        collection(firestore, COLLECTIONS.FINANCIAL),
      );
    }
    else{
        q = query(
        collection(firestore, COLLECTIONS.FINANCIAL),
        where("generatorId", "==", originId)
      );
    }

    const docSnap = await getDocs(q);

const itens= docSnap.docs.map((doc) => ({ id: doc.id, ...doc.data(),}))
  return itens as any;
}
