import { useTranslation } from "react-i18next";
import addIcon from "../../assets/svgs/add.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import ContactForm from "./contact-form/contact-form";
import { getContactsById } from "./contacts-service";
import { ContactInfoDTO } from "./contacts-DTO";
import ImportExportCsv from "../../components/ImportExportCsv/ImportExportCsv";
import { useAuth } from "../../contexts/auth-context";
import FilterComponent from "../../components/FIlter/filter";
import CardContact from "./card-contact/card-contact";
import NoData from "../../components/NoData/NoData";

// Funções de ordenação
const sortByContactName = (data: ContactInfoDTO[]) => {
  return [...data].sort((a, b) => {
    const nameA = a.name?.toString().toLowerCase() || '';
    const nameB = b.name?.toString().toLowerCase() || '';
    return nameA.localeCompare(nameB);
  });
};

const sortByContactEmail = (data: ContactInfoDTO[]) => {
  return [...data].sort((a, b) => {
    const emailA = a.email?.toString().toLowerCase() || '';
    const emailB = b.email?.toString().toLowerCase() || '';
    return emailA.localeCompare(emailB);
  });
};

const Contacts: React.FC<{ enabledUsers: string[] }> = ({ enabledUsers }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userDoc } = useAuth();
  const profile = userDoc?.profile;
  const { id } = useParams<{ id: string }>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [contacts, setContacts] = useState<ContactInfoDTO[]>([]);
  const [filteredData, setFilteredData] = useState<ContactInfoDTO[]>([]);
  
  const data = { originId: id };

  const sortOptions = [
    { label: t("label-name"), sortFunction: sortByContactName },
    { label: t("label-email"), sortFunction: sortByContactEmail }
  ];

  const handleFilterClick = (filteredData: ContactInfoDTO[]) => {
    setFilteredData(filteredData);
  };

  const handleSortClick = (sortFunction: (data: ContactInfoDTO[]) => ContactInfoDTO[]) => {
    const sortedData = sortFunction([...filteredData]); // Ordenar os dados filtrados
    setFilteredData(sortedData);
  };

  const fetchContacts = async () => {
    if (id) {
      try {
        const data = await getContactsById(id);
        setContacts(data);
        setFilteredData(data);
      } catch (error) {
        console.error("Error fetching contacts: ", error);
      }
    }
  };

  useEffect(() => {
    fetchContacts();
  }, [id]);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    fetchContacts(); // Atualizar a lista de contatos ao fechar o modal
  };

  if (enabledUsers.includes(profile || "public")) {
    return (
      <div>
        {isModalOpen && (
          <div className="modal-background">
            <ContactForm contactId="" onClose={handleCloseModal} />
          </div>
        )}
        <div className="page">
          <div className="flex-row">
            <button className="blue-with-icon button" onClick={handleOpenModal}>
              <img src={addIcon} alt="add icon" />
              <p>{t("label-add-new")}</p>
            </button>
            <ImportExportCsv
              exportApi="exportcontactscsv"
              importApi="importcontactscsv"
              data={data}
              onClose={handleCloseModal}
            />
            <FilterComponent
              data={contacts}
              onFilterClick={handleFilterClick}
              sortOptions={sortOptions}
            />
          </div>
          <div className="card-list">
            <br></br>
            {filteredData.map((item, index) => (
              <CardContact onClose={handleCloseModal} key={index} item={item} />
            ))}
            {contacts.length === 0 && <NoData />}
          </div>
        </div>
      </div>
    );
  } else {
    navigate("/page-not-found");
    return <></>;
  }
};

export default Contacts;
