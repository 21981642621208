import CardScrapCategory from "./card-scrap-category/card-scrap-categories";
import "../../scrap.css";
import NoData from "../../../../components/NoData/NoData";
import { ScrapsCategoryInfoDTO } from "../models/scrap-category-DTO";

interface cardlistProps {
  scrapCategories: ScrapsCategoryInfoDTO[]
}

const CardListScrapCategories = ({scrapCategories}:cardlistProps) => {

  return (
    <div className="card-list">
      <br></br>
      {scrapCategories.map((item, index) => (
        <CardScrapCategory key={index} item={item} />
      ))}
      {scrapCategories.length === 0 && <NoData />}
    </div>
  );
};

export default CardListScrapCategories;
