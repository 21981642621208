import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  SparkIcon,
  SparkTable,
  SparkTableBody,
  SparkTableCell,
  SparkTableHead,
  SparkTableRow,
} from "@bosch-web-dds/spark-ui-react";
import list from "../../../assets/img/list-view.svg";
import card from "../../../assets/img/components.svg";
import React, { useEffect, useState } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import { firestore } from "../../../utils/firebase-config";
import ImportExportCsv from "../../../components/ImportExportCsv/ImportExportCsv";
import EcommerceCard from "../../../components/EcommerceCard/EcommerceCard";
import Slider from "../../../components/Slider/Slider";
import NoData from "../../../components/NoData/NoData";
import { useAuth } from "../../../contexts/auth-context";
import FilterComponent from "../../../components/FIlter/filter";
import { colorsCardStatus } from "../../../assets/enums/colors-card-status";
import { transformDateFormat } from "../../../services/date-format";
import { receiveNumberFormatWithMilhar } from "../../../services/number-format";
import Tooltip from '../../../components/tooltip/tooltip';
const ScrapAdminScrapsCategoryInBidView: React.FC<{ enabledUsers: string[] }> = ({
  enabledUsers,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userDoc } = useAuth();
  const profile = userDoc?.profile;
  const [slideData, setSliderData] = useState<string>("");
  const { id } = useParams<{ id: string }>();
  const data = { generatorId: id };
  const location = useLocation();
  const [tableData, setTableData] = useState<any>([]);

  const tableHeader = [
    `${t("label-material")}`,
    `${t("label-scrap-generator")}`,
    `${t("label-quantity")}`,
    `${t("label-value")}`,
    `${t("label-region")}`,
    `${t("material-sales-date")}`,
    `${t("label-status")}`,
  ];
  const [filteredData, setFilteredData] = useState<any[]>([]);

  const handleFilterClick = (filteredData: any[]) => {
    setFilteredData(filteredData);
  };

  const sortByname = (data: any[]) => {
    return [...data].sort((a, b) => {
      const nameA = a.scrapCategory.name?.toString().toLowerCase() || "";
      const nameB = b.scrapCategory.name?.toString().toLowerCase() || "";
      return nameA.localeCompare(nameB);
    });
  };

  const sortBycity = (data: any[]) => {
    return [...data].sort((a, b) => {
      const cityA = a.operationAddress?.city?.toString().toLowerCase() || "";
      const cityB = b.operationAddress?.city?.toString().toLowerCase() || "";
      return cityA.localeCompare(cityB);
    });
  };
  const sortByorigin = (data: any[]) => {
    return [...data].sort((a, b) => {
      const originA = a.originName?.toString().toLowerCase() || "";
      const originB = b.originName?.toString().toLowerCase() || "";
      return originA.localeCompare(originB);
    });
  };

  const sortOptions = [
    { label: t("label-material"), sortFunction: sortByname },
    { label: t("label-city"), sortFunction: sortBycity },
    { label: t("label-scrap-generator"), sortFunction: sortByorigin },
  ];

  const handleSortClick = (sortFunction: (data: any[]) => any[]) => {
    const sortedData = sortFunction([...filteredData]);
    setFilteredData(sortedData);
  };

  const fetchTableData = async () => {
  const qAwaitingBid = query(
      collection(firestore, "ScrapByGenerator"),
      where("status", "==", "AWAITING_BID"),
      where(
        location.pathname.includes("scrap-groups-details")
          ? "scrapCategory.group.id"
          : "scrapCategory.id",
        "==",
        id
      )
    );

    const qInBid = query(
      collection(firestore, "ScrapByGenerator"),
      where("status", "==", "IN_BID"),
      where(
        location.pathname.includes("scrap-groups-details")
          ? "scrapCategory.group.id"
          : "scrapCategory.id",
        "==",
        id
      )
    );

    const querySnapshotAwaitingBid = await getDocs(qAwaitingBid);
    const querySnapshotInBid = await getDocs(qInBid);

    const combinedQuerySnapshot = [
      ...querySnapshotAwaitingBid.docs,
      ...querySnapshotInBid.docs,
    ];

    const items = combinedQuerySnapshot.map((doc) => ({
      id: doc.id,
      ...doc.data(),
      searchData: doc
        .data()
        ["scrapCategory"]["name"].concat(
          " ",
          doc.data()["scrapCategory"]["description"],
          " ",
          doc.data()["specialDescription"],
          " ",
          doc.data()["originName"],
          " ",
       //   doc.data()["operationAddress"]["city"],
          " ",
        //  doc.data()["operationAddress"]["state"]
        ),
    })) as unknown as any[];
    setTableData(items);
    setFilteredData(items);
  };

  useEffect(() => {
    fetchTableData();
  }, [id]);

  const handleCloseModal = () => {
    fetchTableData();
  };
  const cellStyle = {
    padding: "5px",
 
    maxWidth: "180px",

    whiteSpace: "normal",
  };


  if (enabledUsers.includes(profile || "public")) {
    return (
      <div style={{ marginBottom: "120px" }}>
        <div className="flex-row" style={{ justifyContent: "space-between" }}>
          <div className="flex-row">
            <ImportExportCsv
              exportApi="exportscrapbygeneratorscsv"
              importApi=""
              onClose={handleCloseModal}
            />
          </div>
          <FilterComponent
            data={tableData}
            onFilterClick={handleFilterClick}
            sortOptions={sortOptions}
          />

          <Slider
            options={[
              { display: list, value: "list", viewType: "image" },
              { display: card, value: "card", viewType: "image" },
            ]}
            setState={setSliderData}
          ></Slider>
        </div>

        <br />
        <br />
        {tableData.length === 0 && <NoData />}

        {tableData.length > 0 &&
          (slideData === "list" ? (
            <>
              <div className="info-section">
                <SparkTable>
                  <SparkTableHead>
                    <SparkTableRow>
                      <SparkTableCell className="icon-column"></SparkTableCell>
                      {tableHeader.map((header, index) => (
                        <SparkTableCell key={index}>
                          <strong>{header.toUpperCase()}</strong>
                        </SparkTableCell>
                      ))}
                    </SparkTableRow>
                  </SparkTableHead>

                  <SparkTableBody>
                    {filteredData.map((rowItem: any, rowIndex: any) => (
                      <SparkTableRow
                        key={rowIndex}
                        onClick={() =>
                          navigate(
                            `/scrap-generators-details/${rowItem.originId}/scraps-form/${rowItem.id}`
                          )
                        }
                      >
                      <SparkTableCell style={{ ...cellStyle,  }}>
                          <Tooltip
                            text={
                              rowItem.scrapCategory.isHazardousMaterial
                                ? t("label-hazardous-material")
                                : t("label-non-hazardous-material")
                            }
                          >
                            <div>
                              {rowItem.scrapCategory.isHazardousMaterial && (
                                <div >

                                  <SparkIcon

                                    className="icon-flag"
                                    no-padding={true}
                                    icName={"danger-hazards"}
                                  />
                                </div>
                              )}
                              <p  style={{ marginLeft: "25px" }}>
                                {rowItem.scrapCategory.name}
                              </p>
                            </div>
                          </Tooltip>
                        </SparkTableCell>
                        <SparkTableCell  style={{ ...cellStyle,  }}>
                          {rowItem.originName}
                        </SparkTableCell>
                        <SparkTableCell  style={{ ...cellStyle,  }}>
                          {receiveNumberFormatWithMilhar(
                            rowItem.contractTotalQuantityPreview
                          )}{" "}
                          {t(`unityENUM.${rowItem.unity}`)}
                        </SparkTableCell>
                        <SparkTableCell  style={{ ...cellStyle,  }}>
                          { (rowItem.status == "CONTRACTED" || rowItem.status == "OPERATING" ) ? rowItem.contractValue : rowItem.targetPrice ?? ""}/
                          {rowItem.targetPrice ? t(`unityENUM.${rowItem.unity}`) : ""}
                        </SparkTableCell>
                        <SparkTableCell  style={{ ...cellStyle,  }}>
                          {rowItem.operationAddress?.city} -{" "}
                          {rowItem.operationAddress?.countryName}
                        </SparkTableCell>
                        <SparkTableCell  style={{ ...cellStyle,  }}>
                          {transformDateFormat(rowItem.targetDate)}
                        </SparkTableCell>
                        <SparkTableCell
                          style={{ color: "var(--bosch-accent-blue)" }}
                        >
                          {t(`statusEnum.${rowItem.status}`)}
                        </SparkTableCell>
                      </SparkTableRow>
                    ))}
                  </SparkTableBody>
                </SparkTable>
              </div>
              <div style={{ padding: "1rem 0rem" }} className="flex-row">
                <div
                  style={{ padding: "1rem" }}
                  className="flex-row align-center"
                >
                  <SparkIcon no-padding={true} icName="danger-hazards" />
                  <p>- {t("label-hazardous-material")}</p>
                </div>
                <div
                  style={{ padding: "1rem" }}
                  className="flex-row align-center  "
                >
                  <SparkIcon no-padding={true} icName="less-minimize" />
                  <p>- {t("label-non-hazardous-material")}</p>
                </div>
              </div>
            </>
          ) : (
            <div className="eccomerce-card-wrapper">
              {filteredData.map((rowItem: any, rowIndex: any) => (
                <EcommerceCard
                  onClick={() =>
                    navigate(
                      `/scrap-generators-details/${rowItem.originId}/scraps-form/${rowItem.id}`
                    )
                  }
                  buttonAction={() => {}}
                  buttontext="Vizualizar"
                  imageUrl={
                    rowItem.images?.length > 0
                      ? rowItem.images
                      : rowItem.scrapCategory.image || "Image not found!"
                  }
                  price={ (rowItem.status == "CONTRACTED" || rowItem.status == "OPERATING" ) ? rowItem.contractValue : rowItem.targetPrice ?? ""}
                  unitPrice={ rowItem.targetPrice ? t(`unityENUM.${rowItem.unity}`) : ""}
                  key={rowIndex}
                  customBottom={
                    rowItem.scrapCategory.isHazardousMaterial ? (
                      <div className="flex-row align-center icon-container">
                        <SparkIcon no-padding={true} icName="danger-hazards" />
                        <p className="hidden-label">
                          {t("label-hazardous-material")}
                        </p>
                        <div
                          style={{
                            backgroundColor: colorsCardStatus[rowItem.status],
                            padding: 10,
                            color: "white",
                          }}
                        >
                          {t(`statusEnum.${rowItem.status}`)}
                        </div>
                      </div>
                    ) : (
                      <div className="flex-row align-center icon-container">
                        <SparkIcon no-padding={true} icName="less-minimize" />
                        <p className="hidden-label">
                          {t("label-non-hazardous-material")}
                        </p>
                        <div
                          style={{
                            backgroundColor: colorsCardStatus[rowItem.status],
                            padding: 10,
                            color: "white",
                          }}
                        >
                          {t(`statusEnum.${rowItem.status}`)}
                        </div>
                      </div>
                    )
                  }
                >
                  <div>
                    <h3>{rowItem.scrapCategory.name}</h3>
                    <p style={{ marginTop: 7 }}>
                      {rowItem.quantity} {t(`unityENUM.${rowItem.unity}`)}/
                      {t(`periodENUM.${rowItem.period}`)}
                    </p>
                    <p style={{ marginTop: 7 }}>
                      {t(`withdrawal`)}{" "}
                      {t(`periodENUM.${rowItem.pickUpFrequency}`)}
                    </p>
                    <p style={{ marginTop: 7 }}>
                      {rowItem.operationAddress?.city} -{" "}
                      {rowItem.operationAddress?.countryName}
                    </p>
                    <strong>
                      <p style={{ marginTop: 7 }}>{rowItem.originName}</p>
                    </strong>
                    <p style={{ marginTop: 7 }}>
                      {transformDateFormat(rowItem.targetDate)}
                    </p>
                  </div>
                </EcommerceCard>
              ))}
            </div>
          ))}
      </div>
    );
  } else {
    navigate("/page-not-found");
    return <></>;
  }
};

export default ScrapAdminScrapsCategoryInBidView;
