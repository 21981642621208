import React, { useEffect, useState } from "react";
import Select, { StylesConfig } from "react-select";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../utils/firebase-config";
import { getDriversByGeneratorAndDealer } from "../../pages/drivers/drivers-service";

const DriversInputAutocomplete: React.FC<{
  label: string;
  placeholder: string;
  scrapData: any;
  onChange: (driver: any) => void;
  initialValue?: any;
  isDisabled?: boolean;
}> = ({
  label,
  placeholder,
  scrapData,
  onChange,
  initialValue,
  isDisabled,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [driverOptions, setDriverOptions] = useState<any[]>([]);
  const [selectedDriver, setSelectedDriver] = useState<any>(
    initialValue || null
  );
  const [inputValue, setInputValue] = useState("");
  const [userId, setUserId] = useState<string>();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(user.uid);
      } else {
        setUserId("");
      }
    });
    return () => unsubscribe();
  }, []);

  const handleChangeRef = React.useRef(onChange);
  handleChangeRef.current = onChange;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const drivers = await getDriversByGeneratorAndDealer(
          scrapData.carrier.id,
        );

        const options = drivers.map((driver) => ({
          value: driver.id,
          label: driver.name,
          data: driver,
        }));

        setDriverOptions(options);

        if (initialValue) {
          const initialDriver = options.find(
            (option) => option.label === initialValue.name
          );
          if (initialDriver) {
            setSelectedDriver(initialDriver.data);
            handleChangeRef.current(initialDriver.data);
          }
        }
      } catch (error) {
        console.error("Error fetching drivers:", error);
      }
    };

    fetchData();
  }, [scrapData]);

  const handleChange = (selectedOption: any) => {
    if (selectedOption && selectedOption.data) {
      setSelectedDriver(selectedOption.data);
      handleChangeRef.current(selectedOption.data);
    } else {
      setSelectedDriver(null);
      handleChangeRef.current(null);
    }
  };

  const handleInputChange = (newValue: string) => {
    setInputValue(newValue);
  };

  const customFilterOptions = (candidate: any, input: string) => {
    return candidate?.label?.toLowerCase().includes(input?.toLowerCase());
  };

  const customStyles: StylesConfig<any, false> = {
    option: (provided) => ({
      ...provided,
    }),
  };

  return (
    <div className="flex-column select-field">
      <div className="flex-row justify-between">
        <label htmlFor="driver">{label}</label>
      </div>
      <div >
        <Select
          id="driver"
          placeholder={placeholder}
          value={
            selectedDriver
              ? { value: selectedDriver.id, label: selectedDriver.name }
              : null
          }
          onChange={handleChange}
          onInputChange={(newValue, { action }) => {
            if (action === "input-change") {
              handleInputChange(newValue);
            }
          }}
          options={driverOptions}
          filterOption={customFilterOptions}
          isDisabled={isDisabled}
          styles={customStyles}
        />
      </div>
    </div>
  );
};

export default DriversInputAutocomplete;
