import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CollectItemDTO } from "../../models/collect-item-DTO";
import { getScrapMaterialContractedById } from "../../../scraps/scraps-service";
import {
  ScrapContractDTO,
  ScrapInfoDTO,
} from "../../../scraps/scrap-admin-generator-view/components/scrap-form/models/scrap-DTO";
import { getScrapAdminContract } from "../../../scraps/scrap-admin-service";
import { SparkIcon } from "@bosch-web-dds/spark-ui-react";
import { transformDateFormat } from "../../../../services/date-format";
import { receiveNumberFormatWithMilhar } from "../../../../services/number-format";
import Tooltip from '../../../../components/tooltip/tooltip';
interface CardCollectItemProps {
  collect: CollectItemDTO;
  originEntity: string;
}

const CardCollectItem: React.FC<CardCollectItemProps> = ({
  collect,
  originEntity,
}) => {
  const { t } = useTranslation();
  const [materialDetails, setMaterialDetails] = useState<ScrapInfoDTO>();
  const [materialContract, setMaterialContract] = useState<ScrapContractDTO>();

  const fetchData = async () => {
    const responseMaterialDetails = await getScrapMaterialContractedById(
      collect.scrapMaterial
    );
    setMaterialDetails(responseMaterialDetails);

    const responseMaterialContract = await getScrapAdminContract(
      collect.scrapMaterial
    );
    setMaterialContract(responseMaterialContract);
  };

  useEffect(() => {
    fetchData();
  }, [collect]);

  return (
    <div className="collect-card flex-row align-center" style={{ gap: 20 }}>
      <div className="flex-row align-center" style={{ gap: 20, flex: 2 }}>
        <button>
          <SparkIcon icName="paperclip" />
        </button>
        <div className="flex-column">
  <span className="item-label">{t("label-material")}</span>
  <Tooltip
    text={
      materialDetails?.scrapCategory.isHazardousMaterial
        ? t("label-hazardous-material")
        : t("label-non-hazardous-material")
    }
  >
    <h4>{materialDetails?.scrapCategory?.name}</h4>
  </Tooltip>
</div>

      </div>
      {originEntity != "GENERATOR" && (
        <div className="flex-column" style={{ flex: 1.5 }}>
          <span className="item-label">{t("label-scrap-generator")}</span>
          <p>{materialDetails?.originName}</p>
        </div>
      )}
      {originEntity != "DEALER" && (
        <div className="flex-column" style={{ flex: 1.5 }}>
          <span className="item-label">{t("label-scrap-dealer")}</span>
          <p>{materialDetails?.contractDealerName}</p>
        </div>
      )}
     {collect.quantity && (
<div className="flex-column" style={{ flex: 1 }}>
        <span className="item-label">{t("label-net-weight")}</span>
        <p>{receiveNumberFormatWithMilhar(collect.quantity)} {t(`unityENUM.${collect.unity}`)}</p>
        
      </div>

     )} 
      <div className="flex-column" style={{ flex: 1 }}>
        <span className="item-label">{t("label-status")}</span>
        <p style={{ color: "var(--bosch-accent-blue)" }}>
          {t(`collect-statusENUM.${collect.status}`)}
        </p>
      </div>
      <div
        className="flex-column align-end justify-end"
        style={{ gap: 5, flex: 0.5 }}
      >
        <p>{collect.date? transformDateFormat(collect.date): "-"}</p>  <p >
          {t(`collect-periodENUM.${collect.period}`)}
        </p>
        {collect.status == "MTR_GENERATED" && (
          <p style={{ fontSize: 14 }} className="a-link-style">
            {t("collect-statusENUM.MTR_GENERATED")}
          </p>
        )}
        <div
          className="flex-column tag-value-quantity"
          style={{ gap: 5, minWidth: 80 }}
        >
          <p className="value justify-end flex-row" style={{ margin: 0 }}>
            {collect.valueByUnity || "XX xxx,xx"}/
            {t(`unityENUM.${materialDetails?.unity}`)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CardCollectItem;
