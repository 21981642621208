import React, { useState, useEffect, useRef } from "react";
import { Outlet, useNavigate, useParams, useLocation } from "react-router-dom";
import { SparkTabNavigation } from "@bosch-web-dds/spark-ui-react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../contexts/auth-context";

const GeneratorSettings: React.FC<{ enabledUsers: string[] }> = ({
  enabledUsers,
}) => {
  const { id } = useParams();
  const location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [tabs] = useState([{ value: "SIGOR", label: t("label-login-sigor") }]);
  const [selectedTab, setSelectedTab] = useState("SIGOR");
  const { userDoc } = useAuth();
  const profile = userDoc?.profile;

  const handleTabChange = (event: Event, data: { value: string }) => {
    const newTab = data.value;
    setSelectedTab(newTab);
    navigate(newTab);
  };

  if (
    enabledUsers.includes(profile || "public") ||
    location.pathname != `/settings/${id}`
  ) {
    return (
      <div className="page page-centered">
        <h2>{t("navigation.settings")}</h2>
        <SparkTabNavigation
          items={tabs}
          selectedTab={selectedTab}
          whenChange={handleTabChange}
        />
        <Outlet />
      </div>
    );
  } else {
    navigate("/page-not-found");
    return <></>;
  }
};

export default GeneratorSettings;
