import React from 'react';

interface CTAInfoComponentProps {
  children: React.ReactNode;
}

function CTAInfoComponent({ children }: CTAInfoComponentProps) {
  return <div className="cta-info-component">{children}</div>;
}

export default CTAInfoComponent;
