import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { useAuth } from "../../../../../../contexts/auth-context";
import { auth } from "../../../../../../utils/firebase-config";
import { useAlert } from "../../../../../../contexts/alert-context";
import { SuccessFeeDTO } from "./success-fee-DTO";
import { addNewSuccessFee, getSuccessFee } from "../../../../scrap-generator-service";
import CTAInfoComponent from "../../../../../../components/CTAInfoComponent/CTAInfoComponent";
import { SparkIcon } from "@bosch-web-dds/spark-ui-react";
import { applyDecimalMask } from "../../../../../../utils/formatting";

const SuccessFee: React.FC<{ enabledUsers: string[] }> = ({
  enabledUsers,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userDoc } = useAuth();
  const profile = userDoc?.profile;
  const { id } = useParams<{ id: string }>();
  const { setAlert } = useAlert();
  const [userId, setUserId] = useState<string>();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(user.uid);
      } else {
        setUserId("");
      }
    });
    return () => unsubscribe();
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    getValues,
  } = useForm<SuccessFeeDTO>();


  useEffect(() => {
    if (id) {
      getSuccessFee(id).then((data) => {
        if (data) {
       
          Object.entries(data).forEach(([key, value]) => {
            if (typeof value === "object" && value !== null) {
              Object.entries(value as Record<string, unknown>).forEach(
                ([subKey, subValue]) => {
                  setValue(
                    `${key}.${subKey}` as keyof SuccessFeeDTO,
                    subValue as any
                  );
                  setValue(
                    "value",
                    data.value ? applyDecimalMask(data?.value?.toString()) : null
                  );
                }
              );
            } else {
              setValue(key as keyof SuccessFeeDTO, value as any);
            }
          });
        }
      });
    }
  }, [id, setValue]);

  const onSubmit = async (fee: SuccessFeeDTO) => {
    try {

      if(Number(fee.value.toString().replace(/\./g, ""))> 100){
        setAlert({
          text: `${t("label-fee-bigger-100-error")}`,
          type: "error",
        });
      } else {
        await addNewSuccessFee(fee, id ?? "");
      setAlert({ text: t("alert-successfully-registered"), type: "success" });
      }
      
    } catch (e: any) {
      console.error("Erro ao cadastrar: ", e);
      setAlert({
        text: `${t("alert-error-registered")}: ${e.message}`,
        type: "error",
      });
    }
  };


  if (enabledUsers.includes(profile || "public")) {
    return (
      <div className="page-centered">
                <div style={{ marginBottom: 20 }}>
          <CTAInfoComponent>
            <div className="flex-row align-center" style={{ gap: 10 }}>
              <SparkIcon noPadding icName="info-i" pallete="primary" />
              <p>{t("label-fee-info")}</p>
            </div>
          </CTAInfoComponent>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-fields flex-column">
          <br />
            <div className="flex-row">
            <div className="flex-column" style={{alignItems: "center"}}> 
         
              <input
                placeholder={t("placeholder.generalEmpty")}
                type="number"
                id="legalName"
                {...register("value", { required: true })}
              />
              {errors.value && (
                <span>{t("error-input-required")}</span>
              )}
            </div>
            <h2 style={{alignItems: "center" }}>%</h2>
          </div>
          </div>
          <br />
          <br />
          <div className="flex-row buttons-section">
            <button className="blue" type="submit">
              {t("label-save")}
            </button>
          </div>
        </form>
      </div>
    );
  } else {
    navigate("/page-not-found");
    return <></>;
  }
};

export default SuccessFee;
