import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {SocialActivityInfoDTO } from "../social-activities-DTO";
import SocialActivityForm from "../social-activities-form/social-activities-form";


interface Props {
  item: SocialActivityInfoDTO;
  onClose: () => void;
}

const CardSpecialTechnologies = ({ onClose, item }: Props) => {
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    onClose();
  };

  return (
    <div>
      {isModalOpen && (
        <div className="modal-background">
          <SocialActivityForm contactId={item.id} onClose={handleCloseModal} />
        </div>
      )}
      <div className="flex-row card-dealer" onClick={handleOpenModal}>
        <div className="flex-column infos">
          <h3>{item.socialActivityTitle || "-"}</h3>
          <div className="custom-line-hr" />
          <p>
            {item.socialActivity || "-"}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CardSpecialTechnologies;
