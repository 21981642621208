import { receiveNumberFormatWithMilhar } from "./number-format";

export function calculateFromCurrency(
  currencyString: string,
  amountToCalculate: number,
  currency: string,
  locale: string,
  currencyFormatting: string
): string {
  const numberString = currencyString.replace(/[^\d,.-]/g, "");
  let number;
  if (currencyFormatting == ",") {
    number = numberString.replace(".", "");
    number = number.replace(",", ".");
    number = parseFloat(number.replace(",", "."));
  } else {
    number = parseFloat(numberString.replace(",", ""));
  }
  const resultNumber = number + amountToCalculate;
  const result = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
  }).format(resultNumber);
  return result;
}

export function calculatePriceForContractFromCurrency(
  currencyString: string,
  quantity: number
): string {
  let modifiedPrice: string = currencyString.replace("$", "$-");
  const currencySymbol = modifiedPrice.split("-")[0];
  const currencyNumber = modifiedPrice.split("-")[1];
  const numberString = currencyNumber?.replace(/[^\d,.-]/g, "");
  let number;
  if (currencySymbol == "R$") {
    number = numberString.replace(".", "");
    number = number.replace(",", ".");
    number = parseFloat(number);
  } else {
    number = parseFloat(numberString?.replace(",", ""));
  }
  const resultNumber = number * quantity;
  const result =
    currencySymbol + " " + receiveNumberFormatWithMilhar(resultNumber);
  return result;
}
