import { useTranslation } from "react-i18next";
import addIcon from "../../assets/svgs/add.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import ImportExportCsv from "../../components/ImportExportCsv/ImportExportCsv";
import { useAuth } from "../../contexts/auth-context";
import FilterComponent from "../../components/FIlter/filter";

import NoData from "../../components/NoData/NoData";
import { DriversInfoDTO } from "./drivers-DTO";
import DriverForm from "./form-driver/form-driver";
import { getDriversById } from "./drivers-service";
import { SparkTable, SparkTableBody, SparkTableCell, SparkTableHead, SparkTableRow } from "@bosch-web-dds/spark-ui-react";
import { transformDateFormat } from "../../services/date-format";

const sortByDriverName = (data: DriversInfoDTO[]) => {
  return [...data].sort((a, b) => {
    const nameA = a.name?.toString().toLowerCase() || '';
    const nameB = b.name?.toString().toLowerCase() || '';
    return nameA.localeCompare(nameB);
  });
};


const Drivers: React.FC<{ enabledUsers: string[] }> = ({ enabledUsers }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userDoc } = useAuth();
  const profile = userDoc?.profile;
  const { id } = useParams<{ id: string }>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [drivers, setDrivers] = useState<DriversInfoDTO[]>([]);
  const [filteredData, setFilteredData] = useState<DriversInfoDTO[]>([]);

  const data = { originId: id };

  const sortOptions = [
    { label: t("label-name"), sortFunction: sortByDriverName },
  ];

  const handleFilterClick = (filteredData: DriversInfoDTO[]) => {
    setFilteredData(filteredData);
  };

  const handleSortClick = (sortFunction: (data: DriversInfoDTO[]) => DriversInfoDTO[]) => {
    const sortedData = sortFunction([...filteredData]);
    setFilteredData(sortedData);
  };

  const fetchDrivers = async () => {
    if (id) {
      try {
        const data = await getDriversById(id);
        setDrivers(data);
        setFilteredData(data);
      } catch (error) {
        console.error("Error fetching drivers: ", error);
      }
    }
  };

  const [driverId, setDriverId] = useState<string | null>(null);

  useEffect(() => {
    fetchDrivers();
  }, [id]);

  const handleOpenModal = (driverId: string | undefined) => {
    setDriverId(driverId ?? "")
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    fetchDrivers();
  };


  const colors = {
    "VALIDATED": "#00884a",
    "NOT_COMPLIANT": "#ed0007",
    "TO_EXPIRE": "#ffcf00",
  }
  function getDriverStatus(date: string) {
    let driverDate = new Date(date);
    let currentDate = new Date();
    let futureDate = new Date(currentDate);
    futureDate.setMonth(currentDate.getMonth() + 1);

    if (driverDate > futureDate) {
      return '#00884a'
    } else if (driverDate >= currentDate && driverDate <= futureDate) {
      return '#ffcf00'
    } else {
      return '#ed0007'
    }
  }

  if (enabledUsers.includes(profile || "public")) {
    return (
      <div>
        {isModalOpen && (
          <div className="modal-background">
            <DriverForm driverId={driverId ?? ""} onClose={handleCloseModal} />
          </div>
        )}
        <div className="page">
          <div className="flex-row">
            <button className="blue-with-icon button" onClick={() =>
              handleOpenModal("")}>
              <img src={addIcon} alt="add icon" />
              <p>{t("label-add-new")}</p>
            </button>
            {profile === "admin-bosch" && (
            <ImportExportCsv
              exportApi="exportdriverscsv"
              importApi="importdriverscsv"
              data={data}
              onClose={handleCloseModal}
            />
            )}
            <FilterComponent
              data={drivers}
              onFilterClick={handleFilterClick}
              sortOptions={sortOptions}
            />
          </div>
          <br></br>
          <SparkTable>
            <SparkTableHead>
              <SparkTableRow>
                <SparkTableCell style={{ width: "2rem" }}></SparkTableCell>
                <SparkTableCell><strong>{t("label-name")}</strong></SparkTableCell>
                <SparkTableCell><strong>{t("label-rg")}</strong></SparkTableCell>
                <SparkTableCell><strong>{t("label-cnhValidate")}</strong></SparkTableCell>
                <SparkTableCell><strong>{t("label-autorizationDate")}</strong></SparkTableCell>

              </SparkTableRow>
            </SparkTableHead>
            <SparkTableBody>
              {filteredData.map((obj: any, rowIndex: any) => (
                <SparkTableRow onClick={() => { handleOpenModal(obj.id); }} key={rowIndex}>
                  <SparkTableCell style={{ width: "2rem" }}>
                    <div style={{ width: "24px", height: "24px", backgroundColor: getDriverStatus(obj?.expirationDocument?.expirationDate) }}></div>
                  </SparkTableCell>
                  <SparkTableCell>{obj.name}</SparkTableCell>
                  <SparkTableCell>{obj.rg}</SparkTableCell>
                  <SparkTableCell>{obj.cnhValidate}</SparkTableCell>
                  <SparkTableCell>{transformDateFormat( obj.autorizationDate)} - {transformDateFormat(obj?.expirationDocument?.expirationDate)}</SparkTableCell>

                </SparkTableRow>
              ))}
            </SparkTableBody>
          </SparkTable>

        </div>
      </div>
    );
  } else {
    navigate("/page-not-found");
    return <></>;
  }
};

export default Drivers;
