
import { t } from "i18next";

export function formatTimestampDateAndTime(timestamp: {
  seconds: number;
  nanoseconds: number;
}): string {
  const date = new Date(
    timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000
  );
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear().toString();
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  return `${day}/${month}/${year} - ${hours}:${minutes}`;
}

export function reciveDaysAndtransformInYearsOrMonths(days: number) {
  days = parseInt(days.toFixed(0))
  if (days % 365 == 0) {
    return `${(days / 365).toFixed(0)} ${t(days / 365 > 1 ? "years" : "year")}`
  } else {
    return `${(days / 30).toFixed(0)} ${t(days / 30 > 1 ? "months" : "month")}`
  }
}

export function addDaysAndTransformDateStringToDate(
  dateString: string,
  daysToAdd: number
): Date {
  const [year, month, day] = dateString.split("-").map(Number);
  const date = new Date(year, month - 1, day);
  date.setDate(date.getDate() + daysToAdd);
  return date;
}

export function addDaysToToday(daysToAdd: number): string {
  const today = new Date();
  const futureDate = new Date(
    today.getTime() + daysToAdd * 24 * 60 * 60 * 1000
  );
  const year = futureDate.getFullYear();
  const month = (futureDate.getMonth() + 1).toString().padStart(2, "0");
  const day = futureDate.getDate().toString().padStart(2, "0");
  return `${year}-${month}-${day}`;
}

export function formatTimestampDate(timestamp: {
  seconds: number;
  nanoseconds: number;
}): string {
  const date = new Date(
    timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000
  );
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear().toString();
  return `${day}/${month}/${year}`;
}

export function transformDateFormat(dateString: string | undefined): string {
  if (!dateString) {
    return "-";
  }

  try {
    const [year, month, day] = dateString.split("-");
    const formattedDate = `${day}/${month}/${year}`;
    return formattedDate;
  } catch (error) {
    console.error("Erro ao formatar a data:", error);
    return "-";
  }
}


export function formatTimestampToDate(timestamp: {
  seconds: number;
  nanoseconds: number;
}): string {
  const date = new Date(
    timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000
  );
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear().toString();
  return `${year}-${month}-${day}`;
}

export function formatDateToString(date: Date | any): string {
  if (!(date instanceof Date)) {
    date = new Date(date.seconds * 1000);
  }

  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();

  return `${day}-${month}-${year}`;
}

export function formatDateToStringYearFirst(date: Date | any): string {
  if (!(date instanceof Date)) {
    date = new Date(date.seconds * 1000);
  }

  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();

  return `${year}-${month}-${day}`;
}
