import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { COLLECTIONS } from "../../../../assets/enums/firebase-colections";
import { saveNewAsync, update } from "../../../../services/persistence";
import { SigorLoginDTO } from "../../generator-settings-DTO";
import { firestore } from "../../../../utils/firebase-config";

export async function upsertSigorLogin(data: SigorLoginDTO) {
  if (!data.id) {
    const docRef = await saveNewAsync(COLLECTIONS.SIGOR_LOGIN, data);
    return docRef;
  } else {
    await update(COLLECTIONS.SIGOR_LOGIN, data, data.id);
    return data;
  }
}

export async function getSigorLoginById(id: string): Promise<SigorLoginDTO> {
  const docRef = doc(firestore, COLLECTIONS.SIGOR_LOGIN, id);
  const docSnapshot = await getDoc(docRef);

  return docSnapshot.data() as SigorLoginDTO;
}

export async function getSigorLoginByOriginId(
  originId: string
): Promise<SigorLoginDTO | null> {
  const q = query(
    collection(firestore, COLLECTIONS.SIGOR_LOGIN),
    where("generatorId", "==", originId)
  );

  const querySnapshot = await getDocs(q);

  if (querySnapshot.empty) {
    return null;
  }

  const doc = querySnapshot.docs[0];
  const sigorLogin = {
    ...(doc.data() as SigorLoginDTO),
    id: doc.id,
  };

  return sigorLogin;
}
