import React, { useEffect, useState } from "react";
import Select, { StylesConfig } from "react-select";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { getScrapCategoriesApproved } from "../../pages/scraps/scraps-service";
import { getAllPendingItemsByRequester } from "../../services/pending-approval-service";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../utils/firebase-config";

const ScrapCategoryInputAutocomplete: React.FC<{
  label: string;
  placeholder: string;
  onChange: (category: any) => void;
  initialValue?: any;
  callHandlerFirstValue?: boolean;
  isDisabled?: boolean;
}> = ({ label, placeholder, onChange, callHandlerFirstValue, initialValue, isDisabled }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [categoryOptions, setCategoryOptions] = useState<any[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<any>(
    initialValue || null
  );
  const [inputValue, setInputValue] = useState("");
  const [userId, setUserId] = useState<string>();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(user.uid);
      } else {
        setUserId("");
      }
    });
    return () => unsubscribe();
  }, []);

  const handleChangeRef = React.useRef(onChange);
  handleChangeRef.current = onChange;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const approvedCategories = await getScrapCategoriesApproved();
        const pendingItems = await getAllPendingItemsByRequester(userId || "");

        const additionalCategories = pendingItems.map(
          (item) => item.data.scrapsCategoryInfo
        );

        const combinedCategories = [
          ...approvedCategories,
          ...additionalCategories,
        ];

        const options = combinedCategories.map((category) => ({
          value: category.id,
          label: category.name,
          data: category,
        }));

        setCategoryOptions(options);

        if (initialValue) {
          const initialCategory = options.find(
            (option) => option.label === initialValue.name
          );
          if (initialCategory) {
            if (callHandlerFirstValue){
              onChange(initialCategory.data)
            }
            setSelectedCategory(initialCategory.data);
            handleChangeRef.current(initialCategory.data);
          }
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchData();
  }, [initialValue, userId]);

  const handleChange = (selectedOption: any) => {
    if (selectedOption && selectedOption.value === "add-new") {
      navigate(`/scrap-categories-form/${id}/${inputValue}`);
    } else if (selectedOption && selectedOption.data) {
      setSelectedCategory(selectedOption.data);
      handleChangeRef.current(selectedOption.data);
    } else {
      setSelectedCategory(null);
      handleChangeRef.current(null);
    }
  };

  const handleInputChange = (newValue: string) => {
    setInputValue(newValue);
  };

  const customFilterOptions = (candidate: any, input: string) => {
    if (candidate.value === "add-new") {
      return true;
    }
    return candidate?.label?.toLowerCase().includes(input?.toLowerCase());
  };

  const customStyles: StylesConfig<any, false> = {
    option: (provided, state) => ({
      ...provided,
      ...(state.data.value === "add-new" && {
        textAlign: "initial",
        color: "#007bc0",
        fontWeight: "regular",
        fontSize:"14px"
      }),
    }),
  };

  return (
    <div className="flex-column select-field">
      <div className="flex-row justify-between">
        <label htmlFor="category">{label}</label>
      </div>
      <div style={{ margin: "0.5rem 0rem" }}>
        <Select
          id="category"
          placeholder={placeholder}
          value={
            selectedCategory
              ? { value: selectedCategory.id, label: selectedCategory.name }
              : null
          }
          onChange={handleChange}
          onInputChange={(newValue, { action }) => {
            if (action === "input-change") {
              handleInputChange(newValue);
            }
          }}
          options={[
            ...categoryOptions,
            { value: "add-new", label: t("label-didnt-find") },
          ]}
          filterOption={customFilterOptions}
          isDisabled={isDisabled}
          styles={customStyles}
        />
      </div>
    </div>
  );
};

export default ScrapCategoryInputAutocomplete;
