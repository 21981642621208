import { useTranslation } from "react-i18next";

const CardInfosPrice: any = ({}) => {
  const { t } = useTranslation();

  return (
    <div className="infos-scrap">
      <div className="container-percentage">
        <span>{t("landing-page.percentage")}</span>
      </div>
      <div className="container-description-percentage">
        <span className="text-percentage">
          {t("landing-page.description-one-percentage")}
        </span>
        <span className="text-percentagem">
          {t("landing-page.description-one-percentagem")}
        </span>
        <span className="text-percentagem-description">{t("landing-page.description-two-percentage")}</span>
      </div>
    </div>
  );
};

export default CardInfosPrice;