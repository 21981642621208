import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { t } from "i18next";
import {
  SparkIcon,
  SparkTable,
  SparkTableBody,
  SparkTableCell,
  SparkTableHead,
  SparkTableRow,
} from "@bosch-web-dds/spark-ui-react";
import { extractFileNameFromUrl } from "../../../../services/string-treatment";
import ScrapMaterialInputAutocomplete from "../../../../components/ScrapMaterialInputAutocomplete/ScrapMaterialInputAutocomplete";
import { CollectItemDTO } from "../../models/collect-item-DTO";
import { useAlert } from "../../../../contexts/alert-context";
import { addNewCollect, getCDFById } from "../../collects-service";
import { getScrapAdminContract } from "../../../scraps/scrap-admin-service";
import { ScrapInfoDTO } from "../../../scraps/scrap-admin-generator-view/components/scrap-form/models/scrap-DTO";
import { CurrencyInput } from "react-currency-mask";
import {
  getAdrressByEntityId,
  getCountryConfigById,
} from "../../../../services/address-service";
import { getScrapDealerById } from "../../../scrap-dealers/scrap-dealers-service";
import { useAuth } from "../../../../contexts/auth-context";
import { useParams } from "react-router-dom";
import { applyDecimalMask } from "../../../../utils/formatting";
import genericImagePreview from "./../../../../assets/svgs/material-list.svg";
import VehiclesInputAutocomplete from "../../../../components/VehiclesInputAutocomplete/VehiclesInputAutocomplete";
import DriversInputAutocomplete from "../../../../components/DriverInputAutocomplete/DriverInputAutocomplete";
import CollapsibleSection from "../../../../components/ColapseSection/CollapsibleSection";
import { CDFDTO } from "../cdf-section/modal-cdf/cdf-dto";
import { transformDateFormat } from "../../../../services/date-format";
import alertIconImg from "../../../../assets/svgs/alert-warning.svg";
import { DriversInfoDTO } from "../../../drivers/drivers-DTO";
import ScrapStorageInputAutocomplete from "../../../../components/ScrapStorageInputAutocomplete/ScrapStorageInputAutocomplete";
import { CountryConfigDTO } from "../../../settings/components/Country-config/country-config-DTO";

interface NewCollectModalProps {
  originEntity: string;
  item?: CollectItemDTO;
  profile: string;
  onSave: (item: CollectItemDTO) => void;
  onClose: () => void;
}

const NewCollectModal: React.FC<NewCollectModalProps> = ({
  item,
  onSave,
  onClose,
  profile,
  originEntity,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<CollectItemDTO>({
    defaultValues: item as CollectItemDTO,
  });
  const { userDoc } = useAuth();
  const originId = userDoc?.originId;
  const { id } = useParams<{ id: string }>();

  const [initialWeighing, setInitialWeighing] = useState("");
  const [taraWeighing, setTaraWeighing] = useState("");

  const [selectedContainerType, setSelectedContainerType] = useState<any>("");

  const [isHazardousMaterial, setIsHazardousMaterial] = useState(false);
  const [scrapMaterial, setScrapMaterial] = useState<ScrapInfoDTO | null>(null);
  const [automaticMTR, setAutomaticMTR] = useState<boolean>();
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [SelectedTicketFiles, setSelectedTicketFiles] = useState<File[]>([]);
  const [selectedMTRFiles, setSelectedMTRFiles] = useState<File[]>([]);
  const [autocompleteError, setAutocompleteError] = useState(false);
  const [expirationMaterial, setExpirationMaterial] = useState(false);
  const [driverAutocompleteError, setDriverAutocompleteError] = useState(false);
  const [driver, setDriver] = useState<DriversInfoDTO>();
  const [vehicleAutocompleteError, setVehicleAutocompleteError] =
    useState(false);
  const watchedInvoiceAttachment = watch("invoiceAttachment");
  const watchedAttachmentMTR = watch("attachmentMTR");
  const watchedWeighingTicketAttachment = watch("attachamentticket");
  const [scrapMaterialPreview, setScrapMaterialPreview] = useState<any>();
  const [scrapMaterialContract, setScrapMaterialContract] = useState<any>();
  const [scrapDealerDetails, setScrapDealerDetails] = useState<any>();
  const [CdfCollect, setCdfCollect] = useState<CDFDTO>();
  let timer: NodeJS.Timeout;
  const [totalValueDefault, setTotalValueDefault] = useState<any>(
    item?.totalValue ||
      scrapMaterialContract?.contractValueUnity *
        Number(
          scrapMaterialPreview?.quantity.replace(/\./g, "").replace(",", ".")
        )
  );
  const [countryConfigEntity, setCountryConfigEntity] =
    useState<CountryConfigDTO>();

  const { setAlert } = useAlert();

  const fetchEntityCountry = async () => {
    try {
      const entityId = id || item?.generatorId;
      if (entityId) {
        const data = await getAdrressByEntityId(entityId);
        if (data?.country) {
          const countryConfig = await getCountryConfigById(data.country);
          setCountryConfigEntity(countryConfig);
        }
      }
    } catch (error) {
      console.error("Failed to fetch country configuration:", error);
    }
  };

  useEffect(() => {
    fetchEntityCountry();
  }, []);

  useEffect(() => {
    if (item) {
      setValue("status", item.status);
      setValue(
        "quantity",
        item.quantity ? applyDecimalMask(item?.quantity?.toString()) : null
      );
      setValue("containerType", item.containerType || "");
      setSelectedContainerType(item.containerType || "");

      setAutomaticMTR(item.automaticMTR);
      if (item.hasCDF) {
        getCDFById(item.id).then((data) => {
          if (data) {
            setCdfCollect(data);
          }
        });
      }
    }
  }, [item]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files?.length > 0) {
      setSelectedFiles((prevFiles) => [...prevFiles, ...Array.from(files)]);
    }
  };
  const handleticketChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files?.length > 0) {
      setSelectedTicketFiles((prevFiles) => [
        ...prevFiles,
        ...Array.from(files),
      ]);
    }
  };

  const handleMTRFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files?.length > 0) {
      setSelectedMTRFiles((prevFiles) => [...prevFiles, ...Array.from(files)]);
    }
  };

  useEffect(() => {
    if (scrapMaterial) {
      handleSetScrapMaterial(scrapMaterial);
    }
  }, [scrapMaterial]);

  const handleSetDriver = async (driver: DriversInfoDTO) => {
    setValue("driver", driver);
    setDriver(driver);
    setAutocompleteError(false);
  };

  const handleSetScrapMaterial = async (scrapMaterial: ScrapInfoDTO) => {
    setValue("scrapMaterial", scrapMaterial);
    setScrapMaterialPreview(scrapMaterial);
    setAutocompleteError(false);

    setIsHazardousMaterial(scrapMaterial.scrapCategory.isHazardousMaterial);

    let expirationDate = new Date(
      scrapMaterial.expirationDocument.expirationDate
    );
    expirationDate = new Date(
      expirationDate.getTime() + 1 * 24 * 60 * 60 * 1000
    );
    setExpirationMaterial(expirationDate < new Date() ? true : false);

    const contractDetails = await getScrapAdminContract(scrapMaterial.id);
    setScrapMaterialContract(contractDetails);

    const scrapDealerDetails = await getScrapDealerById(
      scrapMaterial.contractDealer
    );
    setScrapDealerDetails(scrapDealerDetails);
  };

  const getImageSrc = (scrapMaterialPreview: any) => {
    if (
      scrapMaterialPreview?.images &&
      scrapMaterialPreview.images.length > 0
    ) {
      return scrapMaterialPreview.images[0];
    } else if (scrapMaterialPreview?.scrapCategory?.image) {
      return scrapMaterialPreview?.scrapCategory?.image;
    } else {
      return genericImagePreview;
    }
  };

  function getDriverStatus(date: string) {
    let driverDate = new Date(date);
    let currentDate = new Date();
    let futureDate = new Date(currentDate);
    futureDate.setMonth(currentDate.getMonth() + 1);

    if (driverDate > futureDate) {
      return "#00884a";
    } else if (driverDate >= currentDate && driverDate <= futureDate) {
      return "#ffcf00";
    } else {
      return "#ed0007";
    }
  }

  const onSubmit = async (data: CollectItemDTO) => {
    if (!data.scrapMaterial) {
      setAutocompleteError(true);
    } else if (!data.driver) {
      setDriverAutocompleteError(true);
    } else if (!data.vehicle) {
      setVehicleAutocompleteError(true);
    } else {
      data.initialWeighing = data.initialWeighing || "";
      data.tara = data.tara || "";
      data.quantity = Number(data.quantity.replace(/\./g, ""));
      data.unity = scrapMaterialPreview.unity;
      data.valueByUnity = scrapMaterialContract?.contractValue;
      data.generatorId = data.scrapMaterial.originId;
      data.totalValue =
        Number(scrapMaterialContract?.contractValueUnity) *
        Number(data.quantity);
      data.quantity = Number(data.quantity);
      data.contractedDealerId = data.scrapMaterial.contractDealer;
      data.scrapMaterialName = data.scrapMaterial.scrapCategory.name;
      data.scrapGeneratorName = data.scrapMaterial.originName;
      data.isHazardousMaterial =
        data.scrapMaterial.scrapCategory.isHazardousMaterial;
      data.scrapDealerName = data.scrapMaterial.contractDealerName;
      data.scrapMaterial = data.scrapMaterial.id;
      if (countryConfigEntity) {
        data.currency = countryConfigEntity.currency;
        data.locale = countryConfigEntity.locale;
      }

      if (automaticMTR === false || automaticMTR === undefined) {
        data.automaticMTR = false;
      } else {
        data.automaticMTR = true;
      }
      await addNewCollect(
        data,
        selectedFiles,
        selectedMTRFiles,
        SelectedTicketFiles
      ).then((response) => {
        if (data.id) {
          setAlert({
            text: t("alert-successfully-updated"),
            type: "success",
          });
        } else {
          setAlert({
            text: t("alert-successfully-registered"),
            type: "success",
          });
        }
        if (!response.success) {
          const delay = 8000;
          timer = setTimeout(() => {
            setAlert({
              text: `${
                response?.error
                  ? response?.error
                  : `MTR: ${t("alert-error-generic")}`
              }`,
              type: "error",
            });
          }, delay);
        }

        onClose();
        onSave(data);
      });
    }
  };

  return (
    <div
      className="big-modal-content"
      style={{ gap: "20px", padding: "1rem 1rem 2rem 1rem" }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-fields">
          <CollapsibleSection title={t("label-collect")} titleFontSize="18px">
            <div className="flex-column">
              <ScrapMaterialInputAutocomplete
                originEntity={originEntity}
                initialValue={item?.scrapMaterial}
                isDisabled={item ? true : false}
                label={`${t("label-material")}*`}
                onChange={(scrapMaterial) =>
                  handleSetScrapMaterial(scrapMaterial)
                }
                placeholder={t("label-material")}
              />
              <div className="flex-row justify-between">
                {autocompleteError && <span>{t("error-input-required")}</span>}
                <div></div>
                <p
                  style={{
                    color: "var(--extra-grey-medium)",
                    fontSize: 12,
                  }}
                >
                  {t("label-material-contracted")}
                </p>
              </div>
            </div>

            {scrapMaterialPreview && (
              <div
                className="resume-material-collect flex-row margin-topbottom-1"
                style={{
                  gap: 10,
                  justifyContent: "center",
                  margin: "20px auto",
                  maxWidth: "650px",
                }}
              >
                <div className="flex-column">
                  <img
                    style={{
                      width: "100px",
                      height: "100px",
                      objectFit: "cover",
                      borderRadius: 5,
                    }}
                    src={getImageSrc(scrapMaterialPreview)}
                  />
                </div>
                <div
                  className="flex-column justify-between"
                  style={{ width: "100%" }}
                >
                  <div className="flex-column" style={{ gap: 5 }}>
                    <div className="justify-between flex-row">
                      <h3 style={{ margin: 0, marginBottom: 8 }}>
                        {scrapMaterialPreview.scrapCategory.name}
                      </h3>
                      <a
                        href={
                          profile === "admin-bosch"
                            ? `/scrap-generators-details/${scrapMaterialPreview.originId}/scraps-form/${scrapMaterialPreview.id}`
                            : `/generator-scraps/${scrapMaterialPreview.originId}/generator-scraps-form/${scrapMaterialPreview.id}`
                        }
                        className="a-link-style"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t("label-details")}
                      </a>
                    </div>
                  </div>
                  <div className="flex-column" style={{ gap: 2 }}>
                    <h5 style={{ margin: 0 }}>
                      {t("material-contract-date")}:
                    </h5>
                    <p>{`${transformDateFormat(
                      scrapMaterialContract?.contractDate
                    )}  ${t("label-to").toLowerCase()}  ${transformDateFormat(
                      scrapMaterialContract?.expirationDocument.expirationDate
                    )}`}</p>
                  </div>
                  <div className="flex-row justify-between align-end">
                    <div className="flex-column" style={{ gap: 2 }}>
                      <h5 style={{ margin: 0 }}>
                        {t("label-responsible-shipping")}:
                      </h5>
                      <p>
                        {t(`label-${scrapMaterialPreview.responsibleShipping}`)}
                      </p>
                    </div>
                    <div className="flex-column align-end">
                      <div className="flex-row" style={{ gap: 5 }}>
                        <h3 style={{ margin: 0 }}>
                          {scrapMaterialContract?.contractValue || "xx"}
                        </h3>
                        <p>{`/${t(
                          `unityENUM.${scrapMaterialPreview.unity}`
                        )}`}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {!expirationMaterial && (
              <>
                {scrapMaterialPreview && (
                  <div
                    className="double-fields flex-row"
                    style={{ gap: "1rem" }}
                  >
                    <div className="flex-column">
                      <label htmlFor="label-net-weight">
                        {t("label-net-weight")}{" "}
                        {` (${t(`unityENUM.${scrapMaterialPreview.unity}`)})*`}
                      </label>
                      <input
                        disabled={!scrapMaterialPreview}
                        placeholder={t("label-net-weight")}
                        type="text"
                        id="label-net-weight"
                        {...register("quantity", {
                          required: true,
                        })}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const { value } = e.target;
                          const masked = applyDecimalMask(value);
                          setValue("quantity", masked);
                          setTotalValueDefault(
                            scrapMaterialContract?.contractValueUnity *
                              Number(masked.replace(/\./g, ""))
                          );
                        }}
                      />
                    </div>

                    <div className="flex-column">
                      <label htmlFor="material-contract-value">
                        {`${t("label-total-value")}*`}
                      </label>
                      <CurrencyInput
                        currency={countryConfigEntity?.currency}
                        locale={countryConfigEntity?.locale}
                        defaultValue={totalValueDefault}
                        onChangeValue={() => {}}
                        InputElement={
                          <input
                            disabled
                            placeholder={t("material-contract-value")}
                            type="text"
                            id="material-contract-value"
                            {...register("totalValue", {
                              required: false,
                            })}
                          />
                        }
                      />
                    </div>
                  </div>
                )}

                <br></br>
                <div
                  className="double-fields flex-row"
                  style={{ gap: "1rem", alignItems: "flex-start" }}
                >
                  <div className="flex-column">
                    <VehiclesInputAutocomplete
                      isDisabled={!scrapMaterialPreview}
                      scrapData={scrapMaterialPreview}
                      initialValue={item?.vehicle}
                      label={`${t("label-vehicle")}*`}
                      placeholder={t("label-vehicle")}
                      onChange={(item) => {
                        setValue("vehicle", item);
                        setVehicleAutocompleteError(false);
                      }}
                    />
                    {vehicleAutocompleteError && (
                      <span>{t("error-input-required")}</span>
                    )}
                  </div>

                  <div className="flex-column">
                    <DriversInputAutocomplete
                      isDisabled={!scrapMaterialPreview}
                      scrapData={scrapMaterialPreview}
                      initialValue={item?.driver}
                      label={`${t("label-driver")}*`}
                      placeholder={t("label-driver")}
                      onChange={(driver) => handleSetDriver(driver)}
                    />
                    {driverAutocompleteError && (
                      <span>{t("error-input-required")}</span>
                    )}

                    {driver && (
                      <div className="resume-material-collect flex-row margin-topbottom-1">
                        <div
                          style={{
                            width: "15px",

                            marginRight: "10px",
                            backgroundColor: getDriverStatus(
                              driver?.expirationDocument?.expirationDate ?? ""
                            ),
                          }}
                        ></div>
                        <div
                          className="flex-row justify-between"
                          style={{ width: "100%" }}
                        >
                          <div
                            className="flex-column"
                            style={{
                              gap: 5,
                              width: "250px",
                              alignItems: "flex-start",
                            }}
                          >
                            <div className="justify-between flex-row">
                              <h3
                                style={{
                                  margin: 0,
                                  marginBottom: 8,
                                  alignItems: "center",
                                }}
                              >
                                {driver?.name}
                              </h3>
                            </div>

                            <div
                              className="flex-row align-center"
                              style={{ gap: "5px" }}
                            >
                              <h5 style={{ margin: 0 }}>{t("label-rg")}:</h5>
                              <p>{driver?.rg}</p>
                            </div>

                            <div className="flex-column" style={{ gap: "5px" }}>
                              <h5 style={{ margin: 0 }}>
                                {t("label-autorizationDate")}:
                              </h5>
                              <p>
                                {transformDateFormat(
                                  driver?.autorizationDate?.toString() ||
                                    "xxxx-xx-xx"
                                )}{" "}
                                -{" "}
                                {transformDateFormat(
                                  driver?.expirationDocument?.expirationDate ||
                                    "xxxx-xx-xx"
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <br />
                {countryConfigEntity && (
                  <div className="flex-column">
                    <div
                      className="flex-row double-fields"
                      style={{ alignItems: "center" }}
                    >
                      <div
                        className="flex-column"
                        style={{ justifyContent: "center" }}
                      >
                        <ScrapStorageInputAutocomplete
                          countryConfigEntity={countryConfigEntity}
                          label={t("label-storage-transportation")}
                          onChange={(item) => {
                            setSelectedContainerType(item);
                            setValue("containerType", item);
                          }}
                          placeholder={t("material-container-type")}
                          initialValue={item?.containerType}
                        />
                        {errors.containerType && (
                          <span>{t("error-input-required")}</span>
                        )}
                      </div>

                      <div className="flex-column">
                        <label htmlFor="label-responsibleName">
                          {`${t("label-responsibleName")}*`}
                        </label>
                        <input
                          disabled={!scrapMaterialPreview}
                          placeholder={t("label-responsibleName")}
                          type="text"
                          id="label-responsibleName"
                          {...register("responsibleName", {
                            required: true,
                          })}
                        />
                        {errors.responsibleName && (
                          <span>{t("error-input-required")}</span>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                <div className="flex-column">
                  <label htmlFor="label-observations">
                    {t("label-observations")}
                  </label>
                  <input
                    disabled={!scrapMaterialPreview}
                    placeholder={t("label-observations")}
                    type="text"
                    id="label-observations"
                    {...register("observations", {
                      required: false,
                    })}
                  />
                </div>
              </>
            )}
          </CollapsibleSection>

          {expirationMaterial && (
            <div
              className="flex-column align-center justify-center"
              style={{
                padding: "1rem",
                color: "var(--bosch-warning-yellow-dark)",
                backgroundColor: "var(--bosch-warning-yellow-opacity10)",
                border: "solid 2px var(--bosch-warning-yellow-dark)",
                borderRadius: "5px",
              }}
            >
              <img
                src={alertIconImg}
                alt="alert_icon"
                style={{ width: "20%" }}
              />
              <h3 style={{ textAlign: "center" }}>
                {t("label-contract-scrap-expired")}
              </h3>
            </div>
          )}

          {!expirationMaterial && (
            <>
              <CollapsibleSection
                title={t("label-invoice")}
                titleFontSize="18px"
              >
                {scrapDealerDetails && (
                  <div className="flex-column legal-informations margin-topbottom-1">
                    <p>
                      {t("label-scrap-dealer")}:{" "}
                      {scrapDealerDetails?.scrapDealerInfo?.legalName}
                    </p>
                    <p>
                      {
                        scrapDealerDetails?.scrapDealerInfo
                          ?.nationalRegisterLabel
                      }
                      : {scrapDealerDetails?.scrapDealerInfo?.nationalRegister}
                    </p>
                    <p>
                      {scrapDealerDetails?.addressInfo?.postalCodeLabel}:{" "}
                      {scrapDealerDetails?.addressInfo?.postalCode}
                    </p>
                    <p>{t("label-address")}:</p>
                    <p>
                      {scrapDealerDetails?.addressInfo?.address},{" "}
                      {scrapDealerDetails?.addressInfo?.addressNumber}
                    </p>
                    <p>
                      {scrapDealerDetails?.addressInfo?.city},{" "}
                      {scrapDealerDetails?.addressInfo?.state} -{" "}
                      {scrapDealerDetails?.addressInfo?.countryName}
                    </p>
                  </div>
                )}

                <div className="flex-column margin-topbottom-1">
                  <div className="flex-row justify-between">
                    <label>{t("label-invoice")}</label>
                  </div>
                  <input
                    style={{ width: "96.5%" }}
                    type="file"
                    multiple
                    onChange={handleFileChange}
                  />

                  {selectedFiles?.length > 0 && (
                    <div>
                      {selectedFiles?.map((file: File, index: number) => (
                        <label key={index}>{file.name || ""}</label>
                      ))}
                    </div>
                  )}
                  {watchedInvoiceAttachment &&
                    watchedInvoiceAttachment.map(
                      (url: string, index: number) => (
                        <SparkTableBody key={index}>
                          <SparkTableRow
                            onClick={() => window.open(url, "_blank")}
                          >
                            <SparkTableCell
                              style={{ color: "var(--bosch-accent-blue)" }}
                            >
                              {extractFileNameFromUrl(url)}
                            </SparkTableCell>
                            <SparkTableCell
                              style={{ justifyContent: "end" }}
                              className="icon-column flex-row"
                            >
                              <SparkIcon
                                pallete="tertiary"
                                className="icon-flag"
                                no-padding={true}
                                icName={"download"}
                              />
                              <p style={{ color: "transparent" }}>-----</p>
                            </SparkTableCell>
                          </SparkTableRow>
                        </SparkTableBody>
                      )
                    )}
                </div>
              </CollapsibleSection>

              <CollapsibleSection
                title={t("Label-Weighing-Ticket")}
                titleFontSize="18px"
              >
                <div className="flex-column margin-topbottom-1">
                  <div
                    className="double-fields flex-row"
                    style={{ gap: "1rem" }}
                  >
                    <div className="flex-column">
                      <label htmlFor="label-initial-weighing">
                        {t("label-initial-weighing")}{" "}
                        {scrapMaterialPreview?.unity
                          ? `(${t(`unityENUM.${scrapMaterialPreview.unity}`)})`
                          : ""}
                      </label>
                      <input
                        disabled={!scrapMaterialPreview}
                        placeholder={t("label-initial-weighing")}
                        type="text"
                        id="label-initial-weighing"
                        {...register("initialWeighing")}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const { value } = e.target;
                          const masked = applyDecimalMask(value);
                          setValue("initialWeighing", masked || "");
                        }}
                      />
                    </div>

                    <div className="flex-column">
                      <label htmlFor="label-tara">
                        {t("Tara ")}{" "}
                        {scrapMaterialPreview?.unity
                          ? `(${t(`unityENUM.${scrapMaterialPreview.unity}`)})`
                          : ""}
                      </label>
                      <input
                        disabled={!scrapMaterialPreview}
                        placeholder={t("Tara ")}
                        type="text"
                        id="label-tara"
                        {...register("tara")}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const { value } = e.target;
                          const masked = applyDecimalMask(value);
                          setValue("tara", masked || "");
                        }}
                      />
                    </div>
                  </div>

                  <div className="flex-row justify-between">
                    <label>{t("label-documents")}</label>
                  </div>
                  <input
                    style={{ width: "96.5%" }}
                    type="file"
                    multiple
                    onChange={handleticketChange}
                  />
                  {SelectedTicketFiles?.length > 0 && (
                    <div>
                      {SelectedTicketFiles?.map((file: File, index: number) => (
                        <label key={index}>{file.name || ""}</label>
                      ))}
                    </div>
                  )}
                  {watchedWeighingTicketAttachment &&
                    watchedWeighingTicketAttachment.map(
                      (url: string, index: number) => (
                        <SparkTableBody key={index}>
                          <SparkTableRow
                            onClick={() => window.open(url, "_blank")}
                          >
                            <SparkTableCell
                              style={{ color: "var(--bosch-accent-blue)" }}
                            >
                              {extractFileNameFromUrl(url)}
                            </SparkTableCell>
                            <SparkTableCell
                              style={{ justifyContent: "end" }}
                              className="icon-column flex-row"
                            >
                              <SparkIcon
                                pallete="tertiary"
                                className="icon-flag"
                                no-padding={true}
                                icName={"download"}
                              />
                              <p style={{ color: "transparent" }}>-----</p>
                            </SparkTableCell>
                          </SparkTableRow>
                        </SparkTableBody>
                      )
                    )}
                </div>
              </CollapsibleSection>

              <CollapsibleSection title={"MTR"} titleFontSize="18px">
                <div className="flex-row align-center">
                  <label style={{ gap: 5 }}>
                    <input
                      disabled={watchedAttachmentMTR}
                      type="checkbox"
                      checked={automaticMTR}
                      onChange={() => {
                        setAutomaticMTR((prev) => !prev);
                      }}
                    />
                    <h4 style={{ margin: 0 }}>{t("label-automatic-mtr")}</h4>
                  </label>
                </div>

                <div className="flex-column ">
                  {!automaticMTR && (
                    <>
                    <br/>
                    <div className="flex-column">
                        <label htmlFor="label-mtrCode">
                          {`${t("label-mtrCode")}*`}
                        </label>
                        <input
                          disabled={!scrapMaterialPreview}
                          placeholder={t("label-mtrCode")}
                          type="text"
                          id="label-mtrCode"
                          {...register("mtrNumber", {
                            required: true,
                          })}
                        />
                        {errors.responsibleName && (
                          <span>{t("error-input-required")}</span>
                        )}
                      </div>
                    <br/>
                      <div className="flex-row justify-between">
                      <label htmlFor="label-generatedMTR">
                          {`${t("label-generatedMTR")}`}
                        </label>
                      </div>
                      
                      <input
                        style={{ width: "96.5%" }}
                        type="file"
                        multiple
                        onChange={handleMTRFileChange}
                      />
                      {selectedMTRFiles?.length > 0 && (
                        <div>
                          {selectedMTRFiles?.map((file, index) => (
                            <label key={index}>{file.name || ""}</label>
                          ))}
                        </div>
                      )}
                      
                      

                    </>
                    
                  )}
                  {watchedAttachmentMTR &&
                    watchedAttachmentMTR.map((url: string, index: number) => (
                      <SparkTableBody key={index}>
                        <SparkTableRow
                          onClick={() => window.open(url, "_blank")}
                        >
                          <SparkTableCell
                            style={{ color: "var(--bosch-accent-blue)" }}
                          >
                            {extractFileNameFromUrl(url)}
                          </SparkTableCell>
                          <SparkTableCell
                            style={{ justifyContent: "end" }}
                            className="icon-column flex-row"
                          >
                            <SparkIcon
                              pallete="tertiary"
                              className="icon-flag"
                              no-padding={true}
                              icName={"download"}
                            />
                            <p style={{ color: "transparent" }}>-----</p>
                          </SparkTableCell>
                        </SparkTableRow>
                      </SparkTableBody>
                    ))}
                </div>
                
              </CollapsibleSection>

              <CollapsibleSection
                title={t("label-documents-summary")}
                titleFontSize="18px"
              >
                <div className="info-section">
                  <label>{t("label-Transport Documents")}</label>
                  <SparkTable>
                    <SparkTableHead>
                      <SparkTableRow>
                        <SparkTableCell>#</SparkTableCell>
                        <SparkTableCell>{t("label-number")}</SparkTableCell>
                        <SparkTableCell>{t("label-documents")}</SparkTableCell>
                      </SparkTableRow>
                    </SparkTableHead>
                    <SparkTableBody>
                      <SparkTableRow>
                        <SparkTableCell>MTR</SparkTableCell>
                        <SparkTableCell>{"-"}</SparkTableCell>
                        <SparkTableCell style={{ justifyContent: "center" }}>
                          {watchedAttachmentMTR?.length > 0
                            ? watchedAttachmentMTR.map(
                                (url: string, index: number) => (
                                  <span
                                    key={index}
                                    onClick={() => window.open(url, "_blank")}
                                    style={{
                                      cursor: "pointer",
                                      color: "var(--bosch-accent-blue)",
                                      textDecoration: "none",
                                    }}
                                  >
                                    {extractFileNameFromUrl(url)}
                                  </span>
                                )
                              )
                            : "-"}
                        </SparkTableCell>
                      </SparkTableRow>

                      <SparkTableRow>
                        <SparkTableCell>NF</SparkTableCell>
                        <SparkTableCell>{"-"}</SparkTableCell>
                        <SparkTableCell>
                          {watchedInvoiceAttachment?.length > 0
                            ? watchedInvoiceAttachment.map(
                                (url: string, index: number) => (
                                  <span
                                    key={index}
                                    onClick={() => window.open(url, "_blank")}
                                    style={{
                                      cursor: "pointer",
                                      color: "var(--bosch-accent-blue)",
                                      textDecoration: "none",
                                    }}
                                  >
                                    {extractFileNameFromUrl(url)}
                                  </span>
                                )
                              )
                            : "-"}
                        </SparkTableCell>
                      </SparkTableRow>

                      <SparkTableRow>
                        <SparkTableCell>CDF</SparkTableCell>
                        <SparkTableCell>{"-"}</SparkTableCell>
                        <SparkTableCell>
                          {CdfCollect?.attachments &&
                          CdfCollect.attachments.length > 0
                            ? CdfCollect.attachments.map(
                                (url: string, index: number) => (
                                  <span
                                    key={index}
                                    onClick={() => window.open(url, "_blank")}
                                    style={{
                                      cursor: "pointer",
                                      color: "var(--bosch-accent-blue)",
                                      textDecoration: "none",
                                    }}
                                  >
                                    {extractFileNameFromUrl(url)}
                                  </span>
                                )
                              )
                            : "-"}
                        </SparkTableCell>
                      </SparkTableRow>
                    </SparkTableBody>
                  </SparkTable>
                </div>
                {isHazardousMaterial && (
                  <div className="info-section">
                    <label>{t("Label-Document-of-Hazardous-Waste")}</label>
                    <SparkTable>
                      <SparkTableHead>
                        <SparkTableRow>
                          <SparkTableCell>#</SparkTableCell>
                          <SparkTableCell>{t("label-number")}</SparkTableCell>
                          <SparkTableCell>
                            {t("label-documents")}
                          </SparkTableCell>
                          <SparkTableCell style={{ textAlign: "center" }}>
                            {t("label-expiration")}
                          </SparkTableCell>
                        </SparkTableRow>
                      </SparkTableHead>
                      <SparkTableBody>
                        <SparkTableRow>
                          <SparkTableCell>FDSR</SparkTableCell>
                          <SparkTableCell>{"-"}</SparkTableCell>
                          <SparkTableCell style={{ justifyContent: "center" }}>
                            {"-"}
                          </SparkTableCell>
                          <SparkTableCell style={{ justifyContent: "center" }}>
                            {" "}
                            {"-"}{" "}
                          </SparkTableCell>
                        </SparkTableRow>

                        <SparkTableRow>
                          <SparkTableCell>CADRI/AA</SparkTableCell>
                          <SparkTableCell>{"-"}</SparkTableCell>
                          <SparkTableCell>{"-"}</SparkTableCell>
                          <SparkTableCell style={{ justifyContent: "center" }}>
                            {" "}
                            {"-"}{" "}
                          </SparkTableCell>
                        </SparkTableRow>
                      </SparkTableBody>
                    </SparkTable>
                  </div>
                )}
              </CollapsibleSection>

              {item?.hasCDF && (
                <CollapsibleSection title={"CDF"} titleFontSize="18px">
                  <div className="flex-column margin-topbottom-1">
                    <label htmlFor="label-cdf-number">
                      {t("label-cdf-number")}
                    </label>
                    <input
                      disabled
                      value={CdfCollect?.cdfNumber}
                      placeholder={t("label-cdf-number")}
                      type="text"
                      id="label-cdf-number"
                    />
                  </div>
                  <div className="flex-column margin-topbottom-1">
                    <label htmlFor="label-received-indicated-quantity">
                      {t("label-received-indicated-quantity")}
                    </label>
                    <input
                      disabled
                      value={applyDecimalMask(`${CdfCollect?.receivedQtd}`)}
                      placeholder={t("label-received-indicated-quantity")}
                      type="text"
                      id="label-received-indicated-quantity"
                    />
                  </div>
                  <div className="flex-column margin-topbottom-1">
                    {CdfCollect?.attachments &&
                      CdfCollect.attachments.map(
                        (url: string, index: number) => (
                          <SparkTableBody key={index}>
                            <SparkTableRow
                              onClick={() => window.open(url, "_blank")}
                            >
                              <SparkTableCell
                                style={{ color: "var(--bosch-accent-blue)" }}
                              >
                                {extractFileNameFromUrl(url)}
                              </SparkTableCell>
                              <SparkTableCell
                                style={{ justifyContent: "end" }}
                                className="icon-column flex-row"
                              >
                                <SparkIcon
                                  pallete="tertiary"
                                  className="icon-flag"
                                  no-padding={true}
                                  icName={"download"}
                                />
                                <p style={{ color: "transparent" }}>-----</p>
                              </SparkTableCell>
                            </SparkTableRow>
                          </SparkTableBody>
                        )
                      )}
                  </div>
                </CollapsibleSection>
              )}
            </>
          )}

          <div
            className="flex-row justify-end"
            style={{ gap: "10px", marginTop: "1.5rem" }}
          >
            <button onClick={onClose} style={{ margin: 0 }}>
              {t("label-cancel")}
            </button>
            <button
              disabled={expirationMaterial}
              className="blue"
              type="submit"
              style={{ margin: 0 }}
            >
              {t("label-save")}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default NewCollectModal;
