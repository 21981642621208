import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { t } from "i18next";
import ChartEmptyImg from "./../../assets/img/chart-pie.svg";
import { CategoryTotalDTO } from "../../pages/scraps/scrap-category/models/scrap-category-total-dto";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface Props {
  data: CategoryTotalDTO | null;
  showLegend?: boolean;
}

const defaultColors = [
  "rgba(75, 192, 192, 1)",
  "rgba(255, 99, 132, 1)",
  "rgba(54, 162, 235, 1)",
  "rgba(255, 206, 86, 1)",
  "rgba(153, 102, 255, 1)",
  "rgba(255, 159, 64, 1)",
  "rgba(201, 203, 207, 1)",
];

const getBorderColor = (rgba: string, opacity: number) => {
  const rgbaParts = rgba.match(
    /rgba?\((\d+), (\d+), (\d+)(?:, (\d+\.?\d*))?\)/
  );
  if (rgbaParts) {
    return `rgba(${rgbaParts[1]}, ${rgbaParts[2]}, ${rgbaParts[3]}, ${opacity})`;
  }
  return rgba;
};

const MultiCountryAverageChart: React.FC<Props> = ({ data, showLegend }) => {
  if (!data) {
    return (
      <div className="chart-mock" style={{ minWidth: "300px", width: "100%" }}>
        <img src={ChartEmptyImg} alt="chat-empty" />
      </div>
    );
  }

  const years = data.years;
  const countryMap = years.reduce((acc, yearData) => {
    yearData.countries.forEach((countryData) => {
      acc[countryData.country] = countryData.countryName;
    });
    return acc;
  }, {} as { [key: string]: string });

  const selectedCountries = Object.keys(countryMap);

  const countryYearMap = selectedCountries.reduce((acc, country) => {
    acc[country] = years.map((yearData) => {
      const countryData = yearData.countries.find((c) => c.country === country);
      return {
        year: yearData.year,
        averagePrice: countryData ? countryData.averagePrice : 0,
      };
    });
    return acc;
  }, {} as { [key: string]: { year: number; averagePrice: number }[] });

  const labels = years.map((yearData) => yearData.year);
  const datasets = selectedCountries.map((country, index) => {
    const prices = countryYearMap[country].map(
      (yearData) => yearData.averagePrice
    );
    const color = defaultColors[index % defaultColors.length];
    const countryName = countryMap[country] || country;

    return {
      label: countryName,
      data: prices,
      borderColor: color,
      backgroundColor: getBorderColor(color, 0.2),
      borderWidth: 2,
    };
  });

  const chartData = {
    labels: labels,
    datasets: datasets,
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: showLegend || false,
        position: "bottom" as const,
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem: any) {
            return `${t("label-value")}: ${tooltipItem.raw}`;
          },
        },
      },
    },
  };

  return (
    <div style={{ padding: "10px", height: "80%", width: "100%" }}>
      <Line data={chartData} options={options} />
    </div>
  );
};

export default MultiCountryAverageChart;
