import { useTranslation } from "react-i18next";
import addIcon from "../../../../assets/svgs/add.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import { CountryConfigDTO } from "./country-config-DTO";
import {  getCountryConfigs } from "./country-config-service";
import {
  SparkTable,
  SparkTableBody,
  SparkTableCell,
  SparkTableHead,
  SparkTableRow,
} from "@bosch-web-dds/spark-ui-react";
import CountryConfigForm from "./country-config-form/country-config-form";
import { useAuth } from "../../../../contexts/auth-context";

const CountryConfig: React.FC<{ enabledUsers: string[] }> = ({ enabledUsers }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userDoc } = useAuth();
  const profile = userDoc?.profile;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [countryConfig, setCountryConfig] = useState<CountryConfigDTO[]>(
    []
  );
  const { id } = useParams<{ id: string }>();
  const [tableData, setTableData] = useState<any>([]);
  const tableHeader = [
    `${t("label-country")}`,
  ];

  const fetchCountryConfig = async () => {
    if (id) {
      try {
        const data = await getCountryConfigs();
        setCountryConfig(data);
        setTableData(data);
      } catch (error) {
        console.error("Error fetching scrap categories: ", error);
      }
    }
  };
  const [requirementId, setRequirementID] = useState(null || "");

  useEffect(() => {
    fetchCountryConfig();
  }, [id, setCountryConfig]);

  if (enabledUsers.includes(profile || "public")) {
    return (
      <div>
        <div className="page">
          <div className="flex-row">
            <button className="blue-with-icon button" onClick={() => {
              navigate("/country-config-form");
            }}
            >
              <img src={addIcon} alt="add icon" />
              <p>{t("label-add-new")}</p>
            </button>
          </div>
          <br />
          <div className="info-section">
        <SparkTable>
            <SparkTableHead className="flex-row" style={{ alignItens: "center" }}>
              <SparkTableRow>
                {tableHeader.map((header, index) => (
                  <SparkTableCell style={{ alignItens: "center" }} key={index}>
                    <strong>{header.toUpperCase()}</strong>
                  </SparkTableCell>
                ))}
              </SparkTableRow>
            </SparkTableHead>
            <SparkTableBody>
              {tableData.map((rowItem: any, rowIndex: any) => (
                <SparkTableRow
                  onClick={() => {
                    setRequirementID(rowItem.id);

                    navigate(`${rowItem.id}/edit`);

                  }}
                  key={rowIndex}
                >
                  <SparkTableCell>{rowItem.country[0]}</SparkTableCell>
                </SparkTableRow>
              ))}
            </SparkTableBody>
          </SparkTable>
        </div>
      </div>
    </div>);
  } else {
    navigate("/page-not-found")
    return <></>
  }
};

export default CountryConfig;
