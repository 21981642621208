import { t } from "i18next";

interface GenericConfirmationModalProps {
  children?: React.ReactNode;
  title?: string;
  confirmButtonLabel?: string;
  cancelButtonLabel?: string;
  onConfirm: () => void;
  onClose?: () => void;
}

const GenericConfirmationModal: React.FC<GenericConfirmationModalProps> = ({
  children,
  title,
  confirmButtonLabel,
  cancelButtonLabel,
  onConfirm,
  onClose,
}) => {
  return (
    <div
      className="modal-content"
      style={{ gap: "20px", padding: "2rem 1rem" }}
    >
      <h2>{title ? title : t("generic-confirmation-title")}</h2>
      {children}
      <div
        className="flex-row justify-end"
        style={{ gap: "10px", marginTop: "0.5rem" }}
      >
       {onClose && (
        <button onClick={onClose}>{cancelButtonLabel ? cancelButtonLabel : t("label-cancel")}</button>
       )} 
        <button className="blue" onClick={onConfirm}>
          {confirmButtonLabel ? confirmButtonLabel : t("label-confirm")}
        </button>
      </div>
    </div>
  );
};

export default GenericConfirmationModal;
