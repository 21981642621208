import {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { firestore } from "../../utils/firebase-config";
import {
  AddressInfoDTO,
  BankAccountInfoDTO,
  ObservationsDTO,
  ScrapGeneratorDTO,
  ScrapGeneratorInfoDTO,
} from "./scrap-generators-DTO";
import { COLLECTIONS } from "../../assets/enums/firebase-colections";
import {
  callFunction,
  saveNewAsync,
  saveNewWithId,
  update,
} from "../../services/persistence";
import { ContactInfoDTO } from "../contacts/contacts-DTO";
import { SuccessFeeDTO } from "./components/scrap-generator-details/components/success-fee/success-fee-DTO";

// add new scrapGenerator
export async function addNewScrapGenerator(data: ScrapGeneratorDTO) {
  data.scrapGeneratorInfo.region = `${data.addressInfo.city} - ${data.addressInfo.state}`;
  if (data.id == undefined) {
    saveNewAsync(COLLECTIONS.SCRAP_GENERATORS, data.scrapGeneratorInfo).then(
      (docRef) => {
        data.contactInfo.type = "Principal";
        data.contactInfo.originId = docRef.id;
        saveNewWithId(COLLECTIONS.ADDRESS, data.addressInfo, docRef.id);
        saveNewWithId(COLLECTIONS.BANK_INFO, data.bankAccountInfo, docRef.id);
        saveNewWithId(
          COLLECTIONS.CONTACTS,
          data.contactInfo,
          docRef.id + "contact"
        );
        saveNewWithId(COLLECTIONS.OBSERVATIONS, data.observations, docRef.id);
      }
    );
  } else {
    update(COLLECTIONS.SCRAP_GENERATORS, data.scrapGeneratorInfo, data.id);
    update(COLLECTIONS.ADDRESS, data.addressInfo, data.id);
    update(COLLECTIONS.BANK_INFO, data.bankAccountInfo, data.id);
    update(COLLECTIONS.CONTACTS, data.contactInfo, data.id + "contact");
    update(COLLECTIONS.OBSERVATIONS, data.observations, data.id);

    if (data.scrapGeneratorInfo.isHeadquarter) {
      callFunction(
        "updateheadquarternamegenerator",
        { id: data.id, name: data.scrapGeneratorInfo.tradingName },
        data.id
      );
    }
  }
}

// get all branchs of scrapGenerators by id
export async function getBranchsScrapGeneratorsById(
  scrapGeneratorId: string
): Promise<ScrapGeneratorInfoDTO[]> {
  const scrapGeneratorsCollectionRef = collection(
    firestore,
    COLLECTIONS.SCRAP_GENERATORS
  );
  const q = query(
    scrapGeneratorsCollectionRef,
    where("isHeadquarter", "==", false),
    where("headquarterId", "==", scrapGeneratorId)
  );

  const querySnapshot = await getDocs(q);

  const items = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
    searchData: doc
    .data()
    ["legalName"].concat(
      " ",
      doc.data()["nationalRegister"],
      " ",
      doc.data()["tradingName"],
      " ",
      doc.data()["region"]
    ),
  })) as unknown as ScrapGeneratorInfoDTO[];
  return items;
}

// get all scrapGenerators
export async function getScrapGenerators(): Promise<ScrapGeneratorInfoDTO[]> {
  const collectionRef = collection(firestore, COLLECTIONS.SCRAP_GENERATORS);
  const q = query(
    collectionRef,
    orderBy("legalName")
  );

  const querySnapshot = await getDocs(
   q
  );
  const items = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
    searchData: doc
      .data()
      ["legalName"].concat(
        " ",
        doc.data()["nationalRegister"],
        " ",
        doc.data()["tradingName"],
        " ",
        doc.data()["region"]
      ),
  })) as unknown as ScrapGeneratorInfoDTO[];
  return items;
}

// get scrapGenerator details
export const getScrapGeneratorById = async (
  id: string
): Promise<ScrapGeneratorDTO | null> => {
  const scrapGeneratorInfo = await getScrapGeneratorInfo(id);
  const contactInfo = await getScrapGeneratorContact(id);
  const addressInfo = await getScrapGeneratorAddress(id);
  const bankAccountInfo = await getScrapGeneratorBank(id);
  const observations = await getScrapGeneratorObservations(id);

  return {
    id,
    scrapGeneratorInfo,
    addressInfo,
    bankAccountInfo,
    contactInfo,
    observations,
  };
};

export async function getScrapGeneratorInfo(
  id: string
): Promise<ScrapGeneratorInfoDTO> {
  const docRef = doc(firestore, COLLECTIONS.SCRAP_GENERATORS, id);
  const docSnap = await getDoc(docRef);

  return docSnap.data() as ScrapGeneratorInfoDTO;
}

export async function getScrapGeneratorAddress(
  id: string
): Promise<AddressInfoDTO> {
  const docRef = doc(firestore, COLLECTIONS.ADDRESS, id);
  const docSnap = await getDoc(docRef);

  return docSnap.data() as AddressInfoDTO;
}

export async function getScrapGeneratorBank(
  id: string
): Promise<BankAccountInfoDTO> {
  const docRef = doc(firestore, COLLECTIONS.BANK_INFO, id);
  const docSnap = await getDoc(docRef);

  return docSnap.data() as BankAccountInfoDTO;
}

export async function getScrapGeneratorContact(
  id: string
): Promise<ContactInfoDTO> {
  const docRef = doc(firestore, COLLECTIONS.CONTACTS, id + "contact");
  const docSnap = await getDoc(docRef);

  return docSnap.data() as ContactInfoDTO;
}

export async function getScrapGeneratorObservations(
  id: string
): Promise<ObservationsDTO> {
  const docRef = doc(firestore, COLLECTIONS.OBSERVATIONS, id);
  const docSnap = await getDoc(docRef);

  return docSnap.data() as ObservationsDTO;
}

export async function getSuccessFee(
  id: string
): Promise<SuccessFeeDTO> {
  const docRef = doc(firestore, COLLECTIONS.FEE, id + "fee");
  const docSnap = await getDoc(docRef);

  return docSnap.data() as SuccessFeeDTO;
}

// add new
export async function addNewSuccessFee(data: SuccessFeeDTO, id: string) {
  data.value = Number(data.value.toString().replace(/\./g, ""));
  if (data.id == undefined) {
    data.originId = id;
    saveNewWithId(COLLECTIONS.FEE, data, id + "fee");
  } else {
    update(COLLECTIONS.FEE, data, data.id);
  }
}
