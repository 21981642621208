import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { signIn, signInLinkToEmail } from "../../services/auth";
import ButtonForgetPassword from "./components/button-forget-password/button-forget-password";
import ButtonLoginWithoutPassword from "./components/button-login-without-password/button-login-without-password";
import { useTranslation } from "react-i18next";
import { useAlert } from "../../contexts/alert-context";
import { useAuth } from "../../contexts/auth-context";

interface FormData {
  email: string;
  password: string;
}

const Login = () => {
  const [formData, setFormData] = useState<FormData>({
    email: "",
    password: "",
  });
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setAlert } = useAlert();
  const { userDoc } = useAuth();
  const profile = userDoc?.profile;
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (formData.email === "" || formData.password === "") {
      setAlert({ text: `${t("alert-error-invalid-login")}`, type: "error" });
    } else {
      const result = await signIn(formData.email, formData.password);
      if (result) {
        navigate("/dashboard");
      } else {
        setAlert({ text: `${t("alert-error-invalid-login")}`, type: "error" });
      }
    }
  };

  return (
    <section className="login-section">
      <div className="form-side">
        <form onSubmit={handleSubmit}>
          <h2>Login</h2>
          <input
            name="email"
            value={formData.email}
            type="email"
            placeholder={t("label-email")}
            onChange={handleInputChange}
          />
          <input
            name="password"
            value={formData.password}
            type="password"
            placeholder={t("label-password")}
            onChange={handleInputChange}
          />

          <div
            className="forget-password-button flex-row align-center justify-end"
            style={{ width: "100%", marginBottom: "10px" }}
          >
            <ButtonForgetPassword />
          </div>

          <button
            type="submit"
            className="primary"
            style={{ width: "100%", margin: 0 }}
          >
            {t("label-enter")}
          </button>
          <div className="button-login-non-password">
            <ButtonLoginWithoutPassword />
          </div>
          <p>
            {t("for-user-creation")}
            <a
              href={`mailto:better.scrap-4-@br.bosch.com?subject=${encodeURIComponent(
                t("mail.subject")
              )}&body=${encodeURIComponent(t("mail.body"))}`}
            >
              {t("our-team")}
            </a>
            .
          </p>
        </form>
      </div>
    </section>
  );
};

export default Login;
