export const StatusEnum = {
  TO_DEAL: "TO_DEAL",
  AWAITING_BID: "AWAITING_BID",
  IN_BID: "IN_BID",
  BID_COMPLETED: "BID_COMPLETED",
  CANCELED: "CANCELED",
  FINISHED: "FINISHED",
  CONTRACTED: "CONTRACTED",
  OPERATING: "OPERATING",
  DISAPPROVED: "DISAPPROVED",
};
