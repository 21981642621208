import {
    collection,
    doc,
    getDocs,
    query,
    where,
    setDoc,
    updateDoc,
    getDoc,
} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { firestore } from "../../../../../../utils/firebase-config"; 
import { COLLECTIONS } from "../../../../../../assets/enums/firebase-colections";
import { ApproveRollDTO } from "./scrap-generator-approve-rollDTO";


const storage = getStorage();

async function uploadFile(file: File): Promise<string> {
    const storageRef = ref(storage, `approvers/${file.name}`);
    await uploadBytes(storageRef, file);
    const url = await getDownloadURL(storageRef);
    return url;
}

export async function addNewApprover(data: ApproveRollDTO, files: File[]): Promise<boolean> {
    try {
        
        const fileUrls = await Promise.all(files.map(file => uploadFile(file)));

        const dataWithFiles = { ...data, fileUrls };

  
        const docRef = doc(collection(firestore, COLLECTIONS.APPROVE_ROLL));
        await setDoc(docRef, dataWithFiles);
        return true;
    } catch (error) {
        console.error("Error adding new approver: ", error);
        return false;
    }
}

export async function updateApprover(data: ApproveRollDTO, id: string, files?: File[]): Promise<boolean> {
    try {
        let fileUrls = data.fileUrls || [];

        if (files && files.length > 0) {
        
            const newFileUrls = await Promise.all(files.map(file => uploadFile(file)));
            fileUrls = [...fileUrls, ...newFileUrls];
        }


        const dataWithFiles = { ...data, fileUrls };

        const docRef = doc(firestore, COLLECTIONS.APPROVE_ROLL, id);
        await updateDoc(docRef, dataWithFiles);
        return true;
    } catch (error) {
        console.error("Error updating approver: ", error);
        return false;
    }
}

export async function getAllApprovers(id?: string): Promise<ApproveRollDTO[]> {
    try {
        const colRef = collection(firestore, COLLECTIONS.APPROVE_ROLL);
        let q;
        if (id) {
            q = query(colRef, where("user.originId", "==", id));
        } else {
            q = colRef;
        }
        const querySnapshot = await getDocs(q);
        const approvers: ApproveRollDTO[] = [];
        querySnapshot.forEach((doc) => {
            approvers.push({ ...doc.data(), id: doc.id } as ApproveRollDTO);
        });

        return approvers;
    } catch (error) {
        console.error("Error fetching approvers: ", error);
        return [];
    }
}
