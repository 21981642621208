import "../scrap.css";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import addIcon from "../../../assets/svgs/add.svg";
import { useNavigate } from "react-router-dom";
import ImportExportCsv from "../../../components/ImportExportCsv/ImportExportCsv";
import { getScrapCategoriesApproved, getScrapGroups } from "../scraps-service";
import CardListScrapCategories from "./cardlist-scrap-categories/cardlist-scrap-groups";
import { useAuth } from "../../../contexts/auth-context";
import FilterComponent from "../../../components/FIlter/filter";

import React from "react";
import ScrapGroupForm from "./scrap-group-form/scrap-group-form";
import CardListScrapGroups from "./cardlist-scrap-categories/cardlist-scrap-groups";
import { ScrapsCategoryGroupDTO } from "../scrap-category/models/scrap-category-DTO";

const ScrapGroups: React.FC<{ enabledUsers: string[] }> = ({
  enabledUsers,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userDoc } = useAuth();
  const profile = userDoc?.profile;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [scrapCategories, setScrapCategories] = useState<
    ScrapsCategoryGroupDTO[]
  >([]);
  const [filteredData, setFilteredData] = useState<ScrapsCategoryGroupDTO[]>([]);


  const handleFilterClick = (filteredData: ScrapsCategoryGroupDTO[]) => {
    setFilteredData(filteredData);
  };

  const handleSortClick = (
    sortFunction: (data: ScrapsCategoryGroupDTO[]) => ScrapsCategoryGroupDTO[]
  ) => {
    const sortedData = sortFunction([...filteredData]);
    setFilteredData(sortedData);
  };

  const fetchScrapCategories = async () => {
    try {
      const data = await getScrapGroups();
      setScrapCategories(data);
      setFilteredData(data);
    } catch (error) {
      console.error("Error fetching scrap categories: ", error);
    }
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    fetchScrapCategories(); // Atualizar a lista de contatos ao fechar o modal
  };

  useEffect(() => {
    fetchScrapCategories();
  }, []);

  if (enabledUsers.includes(profile || "public")) {
    return (
      <div>
        {isModalOpen && (
          <div className="modal-background">
            <ScrapGroupForm contactId="" onClose={handleCloseModal} />
          </div>
        )}
        <div className="page">
          <div className="flex-row">
            <button className="blue-with-icon button" onClick={handleOpenModal}>
              <img src={addIcon} alt="add icon" />
              <p>{t("label-add-new")}</p>
            </button>
            <ImportExportCsv
              exportApi="exportscrapbygeneratorscsv"
              importApi=""
              onClose={handleCloseModal}
            />
            <FilterComponent
              data={scrapCategories}
              onFilterClick={handleFilterClick}
             
            />
          </div>
          <CardListScrapGroups scrapCategories={filteredData} />
        </div>
      </div>
    );
  } else {
    navigate("/page-not-found");
    return <></>;
  }
};

export default ScrapGroups;
