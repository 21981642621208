import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/auth-context";
import DashBoardAdmin from "../dashboard-admin/dashboard-admin";
import DashBoardGenerator from "../dashboard-generator/dashboard-generator";

const Dashboard: React.FC<{ enabledUsers: string[] }> = ({ enabledUsers }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userDoc, loading } = useAuth();
  const profile = userDoc?.profile;

  if(loading) return null

  if (enabledUsers.includes(profile || "public")) {
    return (
      <div className="page">

        {profile === "admin-bosch" && (
          <DashBoardAdmin
            enabledUsers={["admin-bosch"]}
          />
        )}

        {profile === "scrap-generator" && (
          <DashBoardGenerator enabledUsers={["scrap-generator"]} />
        )}
      </div>
    );
  } else {
    navigate("/page-not-found");
    return <></>;
  }
};

export default Dashboard;
