import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {
  SparkIcon,
  SparkTable,
  SparkTableBody,
  SparkTableCell,
  SparkTableHead,
  SparkTableRow,
} from "@bosch-web-dds/spark-ui-react";
import addIcon from "../../assets/svgs/add.svg";
import React, { useEffect, useState } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import { useAuth } from "../../contexts/auth-context";
import { auth, firestore } from "../../utils/firebase-config";
import FilterComponent from "../../components/FIlter/filter";

import {
  formatTimestampDate,
  transformDateFormat,
} from "../../services/date-format";

import { onAuthStateChanged } from "firebase/auth";
import FinancialDashboard from "../financial/financial-dashboard";
import { COLLECTIONS } from "../../assets/enums/firebase-colections";
import { StatusEnum } from "../../assets/enums/status-enum";
import ExpirationDocumentSolveForm from "../dashboard-admin/components/expiration-document-solve/expiration-document-solve";
import { OptimizationInfoDTO } from "../Optimization/models/optimization-DTO";
import { getOptimizationRandom } from "../Optimization/optimization-service";

const DashBoardGenerator: React.FC<{ enabledUsers: string[] }> = ({
  enabledUsers,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userDoc, loading } = useAuth();
  const profile = userDoc?.profile;
  const originId = userDoc?.originId;
  const { id } = useParams<{ id: string }>();
  const [tableData, setTableData] = useState<any>([]);
  const [tableDataBid, setTableDataBid] = useState<any>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);

  const tableHeader = [
    `${t("label-documents")}`,
    `${t("referent-to")}`,
    `${t("label-expiration")}`,
    `${t("start-warning")}`,
  ];
  const tableHeaderBid = [
    `${t("label-material")}`,
    `${t("label-quantity")}`,
    `${t("label-status")}`,
    `${t(" ")}`,
  ];
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [filteredDataBid, setFilteredDataBid] = useState<any[]>([]);

  const handleFilterClick = (filteredData: any[]) => {
    setFilteredData(filteredData);
  };
  const handleFilterClickBid = (filteredDataBid: any[]) => {
    setFilteredDataBid(filteredDataBid);
  };

  const sortByDocumentName = (data: any[]) => {
    return [...data].sort((a, b) => {
      const nameA = a.docName?.toString().toLowerCase() || "";
      const nameB = b.docName?.toString().toLowerCase() || "";
      return nameA.localeCompare(nameB);
    });
  };

  const sortByOrigin = (data: any[]) => {
    return [...data].sort((a, b) => {
      const originA = a.startWarningDate?.toString().toLowerCase() || "";
      const originB = b.startWarningDate?.toString().toLowerCase() || "";
      return originA.localeCompare(originB);
    });
  };

  const sortByExpirationDate = (data: any[]) => {
    return [...data].sort((a, b) => {
      const dateA = a.expirationDate.getTime();
      const dateB = b.expirationDate.getTime();
      return dateA - dateB;
    });
  };

  const sortOptions = [
    { label: t("label-documents"), sortFunction: sortByDocumentName },
    { label: t("referent-to"), sortFunction: sortByOrigin },
    { label: t("label-expiration"), sortFunction: sortByExpirationDate },
  ];
  const sortOptionsBid:
    | { label: string; sortFunction: (data: any[]) => any[] }[]
    | undefined = [];

  const handleSortClick = (sortFunction: (data: any[]) => any[]) => {
    const sortedData = sortFunction([...filteredData]);
    setFilteredData(sortedData);
  };

  const fetchTableData = async () => {
    const q = query(
      collection(firestore, "ExpirationDocuments"),
      where("solved", "==", false),
      where("originId", "==", originId),
      where("startWarningDate", "<", new Date())
    );

    const querySnapshot = await getDocs(q);

    const items = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
      searchData: doc.data()["docName"].concat(" ", doc.data()["origin"]),
    })) as unknown as any[];
    setTableData(items);
    setFilteredData(items);
  };

  const fetchTableDataNewBid = async () => {
    const q = query(
      collection(firestore, COLLECTIONS.SCRAP_BY_GENERATOR),
      where("originId", "==", originId)
    );

    const querySnapshot = await getDocs(q);

    const items = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
      searchData: doc
        .data()
        ["originName"].concat(" ", doc.data()["scrapCategory"]["name"]),
    })) as unknown as any[];
    let filteredList = items.filter(
      (item) =>
        item.status === StatusEnum.TO_DEAL ||
        item.status === StatusEnum.AWAITING_BID ||
        item.status === StatusEnum.BID_COMPLETED ||
        item.status === StatusEnum.IN_BID
    );
    setTableDataBid(filteredList);
    setFilteredDataBid(filteredList);
  };

  const [opRandom, setOpRandom] = useState<OptimizationInfoDTO>();

  const [userId, setUserId] = useState<string>();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(user.uid);
      } else {
        setUserId("");
      }
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    fetchTableData();
    fetchTableDataNewBid();
    fetchRandom();
  }, [id]);

  if (loading) return null;

  const handleOpenModal = (item: any) => {
    setSelectedItem(item);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedItem(null);
    fetchTableData();
  };

  const fetchRandom = async () => {
    const data = await getOptimizationRandom(originId ?? "");
    if (data) {
      setOpRandom(data);
    } 
  };

  if (enabledUsers.includes(profile || "public")) {
    return (
      <div>
        <>
          <div className="info-section">
            <div
              className="double-fields"
              style={{ gap: 10, marginBottom: 15 }}
            >
              <div style={{ width: "100%" }}>
                <div
                  style={{ width: "100%", gap: 10, marginBottom: 5 }}
                  className="flex-row align-center"
                >
                  <SparkIcon icName="toolbox" />
                  <h3 style={{ margin: 0 }}>{`${t(
                    "scraps-in-negotiation"
                  )}`}</h3>
                </div>

                {tableDataBid.length > 0 && (
                  <button
                    className="blue-with-icon button"
                    onClick={() => {
                      navigate(
                        `/generator-scraps/${userDoc?.originId}/generator-scraps-form`
                      );
                    }}
                  >
                    <img src={addIcon} alt="add icon" />
                    <p>{t("label-add-new")}</p>
                  </button>
                )}
              </div>
              <FilterComponent
                data={tableDataBid}
                onFilterClick={handleFilterClickBid}
                sortOptions={sortOptionsBid}
              />
            </div>

            {tableDataBid.length > 0 && (
              <SparkTable>
                <SparkTableHead>
                  <SparkTableRow>
                    <SparkTableCell className="icon-column"></SparkTableCell>
                    {tableHeaderBid.map((header, index) => (
                      <SparkTableCell key={index}>
                        <strong>{header.toUpperCase()}</strong>
                      </SparkTableCell>
                    ))}
                  </SparkTableRow>
                </SparkTableHead>

                <SparkTableBody>
                  {filteredDataBid.map((rowItem: any, rowIndex: any) => (
                    <SparkTableRow
                      key={rowIndex}
                      onClick={() =>
                        navigate(
                          `/generator-scraps/${originId}/generator-scraps-form/${rowItem.id}`
                        )
                      }
                    >
                      <SparkTableCell className="icon-column">
                        <SparkIcon
                          className="icon-flag"
                          no-padding={true}
                          icName={
                            rowItem.scrapCategory.isHazardousMaterial
                              ? "danger-hazards"
                              : "less-minimize"
                          }
                        />
                      </SparkTableCell>
                      <SparkTableCell>
                        {rowItem.scrapCategory.name}
                      </SparkTableCell>

                      <SparkTableCell>
                        {rowItem.quantity} {t(`unityENUM.${rowItem.unity}`)}/
                        {t(`periodENUM.${rowItem.period}`)}
                      </SparkTableCell>
                      <SparkTableCell>
                        {t(`statusEnum.${rowItem.status}`)}
                      </SparkTableCell>
                      {rowItem.status == StatusEnum.BID_COMPLETED && (
                        <SparkTableCell>
                          <div
                            className="tag-green pill-tag flex-row"
                            style={{
                              padding: "10px 10px",
                              alignItems: "center",
                              justifyContent: "center",
                              gap: 3,
                            }}
                          >
                            <SparkIcon
                              icName="wishlist"
                              noPadding
                              pallete="primary"
                            />
                            <strong>{t("material-best-price")}</strong>{" "}
                          </div>
                        </SparkTableCell>
                      )}
                      {rowItem.status != StatusEnum.BID_COMPLETED && (
                        <SparkTableCell> </SparkTableCell>
                      )}
                    </SparkTableRow>
                  ))}
                </SparkTableBody>
              </SparkTable>
            )}

            {tableDataBid.length == 0 && (
              <div className="dashboard-generator">
                <p>{t("dashboard-generator-main-message")}</p>
                <button
                  className="button blue"
                  onClick={() => navigate("/generator-scraps")}
                >
                  {t("dashboard-generator-button")}
                </button>
              </div>
            )}
          </div>
        </>

        <br />

        <div className="multiple-horizontal-slots" style={{ gap: 5 }}>
          <div className="horizontal-flex-responsible">
            <div className="flex-row align-center">
              <SparkIcon icName="book-lightbulb" />
              <h3 style={{ margin: "0" }}>{t("optimization-opportunities")}</h3>
            </div>

            <div className="recomendation-slot">
              {opRandom ? (
                <h4>{opRandom?.optimizations}</h4>
              ) : (
                <h4>{t("optimization-opportunities-default")}</h4>
              )}
            </div>
          </div>
          <div className="horizontal-flex-responsible">
            <FinancialDashboard />
          </div>
        </div>
        {isModalOpen && (
          <div className="modal-background">
            <ExpirationDocumentSolveForm
              contactId={selectedItem.id}
              onClose={handleCloseModal}
              item={selectedItem}
            />
          </div>
        )}
        <div style={{ marginBottom: "120px" }}>
          <div className="flex-row" style={{ justifyContent: "space-between" }}>
            <div className="flex-row"></div>
          </div>

          <br />
          <br />

          {tableData.length > 0 && (
            <>
              <div className="info-section">
                <div className=" double-fields" style={{ marginBottom: 15 }}>
                  <div
                    className="flex-row align-center"
                    style={{ width: "100%" }}
                  >
                    <SparkIcon icName="alert-error" />
                    <h3 style={{ margin: 0 }}>{`${t("label-expiration")}`}</h3>
                  </div>
                  <FilterComponent
                    data={tableData}
                    onFilterClick={handleFilterClick}
                    sortOptions={sortOptions}
                  />
                </div>
                <SparkTable>
                  <SparkTableHead>
                    <SparkTableRow>
                      <SparkTableCell className="icon-column"></SparkTableCell>
                      {tableHeader.map((header, index) => (
                        <SparkTableCell key={index}>
                          <strong>{header.toUpperCase()}</strong>
                        </SparkTableCell>
                      ))}
                    </SparkTableRow>
                  </SparkTableHead>

                  <SparkTableBody>
                    {filteredData.map((rowItem: any, rowIndex: any) => (
                      <SparkTableRow
                        key={rowIndex}
                        onClick={() => handleOpenModal(rowItem)}
                      >
                        <SparkTableCell className="icon-column">
                          <SparkIcon
                            className="icon-flag"
                            no-padding={true}
                            icName={
                              rowItem.solved
                                ? "danger-hazards"
                                : "less-minimize"
                            }
                          />
                        </SparkTableCell>
                        <SparkTableCell>{rowItem.docName}</SparkTableCell>
                        <SparkTableCell>{rowItem.origin}</SparkTableCell>
                        <SparkTableCell>
                          {transformDateFormat(rowItem.expirationDate)}
                        </SparkTableCell>
                        <SparkTableCell>
                          {formatTimestampDate(rowItem.startWarningDate)}
                        </SparkTableCell>
                      </SparkTableRow>
                    ))}
                  </SparkTableBody>
                </SparkTable>
              </div>
            </>
          )}
        </div>
      </div>
    );
  } else {
    navigate("/page-not-found");
    return <></>;
  }
};

export default DashBoardGenerator;
